import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllProgramLocations = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/programlocation", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addProgramLocation = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addprogramlocation", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const updateProgramLocation = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateprogramlocation", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteProgramLocation = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteprogramlocation/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const areaProgramLocation = (areas) => {
  return axios.post(APP_CONTEXT_URL + "/v1/areaProgramLocation",
    areas,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      },
    });
};