import { Button, Input } from "antd";
import React from "react";
import "./accountDeletion.scss";

const AccountDeletion = () => {
  return (
    <div className="main-body text-gray-900">
      <h1 className="text-center mt-6 text-6xl lg:text-7xl text-gray-900">
        Account Deletion for Nukrim App
      </h1>
      <div className="intro mt-6 text-xl lg:text-4xl">
        At Nukrim, we respect your right to manage your personal data and ensure
        that you have the option to delete your account whenever you choose.
        Please carefully read the information below regarding the account
        deletion process.
      </div>

      <h1 className="mt-6">How to Proceed</h1>
      <p>
        If you would like to delete your account, please enter your email
        address below and submit your request. Our support team will review your
        request and take the necessary actions.
      </p>

      <h3>
        <b>Please enter your registered email address</b>
      </h3>

      <div className="mt-4 email">
        <Input className="email" placeholder="Email Address" />
      </div>

      <div>
        <Button className="btn bg-blue-500 text-white cursor-pointer mt-3 mb-6">
          Submit
        </Button>
      </div>

      <p>
        Upon submitting your email address, we will contact you regarding the
        deletion of your account details.
      </p>

      <h2 className="mt-6">If you want to manually delete your account:</h2>

      <h1>Account Deletion Process</h1>
      <p>To delete your account, please follow these steps:</p>
      <div className="text-3xl">
        1. Log in to your account on the Nukrim website.
        <br />
        2. Go to the Account Settings page.
        <br />
        3. Click on the "Delete Account" button.
        <br />
        4. Follow the on-screen instructions to confirm the account deletion.
      </div>
      <br />

      <p>
        Once you have completed these steps, your account will be deleted, and
        all associated data will be removed from our servers.
      </p>

      <h1>Important Information</h1>
      <p>
        Please note the following important information regarding the account
        deletion process:
      </p>
      <div className="text-3xl">
        1. Once your account is deleted, all data associated with your account
        will be permanently removed from our servers.
        <br />
        2. You will no longer be able to access your account or any associated
        data after the account deletion process is completed.
        <br />
        3. If you change your mind and wish to restore your account, you will
        need to create a new account and start from scratch.
      </div>

      <h1>Contact Us</h1>
      <p>
        If you encounter any issues or have questions regarding the account
        deletion process, please contact our support team at{" "}
        <a href="mailto:nukrim@muhaimintech.com">nukrim@muhaimintech.com</a>.
      </p>

      <div className="end"></div>
    </div>
  );
};

export default AccountDeletion;
