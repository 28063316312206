import React, { useState, useEffect, useRef } from "react";
import "./lecturer.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useForm, Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { getAllLocation } from "../../../apis/LocationApi";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Input } from "antd";
import {
  addLecturer,
  deleteLecturer,
  uploadImageLecturer,
  getLecturerCount,
  getAllLecture,
  lecturerSearchCount,
  getLecturerFilter,
} from "../../../apis/LecturerApi";
import { FileUpload } from "primereact/fileupload";
import { Paginator } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";

function Lecturer() {
  const { Search } = Input;
  const dt = useRef(null);

  const [lecturer, setLecturer] = useState([]);
  const [lecturerTable, setLecturerTable] = useState([]);
  const [selectedLecturer, setSelectedLecturer] = useState(null);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [locationTable, setLocationTable] = useState([]);
  const [deleteLecturerDialog, setDeleteLecturerDialog] = useState(false);
  const [deleteLecturers, setDeleteLecturer] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [totalLecturer, setTotalLecturer] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [basicFirst, setBasicFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: "userId.id", order: -1 },
  ]);
  const ref = useRef();
  const [field, setField] = useState("id");
  const [order, setOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");

  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.email": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.address": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.skills": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    setFilters(e.target.value);
    setGlobalFilterValue(e.target.value);
    if (ref.current) clearTimeout(ref.current);
    if (e.target.value !== "") {
      ref.current = setTimeout(() => {
        setSearchText(e?.target.value);
        SearchCount(e?.target.value);
      }, 1000);
    } else {
      setSearchText("");
      lecturerCount();
    }
  };

  const SearchCount = (value) => {
    lecturerSearchCount(value).then((res) => {
      if (res.status === 200) {
        setTotalLecturer(res.data);
      }
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const openNew = () => {
    reset();
    setsidebarVisible(true);
  };
  const confirmDeleteSelected = (id) => {
    setDeleteLecturerDialog(true);
    setDeleteLecturer(id);
  };

  const lecturerCount = () => {
    getLecturerCount().then((res) => {
      if (res.status === 200) {
        setTotalLecturer(res.data);
        getLecturers();
      }
    });
  };

  const getLecturers = () => {
    setLoading(true);
    getAllLecture(field, order, currentPage, pageSize).then((res) => {
      if (res.status === 200) {
        setLecturerTable(res.data);
        setLecturer(res.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };
  const loadLocationData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getLecturers();
    loadLocationData();
  }, []);

  const hideDialog = () => {
    lecturerCount();
    reset();
    setsidebarVisible(false);
  };

  const onSubmit = async (data) => {
    if (isEdit) {
      let dataForApi = {
        id: valuesForEdit ? valuesForEdit.id : null,
        userId: {
          id: valuesForEdit.userId.id,
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          address: data.address,
          cnic: data.cnic,
          location: data.location,
        },
        skill: data.skill,
        // location: { id: data.location.id },
      };
      addLecturer(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "lecturer Updated Successfully",
            life: 3000,
          });
        }
      });
    } else {
      let dataForApi = {
        id: valuesForEdit ? valuesForEdit?.id : null,
        userId: {
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          cnic: data.cnic,
          address: data.address,
          location: data.location,
        },
        skill: data.skill,
        // location: { id: data.location.id },
      };
      addLecturer(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
        }
      });
    }
  };

  const deleteSelectedLecturer = () => {
    deleteLecturer(deleteLecturers).then((response) => {
      if (response.status === 200) {
        lecturerCount();
        setDeleteLecturerDialog(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Lecturer Deleted",
          life: 3000,
        });
      } else {
        toast.current.show({
          className: "error-shower",
          severity: "Error",
          summary: "Error",
          detail: "Delete the class associated with this lecturer first",
          life: 6000,
        });
        setDeleteLecturerDialog(false);
        reset();
      }
    });
  };

  const hideDeleteLecturerDialog = () => {
    setDeleteLecturerDialog(false);
    setDeleteLecturer("");
  };

  const deleteLecturerDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteLecturerDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedLecturer}
      />
    </React.Fragment>
  );

  useEffect(() => {
    if (valuesForEdit) {
      setValue("userId", valuesForEdit.userId.id);
      setValue("name", valuesForEdit.userId.name);
      setValue("mobile", valuesForEdit.userId.mobile);
      setValue("email", valuesForEdit.userId.email);
      setValue("cnic", valuesForEdit.userId.cnic);
      setValue("address", valuesForEdit.userId.address);
      setValue("skill", valuesForEdit.skill);
      setValue("location", valuesForEdit.userId.location);
    }
  }, [valuesForEdit]);
  const customBase64Uploader = (event) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadImageLecturer(valuesForEdit.id, formData).then((res) => {
      if (res.status === 200) {
        hideDialog();
        reset();
      }
    });
  };

  const representativeMultiSelectBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-0">
          <div>
            <Avatar
              label={rowData?.userId.name.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          </div>
          <div>
            <p>{rowData?.userId.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const lecturerFilter = (options) => {
    return (
      <MultiSelect
        value={selectedLecturer}
        onChange={(e) => {
          setSelectedLecturer(e.value);
        }}
        options={lecturer}
        itemTemplate={representativeMultiSelectBodyTemplate}
        optionLabel="userId.name"
        placeholder="Select Lecturer"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "16.5rem" }}
      />
    );
  };

  const filterLecturer = (filter) => {
    getLecturerFilter(filter, currentPage, pageSize).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setLecturerTable(response.data.lecturer);
        setTotalLecturer(response.data.count);
      } else {
        // show error
      }
    });
  };

  useEffect(() => {
    var filter = {};
    setLoading(true);
    if (selectedLecturer?.length > 0) {
      filter["name"] = selectedLecturer.map((lecturer) => lecturer.userId.name);
    }
    if (searchText) {
      filter["search"] = searchText;
    }
    filterLecturer(filter);
  }, [currentPage, pageSize, field, order, selectedLecturer, searchText]);
  const representativeBodyTemplate = (rowData) => {
    var imgpath = "data:image/jpg;base64, " + rowData.userId?.profileImage;
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.userId?.name?.charAt(0).toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const onBasicPageChange = (event) => {
    setLoading(true);
    setBasicFirst(event.first);
    setCurrentPage(event.page);
    setPageSize(event.rows);
    setLoading(false);
  };

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Lecturer</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            className="w-20rem"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search "
          />
        </span>

        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );

  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            ref={dt}
            value={lecturerTable}
            selection={selectedLecturer}
            loading={loading}
            onSelectionChange={(e) => setSelectedLecturer(e.value)}
            dataKey="id"
            // paginator
            // rows={10}
            // rowsPerPageOptions={[5, 10, 25]}
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Lecturer"
            sortMode="multiple"
            // header={header}
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              "userId.name",
              "userId.cnic",
              "userId.email",
              "userId.mobile",
              "userId.address",
              "userId.location",
              // "skills",
            ]}
            emptyMessage="No Lecturer found."
            responsiveLayout="scroll"
            className="table-sel "
            multiSortMeta={multiSortMeta}
            onSort={(e) => {
              e?.multiSortMeta[0]?.order === 1
                ? setOrder("asc")
                : setOrder("desc");
              setMultiSortMeta(e.multiSortMeta);
              setField(e?.multiSortMeta[0]?.field);
            }}
          >
            {/* <Column
              selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column> */}

            <Column
              field="userId.name"
              header="Name"
              style={{ minWidth: "15rem" }}
              body={representativeBodyTemplate}
              sortable
              sortField="name"
              filterPlaceholder="Search by userId.name"
              showFilterMenu={true}
              filter
              filterElement={lecturerFilter}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />
            <Column
              field="userId.mobile"
              header="Mobile"
              body={(record) => {
                return record?.userId?.mobile;
              }}
              style={{ width: "10%" }}
              filterPlaceholder="Search by userId.mobile"
            />

            <Column
              field="skill"
              header="Skill"
              style={{ width: "15%" }}
              filterPlaceholder="Search by skill"
            />
            <Column
              field="userId.location.locationName"
              header="Location Name"
              style={{ width: "10%" }}
              // sortable
              filterPlaceholder="Search by locationName"
            ></Column>
            <Column
              field="userId.address"
              header="Address"
              body={(record) => {
                return record?.userId?.address;
              }}
              style={{ width: "18%" }}
              filterPlaceholder="Search by userId.address"
            />

            <Column
              field="userId.email"
              header="Email"
              body={(record) => {
                return record?.userId?.email;
              }}
              style={{ width: "15%" }}
              filterPlaceholder="Search by userId.email"
            />
            <Column
              field="userId.cnic"
              header="CNIC"
              body={(record) => {
                return record?.userId?.cnic;
              }}
              style={{ width: "10%" }}
              filterPlaceholder="Search by userId.cnic"
            />

            <Column
              header="Action"
              fixed="Right"
              frozen
              alignFrozen="right"
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined"
                    onClick={(e) => {
                      setValuesForEdit(field);
                      setsidebarVisible(true);
                      setIsEdit(true);
                    }}
                  />

                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded red-color-btn p-button-spacing"
                    onClick={(e) => confirmDeleteSelected(field.id)}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            rows={pageSize}
            totalRecords={totalLecturer}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
            template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Lecturer"
          ></Paginator>
        </div>
        <Sidebar
          visible={sidebarVisible}
          position="right"
          style={{ width: "60em" }}
          onHide={() => {
            setsidebarVisible(false);
            setValuesForEdit(null);
            setIsEdit(false);
          }}
        >
          <div className="flex justify-content-center">
            <div className="card width-adjuster-for-add-deservant-form">
              <h5 className="text-center font-for-register">Lecturer</h5>
              <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid">
                  <div className="field grid" style={{ marginTop: "30px" }}>
                    <label
                      htmlFor="donorName"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Name </b>
                      <span className="color-for-str">*</span>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("name")}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="mobile"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Mobile </b>
                      <span className="color-for-str">*</span>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: "Mobile Number is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("mobile")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="cnic"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>CNIC</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="cnic"
                        control={control}
                        // rules={{ required: "cnic is required." }}
                        rules={{
                          pattern: {
                            value: RegExp(/^[0-9]{5}-[0-9]{7}-[0-9]$/),
                            message: "Please enter correct format of CNIC",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            // keyfilter="alphanum"
                            placeholder="Enter valid CNIC format "
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("cnic")}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "30px" }}>
                    <label
                      htmlFor="location"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Location </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="location"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            {...field}
                            autoFocus
                            options={locationTable}
                            optionLabel={(option) => {
                              return option.locationName;
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="address"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Address </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="address"
                        control={control}
                        // rules={{ required: "address is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("address")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="email"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Email </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="email"
                        control={control}
                        // rules={{ required: "email is required." }}
                        rules={{
                          pattern: {
                            value: new RegExp(
                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                            ),
                            message:
                              "Invalid email address. E.g. example@email.com",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            type={"email"}
                            keyfilter="alphanum"
                            placeholder=" E.g. example@email.com"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="skill"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Skill </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="skill"
                        control={control}
                        // rules={{ required: "skill is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {/* {getFormErrorMessage("skill")} */}
                    </div>
                  </div>
                  {isEdit ? (
                    <div className="field grid" style={{ marginTop: "5px" }}>
                      <label
                        htmlFor="profileImage"
                        className="col-12 mb-2 md:col-3 md:mb-0"
                      >
                        <b>Profile Image </b>
                      </label>
                      <div className="col-10 md:col-8">
                        <Controller
                          control={control}
                          name="demo"
                          render={({ field, fieldState }) => (
                            <FileUpload
                              mode="basic"
                              name="file"
                              accept="image/*"
                              maxFileSize={1000000}
                              customUpload
                              uploadHandler={customBase64Uploader}
                              chooseLabel="Browse"
                              auto
                            />
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Button type="submit" label="Submit" className="submit-btn" />
                <Button
                  type="cancel"
                  label="Cancel"
                  className="p-button-danger cancel-btn"
                  onClick={hideDialog}
                />
              </form>
            </div>
          </div>
        </Sidebar>
        <Dialog
          visible={deleteLecturerDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteLecturerDialogFooter}
          onHide={hideDeleteLecturerDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {
              <span>
                Are you sure you want to delete the selected Lecturer?
              </span>
            }
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default Lecturer;
