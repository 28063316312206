import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

// import Chart from 'react-apexcharts';
import moment from "moment";
import { InstagramFilled } from "@ant-design/icons";
import ChartDataLabels from "chartjs-plugin-datalabels";
const DeservantsChart = (props) => {
  const [deservantsCount, setDeservantsCount] = useState();

  const perCycleDeservants = () => {
    var cycleNames = [];
    var deservantCount = [];
    for (var cycleDeservant in props.deservants) {
      var date = moment(cycleDeservant).format("MMMM-YYYY");
      cycleNames.push(date);
      deservantCount.push(props.deservants[cycleDeservant]);
    }
    var sum = 0;
    var perc = 0;
    var list = [];
    deservantCount.forEach((deservant) => {
      sum = sum + deservant;
    });
    deservantCount.forEach((des) => {
      perc = (des / sum) * 100;
      list.push(perc);
    });

    setDeservantsCount({
      labels: cycleNames,
      datasets: [
        {
          label: "Deservants",
          data: deservantCount,
          backgroundColor: [
            "#C5D4AB",
            "#72A1DB",
            "#F4BF90",
            "#FCE888",
            "#6b64f6",
            "#7F6890",
            "#A17A74",
          ],
          hoverBackgroundColor: [
            "#C5D4AB",
            "#72A1DB",
            "#F4BF90",
            "#FCE888",
            "#6b64f6",
            "#7F6890",
            "#A17A74",
          ],
        },
      ],
    });
  };

  useEffect(() => {
    perCycleDeservants();
  }, [props.deservants]);

  const getLightTheme = () => {
    let deservantsOptions = {
      // isShowDataLabels: true,
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
    };
    return {
      deservantsOptions,
    };
  };

  const { deservantsOptions } = getLightTheme();

  return (
    <div className="layout-main-height">
      <div className="datatable-doc-demo">
        <div className="card">
          <Chart
            type="pie"
            data={deservantsCount}
            options={{...deservantsOptions,plugins:{
              tooltip:{
                enabled:false
              },datalabels: {
                color: '#f3f6f4',
                font:{
                  size:"14px",
                  weight:"600"
                }
              },
            }}}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    </div>
  );
};

export default DeservantsChart;
