import { Card } from "primereact/card";
import React from "react";
import DeservantData from "./deservantData";

const TarbiyahProgramDetail = (props) => {

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <div className="layout-main" style={{ width: "81%" }}>
          <h4>Attendees Details</h4>
          <DeservantData program={props.location.state.programs} />
        </div>
        <div
          style={{
            width: "16%",
            marginLeft: "20px",
          }}
          className="padd-adjuster-for-summary"
        >
          <Card>
            <h4 className="heading-selector">
              <b>
                <u>Lecturer</u>
              </b>
            </h4>
            <div className="cycle-summary-view">
              <div className="summaryCon">
                <p className="valumarginlizer">
                  {props.location.state.programs.lecturers.map((lecturer) => {
                    return lecturer.userId.name;
                  })}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TarbiyahProgramDetail;
