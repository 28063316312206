import React, { useState, useEffect, useRef } from "react";
import "./totalDonations.scss";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Form } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { getAllDonationsByCycle } from "../../../apis/DonationApi";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Sidebar } from "primereact/sidebar";
import PerDonorDonations from "./perDonorDonations";
import { MultiSelect } from 'primereact/multiselect';

const TotalDonations = (props) => {
  // const history = useHistory();
  const [donationCount, setAllDonations] = useState([]);
  const [perDonorDonatonsVisible, setPerDonorDonationsVisible] =
    useState(false);
  const [donorId, setDonorId] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [selectedCycleFilter, setSelectedCycleFilter] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState (null);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "cycleid.startDate": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "donorId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    amount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    donationType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const openPerDonorDonations = () => {
    setPerDonorDonationsVisible(true);
  };

  const hideDialogPerDonorDonations = () => {
    setPerDonorDonationsVisible(false);
  };

  const loadAllDonations = () => {
    getAllDonationsByCycle().then((res) => {
      if (res.status === 200) {
        setAllDonations(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadAllDonations();
  }, []);
  // const totalCycleBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-cycleid.startDate-option">
  //           <span className="image-text">{option.cycleid.startDate}</span>
  //       </div>
  //   );
  // };
  // const totalCycleFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedCycleFilter} 
  //         itemTemplate={totalCycleBody} 
  //         onChange={(e) => setSelectedCycleFilter(e.value)} 
  //         options={donationCount} 
  //         optionLabel="cycleid.startDate" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );
  // };
  // const totalDonorBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-donorId.userId.name-option">
  //           <span className="image-text">{option.donorId.userId.name}</span>
  //       </div>
  //   );
  // };
  // const totalDonorFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedDonor} 
  //         itemTemplate={totalDonorBody} 
  //         onChange={(e) => setSelectedDonor(e.value)} 
  //         options={donationCount} 
  //         optionLabel="donorId.userId.name" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );
    
  // };
  // const totalAmountBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-amount-option">
  //           <span className="image-text">{option.amount}</span>
  //       </div>
  //   );
  // };
  // const totalAmountFilter = (options) => {
  //   return  (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedAmount} 
  //         itemTemplate={totalAmountBody} 
  //         onChange={(e) => setSelectedAmount(e.value)} 
  //         options={donationCount} 
  //         optionLabel="amount" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );
    
  // };
  // const typeDonationBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-donationType-option">
  //           <span className="image-text">{option.donationType}</span>
  //       </div>
  //   );
  // };
  // const typeDonationFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedDonation} 
  //         itemTemplate={typeDonationBody} 
  //         onChange={(e) => setSelectedDonation(e.value)} 
  //         options={donationCount} 
  //         optionLabel="donationType" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );
    
  //   // <MultiSelect  
  //   // options={donationCount} 
  //   // donorscycle={typeDonationBody} 
  //   // // onChange={(e) => options.filterApplyCallback(e.value)} 
  //   // optionLabel="donationType" 
  //   //  placeholder="Any" 
  //   //  className="p-column-filter"
  //   // maxSelectedLabels={1} 
  //   // />
  // };
  // const renderHeader = () => {
  //   return (
  //     <div className="table-header tab-adjuster-total-donations">
  //       <h5 className="m-0">Donations</h5>
  //       <span className="p-input-icon-left">
  //         <i className="pi pi-search" />
  //         <InputText
  //           value={globalFilterValue}
  //           onChange={onGlobalFilterChange}
  //           placeholder="Search"
  //         />
  //       </span>
  //     </div>
  //   );
  // };

  // const header = renderHeader();

  const rowClick = (donorId) => {
    setDonorId(donorId);
    setPerDonorDonationsVisible(true);
    // donarId: donar.id,
    // donarName: donar.name,
    //   history.push({pathname:"/donarDonations",
    // donarId: donar.id,
    // donarName: donar.name,
    // // })
  };

  const representativeBodyTemplate = (rowData) => {
    var imgpath =
      "data:image/jpg;base64, " + rowData.donorId.userId?.profileImage;

    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.donorId.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.donorId.userId?.name?.charAt(0).toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.donorId.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            onRowClick={(e) => rowClick(e.data.donorId)}
            value={donationCount}
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilterFields={[
              "cycleid.startDate",
              "donorId.userId.name",
              "referenceId.username",
              "amount",
              "donationType",
            ]}
            emptyMessage="No donations found."
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            loading={loading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} donations"
            // header={header}
            className="table-sel"
          >
            <Column
              // selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="cycleid.startDate"
              header="Cycle Name"
              body={(record) => {
                return moment(record.cycleid.startDate).format("MMMM, YYYY");
              }}
              sortable
              filterPlaceholder="Search by cycleid.startDate"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={totalCycleFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />

            <Column
              className="donorhover"
              field="donorId.userId.name"
              header="Donor"
              sortable
              filterPlaceholder="Search by donorId.userId.name"
              // style={{ minWidth: "14rem" }}
              // body={representativeBodyTemplate}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={totalDonorFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />

            <Column
              field="amount"
              header="Amount"
              body={(record) => {
                return record.amount;
              }}
              sortable
              // filterPlaceholder="Search by amount"
              // dataType="numeric"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={totalAmountFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />

            <Column
              field="donationType"
              header="Donation type"
              body={(record) => {
                return record.donationType;
              }}
              sortable
              // filterPlaceholder="Search by donationType"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={typeDonationFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />
          </DataTable>
        </div>
      </div>

      <Sidebar
        visible={perDonorDonatonsVisible}
        position="right"
        style={{ width: "80em" }}
        onHide={() => {
          setPerDonorDonationsVisible(false);
        }}
      >
        <PerDonorDonations
          donarId={donorId}
          hide={hideDialogPerDonorDonations}
        />
      </Sidebar>
    </div>
  );
};

export default TotalDonations;
