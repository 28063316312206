import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const addCycleDistributor = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/cycledistributor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addCycleDistributorData = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addcycledistributor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const updateCycleDistributor = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/cycledistributor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const allCycleDistributors = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/cycledistributor", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllCurrentCycleDeservants = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/currentcycledeservants", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const getDeservantCount = () => {
//   return axios.get(APP_CONTEXT_URL + "/v1/cycledeservantscount", {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const getCurrentCycleDeservantsCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/currentcycledeservantcount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const currentCycleDeservants = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/currentcycledeservants", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getCurrentCycleDistributorsCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/currentcycledistributorcount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const currentCycleDistributors = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/currentcycledistributors", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const distributorsbycycle = (id) => {

  return axios.get(APP_CONTEXT_URL + "/v1/distributorsbycycle/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteCycleDistributor = (cycleId, deservantId) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/cycledistributor/" + cycleId + "/" + deservantId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};


export const getDistributorAndDeservantByIdCount = (cycleId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getDistributorAndDeservantByIdCount/" + cycleId , {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const getDistributorAndDeservantById = (cycleId,current,pageSize) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getDistributorAndDeservantById/" + cycleId  +
  "?page=" +
  current +
  "&size=" +
  pageSize, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getReferredByFilter = (filter,current,pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/cycleDistributorDonations?page=" +
    current +
    "&size=" +
    pageSize,filter ,{
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uniqueReferrers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/cycleDistributor/uniqueReferrers", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
