import React, { useState, useEffect, useRef } from "react";
import "./Donations.scss";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Modal,
  Divider,
  notification,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Col, DatePicker, Drawer, Form, Row, Select, Space } from "antd";
import {
  allDonations,
  deleteDonations,
  donationReceipt,
} from "../../../apis/DonationApi";
import { Toolbar } from "primereact/toolbar";
import DonationForm from "./donationForm";
import { downloadDonationReceiptFile } from "../../../functions/functions";

const Context = React.createContext({
  name: "Default",
});

const Donations = () => {
  const { Search } = Input;
  const toast = useRef(null);
  const moment = require("moment");
  const [donations, setDonations] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [modal2Open, setModal2Open] = useState(false);
  const { Option } = Select;
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [donorName, setDonorName] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [filteredDonations, setFilteredDonations] = useState([]);

  const [currentRecord, setCurrentRecord] = useState(null);

  const confirmDelete = (id) => {
    deleteDonations(id).then((res) => {
      if (res.status === 200) {
        const heading = "Donation Deleted Successfully !";
        const message = "Donation has been deleted successfully !";
        openNotification("bottomRight", "success", heading, message);
        loadAllDonations();
        setModal2Open(false);
      } else {
        const heading = "Donation Deletion Unsuccessful !";
        const message = `Donation could not be deleted. Please try again`;
        openNotification("bottomRight", "error", heading, message);
        setModal2Open(false);
      }
    });
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, type, heading, message) => {
    notification[type]({
      message: heading,
      description: message,
      placement,
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    const filteredData = donations.filter((donation) =>
      Object.keys(donation).some((key) =>
        String(donation[key]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredDonations(filteredData);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "donorName",
      key: "donorName",
      sorter: (a, b) => a.donorName.localeCompare(b.name),
    },
    {
      title: "Donation Type",
      dataIndex: "donationType",
      key: "donationType",
      sorter: (a, b) => a.donationType.localeCompare(b.name),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      sorter: (a, b) => a.paymentMethod.localeCompare(b.name),
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate", // Assuming "creationDate" is a valid date field in your data
      key: "creationDate",
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
      render: (creationDate) => {
        // Access the actual date value
        if (!creationDate) {
          // Handle missing or invalid dates
          return null; // Display nothing or a placeholder
        }
        return moment(creationDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Modal
            title="Confirm Deletion"
            centered
            open={modal2Open}
            okText="Delete"
            onOk={() => confirmDelete(currentRecordId)}
            onCancel={() => setModal2Open(false)}
            okButtonProps={{ danger: true }}
            maskProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.2)" } }}
          >
            <p>Are you sure you want to delete this donation.</p>
          </Modal>
          <Tooltip title="Download Receipt" placement="top">
            {isDownloading && record.id === currentRecordId ? (
              <LoadingOutlined style={{ fontSize: "20px" }} />
            ) : (
              <DownloadOutlined
                className="download-icon"
                style={{ fontSize: "22px" }}
                onClick={() => {
                  setIsDownloading(true);
                  setCurrentRecordId(record.id);
                  donationReceipt(record.id).then((res) => {
                    if (res.status === 200) {
                      downloadDonationReceiptFile(res, record);
                    }
                    setIsDownloading(false);
                  });
                }}
              />
            )}
          </Tooltip>
          <Tooltip title="Edit Donation" placement="top">
            <EditOutlined
              className="download-icon"
              style={{ fontSize: "22px" }}
              onClick={() => {
                setCurrentRecord(record);
                showDrawer();
                setEditMode(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Donation" placement="top">
            <DeleteOutlined
              className="delete-icon"
              style={{ fontSize: "22px" }}
              onClick={() => {
                setCurrentRecordId(record.id);
                setDonorName(record.donorName);
                setModal2Open(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const loadAllDonations = () => {
    setIsLoading(true);
    allDonations().then((res) => {
      if (res.status === 200) {
        var donationsTemp = [];
        res.data.forEach((Donation) => {
          Donation = {
            creationDate: Donation.creationDate,
            amount: Donation.amount,
            crNumber: Donation.crNumber,
            donationType: Donation.donationType,
            donorName: Donation.donorId.userId.name,
            donorId: Donation.donorId.id,
            paymentMethod: Donation.paymentMethod,
            id: Donation.id,
            cycleid: Donation.cycleid,
          };
          donationsTemp.push(Donation);
        });
        setDonations(donationsTemp);
        setFilteredDonations(donationsTemp);

        setIsLoading(false);
      }
    });
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setEditMode(false);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    loadAllDonations();
  }, []);

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Donations</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            placeholder="Search Donation"
            onChange={onGlobalFilterChange}
            value={globalFilterValue}
            style={{
              width: 200,
            }}
          />
        </span>
      </span>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        Add Donation
      </Button>
    </React.Fragment>
  );

  return (
    <div className="layout-main">
      <Drawer
        title={editMode ? "Edit Donation" : "Add Donation"}
        width={500}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <DonationForm
          hide={onClose}
          load={loadAllDonations}
          editMode={editMode}
          editorValues={currentRecord}
        />
      </Drawer>
      <div>
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div>
        <div className="card">
          {isloading ? (
            <div
              style={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <Table dataSource={filteredDonations} columns={columns} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Donations;
