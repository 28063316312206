import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllUsers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/users/all", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getCombinedUser = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/users/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllUsersExceptLogin = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/users", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const checkUser = (name) => {
  return axios.get(APP_CONTEXT_URL + "/v1/open/checkUser/" + name, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      // Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const createOne = (data) => {
  return axios.post(APP_CONTEXT_URL + "/v1/open/createOne", data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      // Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
