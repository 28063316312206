import {
  CalendarOutlined,
  DollarOutlined,
  HomeOutlined,
  HourglassOutlined,
  MenuOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Button, Dropdown, Layout, Menu } from "antd";
import PrimeReact from "primereact/api";
import React, { Suspense, useEffect, useState } from "react";
import {
  AnimationTypes,
  ComponentTransition,
} from "react-component-transition";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import { saveUserData } from "../actions/user";
import "../assets/layout/flags/flags.css";
import "../assets/layout/layout.scss";
import { AppProvider } from "../contexts/app";
import logo from "../images/nukrim.png";
import { clearAuth } from "../utils/authToken";
import { resetEntities } from "./../actions/data";
import Area from "./entities/Area/Area";
import Cycle from "./entities/Cycle/cycle";
import Deservant from "./entities/Deservant/Deservant";
import Distributor from "./entities/Distributor/Distributor";
import Donation from "./entities/Donation/Donations";
import Donor from "./entities/Donor/donor";
import Items from "./entities/Items/Items";
import Locations from "./entities/Location/location";
import User from "./entities/User/user";
import "./style.scss";
import Loader from "./ui-kit/loader/index";

import CurrentCycleDeservantTile from "./dashboard/DeservantTile/currentcycledeservants";
import Dashboard from "./dashboard/stepDashboard";
import CycleDetails from "./entities/Cycle/cycleDetails";
import Location from "./entities/TarbiyahProgram/programLocation";
import Classes from "./entities/TarbiyahProgram/tarbiyahProgram";
import TarbiyahProgramDetail from "./entities/TarbiyahProgram/tarbiyahProgramDetail";
import Lecturer from "./entities/lecturer/lecturer";
import PendingUsers from "./entities/User/pendingUsers";

const Home = (props) => {
  const { Header, Footer, Content } = Layout;
  const [language, setLanguage] = useState("en");
  const { loading = {}, currentUser = {} } = useSelector(({ app }) => app);
  const location = useLocation();
  const { app: apploading } = loading;
  const history = useHistory();
  const dispatch = new useDispatch();
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  PrimeReact.ripple = true;

  const getViewKey = () => {
    switch (history.location.pathname) {
      case "/parserconfig":
        return "parserconfig";
      case "/templates":
        return "templates";
      case "/settings":
        return "settings";
      case "/crewprojects":
        return "crewprojects";
      case "/globalvariables":
        return "/globalvariables";
      case "/trackingJobs":
        return "/trackingJobs";
      default:
        return "projects";
    }
  };

  const [selectedKey, setSelectedKey] = useState(getViewKey());

  document.documentElement.style.fontSize = "11px";

  const handleSignOut = (e) => {
    props.onLogout();
    history.push("/");
    localStorage.clear();
    dispatch(resetEntities());
    clearAuth();
    dispatch(saveUserData(null));
  };

  useEffect(() => {
    if (history.location.pathname === "/login") {
      handleSignOut();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (props.defaultView && location.pathname === "/" && currentUser) {
      history.push(props.defaultView);
    }
  }, [currentUser]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  const handleEntityChange = (name) => {
    setSelectedKey(name);
    history.push("/" + name);
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const routes = [
    { path: "/User", component: User },
    { path: "/Area", component: Area },
    { path: "/cycle", component: Cycle },
    { path: "/items", component: Items },
    { path: "/Donor", component: Donor },
    { path: "/classes", component: Classes },
    { path: "/lecturer", component: Lecturer },
    { path: "/location", component: Locations },
    { path: "/donations", component: Donation },
    { path: "/dashboard", component: Dashboard },
    { path: "/deservant", component: Deservant },
    { path: "/distributor", component: Distributor },
    { path: "/ProgramLocation", component: Location },
    { path: "/pendingUsers", component: PendingUsers },
    { path: "/cycleDetails", component: CycleDetails },
    { path: "/ProgramDetail", component: TarbiyahProgramDetail },
    { path: "/currentcycledeservants", component: CurrentCycleDeservantTile },
  ];

  const subMenu = (
    <Menu hidden="">
      <Menu.Item key="profile">Edit Profile</Menu.Item>
      <Menu.Item key="password">Change Password</Menu.Item>
      <Menu.Item key="logout" onClick={handleSignOut}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AppProvider value={{ language, setLanguage }}>
      {apploading && apploading.status && <Loader text={apploading.text} />}
      <Suspense fallback={<Loader />}>
        <Layout className="Header-style">
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              height: "6rem",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                onClick={() => {
                  history.push("/dashboard");
                }}
                style={{
                  height: "6rem",
                  background: "rgba(255, 255, 255, 0.3)",
                  marginLeft: "1%",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    height: "90%",
                  }}
                />
              </div>
              <div
                style={{
                  marginRight: "1%",
                }}
              >
                <Dropdown overlay={subMenu}>
                  <Button shape="circle">
                    <UserOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Header>

          <Content style={{ marginBottom: "45px" }}>
            <Layout
              className="site-layout-background"
              style={{ marginTop: "10px" }}
            >
              <div style={{ display: "flex" }}>
                <div
                  className="menu-div-style"
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    marginTop: "60px",
                    display: "flex",
                  }}
                >
                  <Menu
                    style={{
                      width: 200,
                      textAlignLast: "left",
                    }}
                    mode="inline"
                    selectedKeys={selectedKey}
                    defaultSelectedKeys={["projects"]}
                    defaultOpenKeys={["projects"]}
                  >
                    <Menu.Item
                      icon={<HomeOutlined />}
                      key="dashboard"
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        handleEntityChange("dashboard");
                      }}
                    >
                      Dashboard
                    </Menu.Item>

                    <Menu.Item
                      icon={<CalendarOutlined />}
                      key="cycle"
                      style={{ textAlign: "center" }}
                      onClick={() => handleEntityChange("cycle")}
                    >
                      Rashan Cycle
                    </Menu.Item>

                    <Menu.Item
                      icon={<DollarOutlined />}
                      key="donations"
                      style={{ textAlign: "center" }}
                      onClick={() => handleEntityChange("donations")}
                    >
                      Donations
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuOutlined />}
                      key="items"
                      style={{ textAlign: "center" }}
                      onClick={() => handleEntityChange("items")}
                    >
                      Items
                    </Menu.Item>

                    <Menu.SubMenu
                      title="Location"
                      icon={<LocationOnOutlinedIcon />}
                      key="location"
                      style={{ textAlign: "center" }}
                      onTitleClick={() => handleEntityChange("location")}
                    >
                      <Menu.Item
                        icon={<LocationOnOutlinedIcon />}
                        key="Area"
                        style={{ textAlign: "center" }}
                        onClick={() => handleEntityChange("Area")}
                      >
                        Area
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu
                      title="Tarbiyah Program"
                      icon={<ProfileOutlined />}
                      key="classes"
                      style={{ textAlign: "center" }}
                      onTitleClick={() => handleEntityChange("classes")}
                    >
                      <Menu.Item
                        icon={<LocationOnOutlinedIcon />}
                        key="ProgramLocation"
                        style={{ textAlign: "center" }}
                        onClick={() => handleEntityChange("ProgramLocation")}
                      >
                        Program Location
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu
                      title="User"
                      icon={<TeamOutlined />}
                      key="user"
                      style={{ textAlign: "center" }}
                      onTitleClick={() => handleEntityChange("user")}
                    >
                      <Menu.Item
                        icon={<UserOutlined />}
                        key="Deservant"
                        style={{ textAlign: "center" }}
                        onClick={() => handleEntityChange("Deservant")}
                      >
                        Deservant
                      </Menu.Item>
                      <Menu.Item
                        icon={<HourglassOutlined />}
                        key="Pending Users"
                        style={{ textAlign: "center" }}
                        onClick={() => handleEntityChange("pendingUsers")}
                      >
                        Pending Users
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu>
                </div>
                <div
                  className=""
                  style={{
                    width: "-webkit-fill-available",
                    marginTop: "60px",
                    display: "inline-grid",
                    marginRight: "8px",
                  }}
                >
                  <Content
                    className="site-layout-background "
                    style={{
                      padding: "1%",
                      height: "82vh",
                    }}
                  >
                    <ComponentTransition
                      className="Menu-design"
                      animateContainer={true}
                      enterAnimation={AnimationTypes.slideRight.enter}
                      exitAnimation={AnimationTypes.slideRight.exit}
                    >
                      <Switch>
                        {routes.map(({ path, component: Component }) => (
                          <Route key={path} path={path} component={Component} />
                        ))}
                      </Switch>
                    </ComponentTransition>
                  </Content>
                </div>
              </div>
            </Layout>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              position: "fixed",
              width: "100%",
              left: "0",
              bottom: "0",
              zIndex: 1,
            }}
          >
            COPYRIGHT © 2024 · Muhaimin Technologies
          </Footer>
        </Layout>
      </Suspense>
    </AppProvider>
  );
};
export default Home;
