import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  addCycleItems,
  getAllCycleItemsByCycleId,
} from "../../../apis/cycleItemApi";
import { getAllItems } from "../../../apis/ItemsApi";
import "./addCycleitem.scss";
const AddCycleItem = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [selectedCycleItem, setSelectedCycleItem] = useState({});

  const [Itemsname, setitemsname] = useState(null);
  const [allCycleItemData, setAllCycleItemData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const toast = useRef(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm();

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );

  const fetchItems = () => {
    getAllItems().then((res) => {
      if (res.status === 200) {
        setitemsname(res.data);
      }
    });
  };

  useEffect(() => {
    fetchItems();

    if (props?.editorValues) {
      setValue("itemName", props.editorValues.itemId);
      setValue("quantity", props.editorValues.quantity);
      setValue("price", props?.editorValues.price);
      // setValue("discountedPrice", props?.editorValues.discountedPrice);
      if (props?.editorValues?.id) {
        setIsEdit(true);
      }
    }
    // reset();
  }, [props?.editorValues]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const onSubmit = (data) => {
    if (isEdit) {
      let dataForApi = {
        id: props?.editorValues?.id ? props?.editorValues?.id : "null",
        quantity: data.quantity,
        price: data.price,
        itemId: { id: data.itemName.id },
        cycleId: { id: props?.id },
      };
      addCycleItems(dataForApi).then((res) => {
        if (res.status === 200) {
          setSelectedCycleItem({});
          setIsEdit(false);
          reset();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Item Cycle Updated Successfully",
            life: 5000,
          });
          props.loadItems();
          props.hide();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Item Cycle Updated Successfully",
            life: 5000,
          });
        }
      });
    } else {
      let dataForApi = {
        id: null,
        quantity: data.quantity,
        price: data.price,
        discountedPrice: data.discountedPrice,
        itemId: { id: data.itemName.id },
        cycleId: { id: props?.id },
      };
      addCycleItems(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          setIsEdit(false);
          props.loadItems();
          props.hide();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Items Cycle Updated Successfully",
            life: 5000,
          });
        }
      });
    }
  };

  return (
    <div className="layout-main">
      <div className="form-demo">
        <Toast ref={toast} position="top-center" />

        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex align-items-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h5>Registration Successful!</h5>
          </div>
        </Dialog>

        <div className="flex justify-content-center">
          <div className="card width-adjuster-for-add-cycleItem-form">
            <h5 className="text-center font-for-register">Cycle Items</h5>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="p-fluid">
                <div className="field grid" style={{ marginTop: "30px" }}>
                  <label
                    htmlFor="itemName"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Item Name
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="itemName"
                      control={control}
                      // rules={{ required: "Item Name is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          autoFocus
                          options={Itemsname}
                          optionLabel="name"
                          placeholder="Enter Your Item Name"
                        />
                      )}
                    />
                    {getFormErrorMessage("itemName")}
                  </div>
                </div>
                <div className="field grid" style={{ marginTop: "5px" }}>
                  <label
                    htmlFor="quantity"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Quantity
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="quantity"
                      control={control}
                      rules={{ required: "quantity is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          defaultValue={props?.editorValues?.quantity}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("quantity")}
                  </div>
                </div>
                <div className="field grid" style={{ marginTop: "5px" }}>
                  <label
                    htmlFor="price"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Price
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="price"
                      control={control}
                      rules={{ required: "price is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          defaultValue={props?.editorValues?.price}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("price")}
                  </div>
                </div>
              </div>
              <Button type="submit" label="Submit" className="submit-btn" />
              <Button
                type="button"
                label="Cancel"
                className="p-button-danger cancel-btn"
                onClick={props.hide}
              />
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default AddCycleItem;
