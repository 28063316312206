import React, { useState, useEffect } from "react";
import "./cycleDistributors.scss";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { getDeservantsForCycle } from "../../../apis/DeservantApi";
import { addCycleDistributor } from "../../../apis/CycleDistributorApi";

const CycleDistributors = (props) => {
  const [allDeservants, setAllDeservants] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedDeservant, setSelectedDeservant] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "referredBy.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "distributor.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    familyMember: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const fetchDeservants = () => {
    getDeservantsForCycle(props.id).then((res) => {
      setAllDeservants(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchDeservants();
  }, []);

  useEffect(() => {}, [selectedDeservant]);

  const { reset } = useForm();

  const submitData = () => {
    var allIds = [];
    selectedDeservant.forEach((deservant) => {
      var obj = {
        deservantId: deservant.id,
        distributorId: deservant.distributor.id,
        cycleId: props.id,
      };
      allIds.push(obj);
    });
    addCycleDistributor(allIds).then((res) => {
      if (res.status === 200) {
        props.currentCycleDeservants();
        reset();
        props.hide();
      }
    });
  };

  const renderHeader = () => {
    return (
      <div className="table-header tab-adjuster-cycle-distributor">
        <h5 className="m-0">Cycle Deservants</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search by Distributor"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            value={allDeservants}
            selection={selectedDeservant}
            onSelectionChange={(e) => setSelectedDeservant(e.value)}
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilterFields={[
              "referredBy.name",
              "distributor.userId.name",
              "userId.name",
              "userId.cnic",
              "userId.mobile",
              "familyMember",
            ]}
            emptyMessage="No Cycle Deservants found."
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Deservants"
            header={header}
            loading={loading}
            className="table-sel"
          >
            <Column
              key="referredBy.id"
              selectionMode="multiple"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="userId.name"
              header="Deservants"
              body={(record) => {
                return record.userId.name;
              }}
              sortable
              filterPlaceholder="Search by userId.name"
              style={{ minWidth: "13rem" }}
            />

            <Column
              key="distributor.id"
              field="distributor.userId.name"
              header="Distributors"
              body={(record) => {
                return record.distributor.userId.name;
              }}
              sortable
              filterPlaceholder="Search by referredBy.distributorId.name"
              style={{ minWidth: "13rem" }}
            />

            <Column
              key="refferedBy.id"
              field="refferedBy.name"
              header="Reffered By"
              body={(record) => {
                if (record.referredBy) {
                  return record.referredBy.name;
                } else {
                  return "N/A";
                }
              }}
              sortable
              filterPlaceholder="Search by userId.name"
              style={{ minWidth: "13rem" }}
            />

            <Column
              field="userId.cnic"
              header="Cnic"
              body={(record) => {
                return record.userId.cnic;
              }}
              sortable
              filterPlaceholder="Search by userId.cnic"
              style={{ minWidth: "13rem" }}
            />

            <Column
              field="userId.mobile"
              header="Mobile"
              body={(record) => {
                return record.userId.mobile;
              }}
              sortable
              filterPlaceholder="Search by userId.mobile"
              style={{ minWidth: "13rem" }}
            />

            <Column
              field="familyMember"
              header="Family Members"
              body={(record) => {
                return record.familyMember;
              }}
              sortable
              filterPlaceholder="Search by familyMember"
              style={{ minWidth: "13rem" }}
            />
          </DataTable>
        </div>
        <Button label="Submit" className="submit-btn" onClick={submitData} />
      </div>
    </div>
  );
};

export default CycleDistributors;
