import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllCycleItems = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/cycleitems", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const getAllCycleItemsByCycleId = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/cycleitems/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addCycleItems = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/cycleitems", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const editCycleItems = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/cycleitems", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteCycleItems = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/cycleitems/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
