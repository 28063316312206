import React, { useState, useEffect } from "react";
import "./cycleForm.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import CycleReceipt from "../Cycle/cycleRecipt";
import { Dropdown } from "primereact/dropdown";
import { allCycles, addCycle, deleteCycle } from "../../../apis/CycleApi";
import moment from "moment";
import { RadioButton } from "primereact/radiobutton";
import { COMPLETED, ACTIVE, UPCOMING } from "../../../utils/constants";
import { getAllLocation } from "../../../apis/LocationApi";
const CycleForm = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [cycleList, setCycleList] = useState(null);
  const [cycleStatus, setCycleStatus] = useState("ACTIVE");
  const [locationTable, setLocationTable] = useState([]);

  const [selectedCycle, setSelectedCycle] = useState(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  const loadAreaData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data);
        // setLoading(false);
      }
    });
  };

  const fetchCycles = () => {
    allCycles().then((res) => {
      if (res.status === 200) {
        setCycleList(res.data);
      }
    });
  };

  const onEditFunction = () => {
    if (props?.editorValues) {
      var startDate = new Date(
        moment(props?.editorValues.startDate).format("YYYY-MM-DD HH:mm:ss")
      );
      var endDate = new Date(
        moment(props?.editorValues.endDate).format("YYYY-MM-DD HH:mm:ss")
      );

      setSelectedCycle(props.editorValues);
      setValue("startDate", startDate);
      setValue("cycleName", props?.editorValues.cycleName);
      setValue("noOfItems", props?.editorValues.noOfItems);
      setValue("tax", props?.editorValues.tax);
      setValue("totalAmount", props?.editorValues.totalAmount);
      setValue("transportCharges", props?.editorValues.transportCharges);
      setValue("endDate", endDate);
      setValue("discountedPrice", props?.editorValues.discountedPrice);
      setValue("status", props?.editorValues.status);
      setValue("location", props?.editorValues.location.id);
      setCycleStatus(props?.editorValues.status);
    } else {
      reset();
    }
  };

  useEffect(() => {
    fetchCycles();
    loadAreaData();
    onEditFunction();
  }, [props?.editorValues]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const onSubmit = (data) => {
    let dataForApi = {
      id: selectedCycle ? selectedCycle.id : "",
      startDate: new Date(
        moment(data["startDate"]).format("YYYY-MM-DD HH:mm:ss")
      ),
      cycleName: data.cycleName,
      noOfItems: data.noOfItems,
      tax: parseFloat(data.tax),
      totalAmount: data.totalAmount,
      transportCharges: data.transportCharges,
      // endDate: new Date(moment(data["endDate"]).format("YYYY-MM-DD HH:mm:ss")),
      // itemsReceipt: data.itemsReceipt,
      // ImageList: [],
      discountedPrice: data.discountedPrice,
      status: cycleStatus,
      location: { id: data.location },
    };

    addCycle(dataForApi).then((res) => {
      if (res.status === 200) {
        props.loadCycleData();

        reset();
        props.hide();
        // window.location.reload(false);
      }
    });
    props.loadCycleData();
  };
  const isNew = props?.editorValues;
  return (
    <div className="layout-main">
      <div className="form-demo">
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex align-items-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h5>Registration Successful!</h5>
          </div>
        </Dialog>

        <div className="flex justify-content-center">
          <div className="card width-adjuster-for-add-cycle-form">
            <h5 className="text-center font-for-cycle">Cycle</h5>
            <br />
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="p-fluid">
                <div
                  className="field grid"
                  style={{ marginTop: "5px", fontSize: "12px" }}
                >
                  <label
                    htmlFor="noOfItems"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Cycle Name
                  </label>

                  <div className="col-10 md:col-8">
                    <Controller
                      name="cycleName"
                      control={control}
                      rules={{ required: "Cycle Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("cycleName")}
                  </div>
                </div>
                <div className="field grid" style={{ marginTop: "30px" }}>
                  <label
                    htmlFor="location"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    <b> Location </b>
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="location"
                      control={control}
                      // rules={{ required: "you need to choose a referre." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          value={field.value}
                          options={locationTable}
                          optionLabel="locationName"
                          optionValue="id"
                          placeholder="Select Location"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                    {/* {getFormErrorMessage("referredBy")} */}
                  </div>
                </div>

                {isNew ? (
                  <div
                    className="field grid"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <label
                      htmlFor="noOfItems"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      No. Of Items
                    </label>

                    <div className="col-10 md:col-8">
                      <Controller
                        name="noOfItems"
                        control={control}
                        rules={{ required: "Number Of Items is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                            disabled
                          />
                        )}
                      />
                      {getFormErrorMessage("noOfItems")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {isNew ? (
                  <div
                    className="field grid"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <label
                      htmlFor="tax"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      Tax
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="tax"
                        control={control}
                        rules={{ required: "Tax is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("tax")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {isNew ? (
                  <div
                    className="field grid"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <label
                      htmlFor="tax"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      Transport Charges
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="transportCharges"
                        control={control}
                        rules={{ required: "Transport Charges is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("transportCharges")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {isNew ? (
                  <div
                    className="field grid"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <label
                      htmlFor="discountedPrice"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      Discounted Price
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="discountedPrice"
                        control={control}
                        rules={{ required: " is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("discountedPrice")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {isNew ? (
                  <div
                    className="field grid"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <label
                      htmlFor="discountedPrice"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      Status
                    </label>
                    <div>
                      <div style={{ display: "flex" }}>
                        {/* <div className="field-radiobutton">
                          <RadioButton
                            value={UPCOMING}
                            onChange={(e) => setCycleStatus(e.value)}
                            checked={cycleStatus === UPCOMING}
                          />
                          <label>Upcoming</label>
                        </div>
                        <div className="field-radiobutton ml-4">
                          <RadioButton
                            value={ACTIVE}
                            onChange={(e) => setCycleStatus(e.value)}
                            checked={cycleStatus === ACTIVE}
                          />
                          <label>Active</label>
                        </div> */}
                        <div className="field-radiobutton ml-4">
                          <RadioButton
                            value={COMPLETED}
                            onChange={(e) => setCycleStatus(e.value)}
                            checked={cycleStatus === COMPLETED}
                          />
                          <label>Completed</label>
                        </div>
                      </div>
                      {/* <Controller
                        name="status"
                        control={control}
                        rules={{ required: " is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      /> */}
                      {getFormErrorMessage("status")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {isNew ? <CycleReceipt cycleId={props?.editorValues.id} /> : ""}

              <Button type="submit" label="Submit" className="submit-btn" />
            </form>
            <Button
              type="cancel"
              label="Cancel"
              className="p-button-danger cancel-btn"
              onClick={props.hide}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CycleForm;
