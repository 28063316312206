import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllLocation = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/location", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addLocation = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addLocation", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const updateArea = (values) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/updateLocation", values, {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const deleteLocation = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteLocation/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const areaLocation = (areas) => {
  return axios.post(APP_CONTEXT_URL + "/v1/locationFilter",
    areas,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      }
  });
};
