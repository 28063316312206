import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllAreas = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/area", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addArea = (values) => {
  console.log("Area Vals: ", values)
  return axios.post(APP_CONTEXT_URL + "/v1/addArea", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const updateArea = (values) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/updatearea", values, {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const deleteArea = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteArea/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const areaLocation = (areas) => {
  return axios.post(APP_CONTEXT_URL + "/v1/areaFilter",
    areas,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      }
    });
};
