import React, { useState, useEffect, useRef } from "react";
import "./Items.scss";
import { DataTable } from "primereact/datatable";
import { getAllItems, deleteItems,itemsName } from "../../../apis/ItemsApi";
import { Form } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import AddItems from "./addItems";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Sidebar } from "primereact/sidebar";
import ItemPriceDetail from "../Items/itemPriceDetail";
import { MultiSelect } from 'primereact/multiselect';

const Items = () => {
  const dt = useRef(null);
  const toast = useRef(null);
  const moment = require("moment");
  const [form] = Form.useForm();
  const [itemData, setItemsAreaData] = useState([]);
  const [itemTable, setItemTable] = useState([]);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [itemPriceDialoge, setItemPriceDialoge] = useState(false);
  const [itemPrice, setItemPrice] = useState({});
  const [deleteItem, setDeleteItem] = useState();
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState();
  const [id, setId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedItems, setSelectedItems] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    unit: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    creationDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const openNew = () => {
    setId(null);
    setsidebarVisible(true);
  };
  const confirmDeleteSelected = (id) => {
    setDeleteItemDialog(true);
    setDeleteItem(id);
  };

  useEffect(() => {
    const ids = selectedItems?.map(obj => obj.id);
    if(ids?.length > 0)
    {
      itemsName(ids).then((res) => {
        if (res.status === 200) {
          setItemTable(res.data)
        }
        else // api call failed 
          setItemTable([]);
      });
    }
    else 
    loadItemData();
  }, [selectedItems])

  const itemsBody = (option) => {
    return ( 
              <div className="p-multiselect-name-option">
            <span className="image-text"> {option.name}</span>
        </div>
    );
  };
  const itemsFilter = (options) => {
    return (
      <div className="card flex justify-content-center">
          <MultiSelect 
           value={selectedItems} 
           onChange={(e) => setSelectedItems(e.value)} 
           options={itemData} 
           optionLabel="name" 
          //  display="chip" 
           placeholder="Select Name" 
           maxSelectedLabels={1} 
           className="w-full md:w-14rem" />
      </div>
  );
  };

  const deleteSelectedItems = () => {
    deleteItems(deleteItem).then((response) => {
      if (response.status === 200) {
        loadItemData();
        setDeleteItemDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Item Deleted Successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Delete the item from cycle first",
          life: 3000,
        });
        setDeleteItemDialog(false);
      }
    });
  };
  const loadItemData = () => {
    getAllItems().then((res) => {
      if (res.status === 200) {
        setItemTable(res.data)
        setItemsAreaData(res.data.reverse());
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    loadItemData();
  }, []);

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Items List</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );

  const hideDeleteProductsDialog = () => {
    setDeleteItemDialog(false);
    setDeleteItem("");
  };
  const hideItemPriceDialog = () => {
    setItemPriceDialoge(false);
    // setDeleteItem("");
  };
  const deleteItemDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedItems}
      />
    </React.Fragment>
  );
  const hideDialog = () => {
    setValuesForEdit(null);
    setsidebarVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Item List Updated Successfully",
      life: 5000,
    });
  };
  return (
    <div className="layout-main">
      <div>
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />

        <div className="card">
          <DataTable
            ref={dt}
            value={itemTable}
            dataKey="id"
            paginator
            rows={10}
            // scrollable
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={["name", "unit", "creationDate"]}
            loading={loading}
            emptyMessage="No items found."
            responsiveLayout="scroll"
            className="table-sel mytab cyclehover"
            onRowClick={(e) => {
              setItemPriceDialoge(true);
              setItemPrice(e.data);
            }}
            rowHover
          >
            <Column
              field="name"
              header="Name"
              // body={(record) => {
              //   return record.name;
              // }}
              style={{ minWidth: "14rem" }}
              sortable
              filterPlaceholder="Search by name"
              showFilterMenu={true}
              filterMenuStyle={{ width: '14rem' }}
              filter
              filterElement={itemsFilter}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />

            <Column
              field="unit"
              header="Unit"
              style={{ minWidth: "14rem" }}
              sortable
              // filterPlaceholder="Search by unit"
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={unitFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />

            <Column
              field="creationDate"
              header="Creation Date"
              style={{ minWidth: "14rem" }}
              sortable
              filterPlaceholder="Search by creationDate"
            />

            <Column
              header="Action"
              fixed="Right"
              frozen
              alignFrozen="right"
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined"
                    onClick={(e) => {
                      setValuesForEdit(field);
                      setsidebarVisible(true);
                    }}
                  />

                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded red-color-btn p-button-spacing"
                    onClick={(e) => confirmDeleteSelected(field.id)}
                  />
                </div>
              )}
            ></Column>
          </DataTable>

          <Sidebar
            visible={sidebarVisible}
            position="right"
            style={{ width: "40em" }}
            onHide={() => {
              setsidebarVisible(false);
              setValuesForEdit(null);
            }}
          >
            <AddItems
              hide={hideDialog}
              load={loadItemData}
              editorValues={valuesForEdit ? valuesForEdit : null}
            />
          </Sidebar>
        </div>
        <Dialog
          visible={deleteItemDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteItemDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {<span>Are you sure you want to delete the selected Item?</span>}
          </div>
        </Dialog>
        <Dialog
          visible={itemPriceDialoge}
          style={{ width: "800px" }}
          header={"Item Details (" + itemPrice?.name + ")"}
          // modal
          // footer={deleteItemDialogFooter}
          onHide={hideItemPriceDialog}
        >
          <ItemPriceDetail item={itemPrice} />
        </Dialog>
      </div>
    </div>
  );
};

export default Items;
