import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Sidebar } from "primereact/sidebar";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getEstimatedDonation } from "../../apis/CycleApi";
import {
  getAmountofCurrentCycle,
  getDonationsCount,
  getTotalAmountofCurrentCycle,
} from "../../apis/DonationApi";
import "./style.scss";
// import DonationForm from "../entities/Donation/donationForm";
import AllCycles from "./cycleTile/allCycles";
import PerCycleDonations from "./donationsTile/perCycleDonations";
import TotalDonations from "./donationsTile/totalDonations";

import {
  allCycleDates,
  countOfCycles,
  getLatestCycle,
  perCycleData,
} from "../../apis/CycleApi";
import {
  currentCycleDeservants,
  // getDeservantCount,
  getCurrentCycleDeservantsCount,
  getCurrentCycleDistributorsCount,
} from "../../apis/CycleDistributorApi";
import { getDeservantCount } from "../../apis/DeservantApi";
import { countDistributors } from "../../apis/DistributorApi";
import CurrentCycleDeservantTile from "../dashboard/DeservantTile/currentcycledeservants";
import Deservant from "../entities/Deservant/Deservant";
import DeservantsChart from "./DeservantsChart/deservantsChart";
import CurrentCycleDistributors from "./distributorTile/currentCycleDistributors";
import TotalDistributors from "./distributorTile/totalDistributors";
import DonationsChart from "./DonationsChart/donationsChart";
import EstimatedDonationsChart from "./EstimatedDonationsChart/estimatedDonationsChart";

const StepDashboard = (props) => {
  const [cycleYears, setCycleYears] = useState([]);
  const [latestYear, setLatestYear] = useState();
  const [cycleDonations, setCycleDonations] = useState({});
  const [cycleDeservants, setCycleDeservants] = useState({});
  const [donationCount, setDonationCount] = useState([]);
  const [estimatedAmount, setEstimatedAmount] = useState();
  const [deservantsCount, setDeservantsCount] = useState([]);
  const [currentCycleAmount, setCurrentCycleAmount] = useState();
  const [cycleCount, setCycleCount] = useState([]);
  const [distributorCount, setDistributorCount] = useState([]);
  const [currentCycleDeservant, setCurrentCycleDeservant] = useState([]);
  const [currentCycleCount, setCurrentCycleCount] = useState([]);
  const [currentCycleDeservantsVisible, setCurrentCycleDeservantsVisible] =
    useState(false);
  // const [donationFormVisible, setDonationFormVisible] = useState(false);
  const [currentCycleDonationsVisible, setCurrentCycleDonationsVisible] =
    useState(false);
  const [totalDonationsVisible, setTotalDonationsVisible] = useState(false);
  const [totalDeservantsVisible, setTotalDeservantsVisible] = useState(false);
  const [currentCycleDeservantCount, setCurrentCycleDeservantCount] = useState(
    []
  );
  const [currentCycleDistributorCount, setCurrentCycleDistributorCount] =
    useState([]);
  const [latestCycle, setLatestCycle] = useState([]);

  const [valuesForEdit, setValuesForEdit] = useState();
  const [cycleId, setCycleId] = useState(null);
  const [allCyclesVisible, setAllCyclesVisible] = useState(false);
  const [totalDistributorsVisible, setTotalDistributorsVisible] =
    useState(false);
  const [currentcycleDistributorsVisible, setCurrentCycleDistributorsVisible] =
    useState(false);
  const history = useHistory();

  const openTotalDonations = () => {
    setTotalDonationsVisible(true);
  };

  const openCurrentCycleDonations = () => {
    setValuesForEdit(null);
    setCurrentCycleDonationsVisible(true);
  };

  const hideDialogDonation = () => {
    setCurrentCycleDonationsVisible(false);
  };

  const hideDialogTotalDonations = () => {
    setTotalDonationsVisible(false);
  };
  const openTotalDeservants = () => {
    setTotalDeservantsVisible(true);
  };
  const hideDialogTotalDeservants = () => {
    setTotalDeservantsVisible(false);
  };
  const hideDialogTotalDistributors = () => {
    setTotalDistributorsVisible(false);
  };
  const hideDialogCurrentCycleDistributors = () => {
    setCurrentCycleDistributorsVisible(false);
  };

  const openCurrentCycleDeservants = () => {
    setValuesForEdit(null);
    setCurrentCycleDeservantsVisible(true);
  };
  const hideDialogCurrentCycleDeservants = () => {
    setCurrentCycleDeservantsVisible(false);
  };

  const hideDialogAllCycles = () => {
    setAllCyclesVisible(false);
  };

  const loadDonationCount = () => {
    getDonationsCount().then((res) => {
      if (res.status === 200) {
        setDonationCount(res.data);
      }
    });
  };

  const {
    formState: { errors },
  } = useForm();

  function CalculateEstimatedAmount() {
    var value = 0;
    if (estimatedAmount > currentCycleAmount) {
      value = estimatedAmount - currentCycleAmount;
      return value;
    } else if (estimatedAmount <= currentCycleAmount) {
      estimatedAmount = 0;
      return estimatedAmount;
    }
  }

  const loadCurrentCycleCount = () => {
    getTotalAmountofCurrentCycle().then((res) => {
      if (res.status === 200) {
        setCycleId(res.data);
        setCurrentCycleCount(res.data);
      }
    });
  };

  const EstAmount = () => {
    getEstimatedDonation().then((res) => {
      if (res.status === 200) {
        setEstimatedAmount(res.data);
        CalculateEstimatedAmount();
      }
    });
  };

  const loadDeservantsCount = () => {
    getDeservantCount().then((res) => {
      if (res.status === 200) {
        setDeservantsCount(res.data);
      }
    });
  };

  const loadCycleDates = () => {
    allCycleDates().then((res) => {
      if (res.status === 200) {
        setCycleYears(res.data);
        setLatestYear(Math.max(...res.data));
      }
    });
  };

  const currentCycleData = () => {
    getAmountofCurrentCycle().then((res) => {
      if (res.status === 200) {
        setCurrentCycleAmount(res.data);
      }
    });
  };
  const loadCountOfCycles = () => {
    countOfCycles().then((res) => {
      if (res.status === 200) {
        setCycleCount(res.data);
      }
    });
  };
  const loadCurrentCycleDeservantCount = () => {
    getCurrentCycleDeservantsCount().then((res) => {
      if (res.status === 200) {
        // setCycleId(res.data);
        setCurrentCycleDeservantCount(res.data);
      }
    });
  };
  const loadCurrentCycleDeservants = () => {
    currentCycleDeservants().then((res) => {
      if (res.status === 200) {
        setCycleId(res.data);
        setCurrentCycleDeservant(res.data);
      }
    });
  };

  const loadCycleCount = () => {
    countOfCycles().then((res) => {
      if (res.status === 200) {
        setCycleCount(res.data);
      }
    });
  };

  const loadDistributorCount = () => {
    countDistributors().then((res) => {
      if (res.status === 200) {
        setDistributorCount(res.data);
      }
    });
  };

  const loadCurrentCycleDistributorCount = () => {
    getCurrentCycleDistributorsCount().then((res) => {
      if (res.status === 200) {
        // setCycleId(res.data);
        setCurrentCycleDistributorCount(res.data);
      }
    });
  };

  const loadLatestCycle = () => {
    getLatestCycle().then((res) => {
      if (res.status === 200) {
        // setCycleId(res.data);
        setLatestCycle(res.data);
      }
    });
  };

  const dashboardApi = (e) => {
    perCycleData(e).then((res) => {
      if (res.status === 200) {
        setCycleDonations(res.data.cycleDonations);
        setCycleDeservants(res.data.cycleDeservants);
      }
    });
  };

  useEffect(() => {
    loadDonationCount();
    currentCycleData();
    loadCurrentCycleCount();
    loadCycleCount();
    EstAmount();
    loadDeservantsCount();
    loadCountOfCycles();
    loadCurrentCycleDeservantCount(cycleId);
    loadCurrentCycleDeservants();
    loadDistributorCount();
    loadCurrentCycleDistributorCount(cycleId);
    loadLatestCycle(cycleId);
    loadCycleDates();
  }, []);

  useEffect(() => {
    if (latestYear) {
      dashboardApi(latestYear);
    }
  }, [latestYear]);

  return (
    <React.Fragment>
      <div className="layout-main">
        <div style={{ display: "flex" }}>
          <div className="layout-dashborad">
            <div className="flex justify-content-center mb-1">
              <span className="block text-500 font-medium mb-1 bolder">
                Total
              </span>
            </div>
            <div className="dashboradcard mb-0">
              <div className="main" onClick={openTotalDonations}>
                <span className="block text-500 font-medium mb-1 bolder">
                  Donations
                </span>
                <span className="text-900 font-medium text-xl">
                  <span className="text-orange-500 font-medium ">Rs </span>
                  {donationCount}
                </span>
              </div>
              <div className="vertical-line"></div>
              <div className="submain" onClick={openTotalDeservants}>
                <span className="block text-500 font-medium mb-1 bolder">
                  Deservants
                </span>
                <span className="text-green-500 font-medium text-xl">
                  <span className="text-orange-500 font-medium "></span>
                  {deservantsCount}
                </span>
              </div>
            </div>
          </div>

          <div className="layout-dashborad">
            <div className="flex justify-content-center mb-1">
              <span className="block text-500 font-medium mb-1 bolder">
                Current cycle
              </span>
            </div>
            <div className="dashboradcard mb-0">
              <div className="main" onClick={openCurrentCycleDonations}>
                <span className="block text-500 font-medium mb-1 bolder">
                  Collected
                </span>
                <span className="text-900 font-medium text-xl">
                  <span className="text-orange-500 font-medium ">Rs </span>
                  {currentCycleCount}
                </span>
              </div>
              <div className="vertical-line"></div>
              <div className="submain">
                <span className="block text-500 font-medium mb-1 bolder">
                  Required
                </span>
                <span className="text-green-500 font-medium text-xl">
                  <span className="text-orange-500 font-medium ">Rs </span>
                  {estimatedAmount > currentCycleCount
                    ? estimatedAmount - currentCycleCount
                    : 0}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="layout-dashborad">
            <div className="flex justify-content-center mb-1">
              <span className="block text-500 font-medium mb-1 bolder">
                Cycles
              </span>
            </div>
            <div className="dashboradcard mb-0">
              <div className="main" onClick={openAllCycles}>
                <span className="block text-500 font-medium mb-1">Total</span>
                <span className="text-900 font-medium text-xl">
                  {cycleCount}
                </span>
              </div>
              <div className="vertical-line"></div>
              <div className="submain" onClick={(e) => onClick(e.data)}>
                <span className="block text-500 font-medium mb-1">
                  Latest Cycle
                </span>
                <span className="text-green-500 font-medium text-xl"></span>
              </div>
            </div>
          </div>

          <div className="layout-dashborad">
            <div className="flex justify-content-center mb-1">
              <span className="block text-500 font-medium mb-1 bolder">
                Distributors
              </span>
            </div>
            <div className="dashboradcard mb-0">
              <div className="main" onClick={openTotalDistributors}>
                <span className="block text-500 font-medium mb-1">Total</span>
                <span className="text-900 font-medium text-xl">
                  {distributorCount}
                </span>
              </div>
              <div className="vertical-line"></div>
              <div className="submain" onClick={openCurrentCycleDistributors}>
                <span className="block text-500 font-medium mb-1">
                  Current Cycle
                </span>
                <span className="text-green-500 font-medium text-xl">
                  {currentCycleDistributorCount}
                </span>
              </div>
            </div>
          </div> */}

          <Sidebar
            visible={totalDonationsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setTotalDonationsVisible(false);
            }}
          >
            <TotalDonations hide={hideDialogTotalDonations} />
          </Sidebar>

          <Sidebar
            visible={currentCycleDonationsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setCurrentCycleDonationsVisible(false);
              setValuesForEdit(null);
            }}
          >
            <PerCycleDonations
              cycleDetail={cycleId ? cycleId : ""}
              hide={hideDialogDonation}
              editorValues={valuesForEdit ? valuesForEdit : null}
            />
          </Sidebar>

          <Sidebar
            visible={totalDeservantsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setTotalDeservantsVisible(false);
            }}
          >
            <Deservant type="dashboard" hide={hideDialogTotalDeservants} />
          </Sidebar>

          <Sidebar
            visible={currentCycleDeservantsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setCurrentCycleDeservantsVisible(false);
            }}
          >
            <CurrentCycleDeservantTile
              deservantCycle={cycleId ? cycleId : ""}
              hide={hideDialogCurrentCycleDeservants}
            />
          </Sidebar>

          <Sidebar
            visible={allCyclesVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setAllCyclesVisible(false);
            }}
          >
            <AllCycles hide={hideDialogAllCycles} />
          </Sidebar>

          <Sidebar
            visible={totalDistributorsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setTotalDistributorsVisible(false);
            }}
          >
            <TotalDistributors hide={hideDialogTotalDistributors} />
          </Sidebar>

          <Sidebar
            visible={currentcycleDistributorsVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setCurrentCycleDistributorsVisible(false);
            }}
          >
            <CurrentCycleDistributors
              hide={hideDialogCurrentCycleDistributors}
            />
          </Sidebar>
        </div>

        <div className="sep-chart">
          <div className="layout-dashborad-donation-graph">
            <div className="aligner-con-header">
              <span className="heading-graphics">Donations</span>
            </div>
            <div className="flex justify-content-center mb-1">
              <DonationsChart donations={cycleDonations} />
            </div>
            <div className="dashboradcard mb-0"></div>
          </div>

          <div className="layout-dashborad-deservant-graph">
            <div className="aligner-con-header">
              <span className="heading-graphics">Deservants</span>
            </div>
            <div className="flex justify-content-center mb-1">
              <DeservantsChart deservants={cycleDeservants} />
            </div>
            <div className="dashboradcard mb-0"></div>
          </div>
          <div className="margin-chart-top">
            <div className="aligner-con-header">
              <span className="heading-graphics">Estimated Donations</span>
            </div>
            <div className="flex justify-content-center mb-1">
              <EstimatedDonationsChart />
            </div>
            <div className="dashboradcard mb-0"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default StepDashboard;
