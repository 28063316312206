import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getUsers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/users", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getRoles = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/roles", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addUser = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/adduser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const editUser = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/edituser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteUsers = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/users/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
