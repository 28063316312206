import "./style.scss";
import {
  Button as AntButton,
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import {
  addUser,
  deleteUsers,
  editUser,
  getRoles,
  getUsers,
} from "../../../apis/SettingApis";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import UserForm from "./userForm";
import moment from "moment";
import { getCombinedUser } from "../../../apis/UserApi";

const Users = (props) => {
  const { Search } = Input;
  const [users, setUsers] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [editCheck] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [combinedUser, setCombinedUser] = useState({});

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, type, heading, message) => {
    notification[type]({
      message: heading,
      description: message,
      placement,
    });
  };

  const showDrawer = () => {
    setEditMode(false);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showDrawerForEdit = () => {
    setOpen(true);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setGlobalFilterValue(value);
    const filteredData = users.filter((user) =>
      ["name", "location", "mobile", "userType", "creationDate"].some((key) => {
        if (key === "creationDate") {
          return user[key]
            ? moment(user[key]).format("DD-MM-YYYY").includes(value)
            : false;
        }
        return String(user[key]).toLowerCase().includes(value);
      })
    );
    setFilteredUsers(filteredData);
  };

  const fetchUsers = async () => {
    setApiLoading(true);
    const response = await getUsers();
    if (response.status === 200) {
      setUsers(response.data.sort((user1, user2) => user1.id - user2.id));
      setFilteredUsers(response.data);
      setApiLoading(false);
    } else {
      setApiLoading(false);
    }
  };

  const fetchRoles = async () => {
    const response = await getRoles();
    if (response.status === 200) {
      setRolesOptions(response.data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [editCheck]);

  const fetchCombinedUser = async (id) => {
    setFetching(true);
    const response = await getCombinedUser(id);
    if (response.status === 200) {
      setCombinedUser(response.data);
      setFetching(false);
      showDrawerForEdit();
    }
  };

  const confirmDelete = (id) => {
    deleteUsers(id).then((res) => {
      if (res.status === 200) {
        fetchUsers();
        openNotification(
          "bottomRight",
          "success",
          "Deletion Success",
          "User deleted successfully"
        );
        setModal2Open(false);
      } else {
        openNotification(
          "bottomRight",
          "error",
          "Deletion Error",
          "Failed to delete user"
        );
        setModal2Open(false);
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      filters: [
        { text: "Distributor", value: "Distributor" },
        { text: "Donor", value: "Donor" },
        { text: "Deservant", value: "Deservant" },
        { text: "Lecturer", value: "Lecturer" },
        { text: "Admin", value: "Admin" },
      ],
      onFilter: (value, record) => record.userType.includes(value),
    },
    {
      title: "Created On",
      dataIndex: "creationDate",
      key: "creationDate",
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Modal
            title="Confirm Deletion"
            centered
            open={modal2Open}
            okText="Delete"
            onOk={() => confirmDelete(currentRecordId)}
            onCancel={() => setModal2Open(false)}
            okButtonProps={{ danger: true }}
            maskProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.2)" } }}
          >
            <p>Are you sure you want to delete this user ?</p>
          </Modal>

          <Tooltip title="View Details" placement="top">
            <EyeOutlined
              className="hover:text-blue-500 duration-300 ease-in-out"
              style={{
                fontSize: "22px",
                color: "gray", // set to gray to appear disabled
                cursor: "not-allowed", // changes the cursor to indicate it's disabled
                pointerEvents: "none",
              }}
              disabled={true}
            />
          </Tooltip>

          <Tooltip title="Edit User" placement="top">
            {fetching && record.id === currentRecordId ? (
              <LoadingOutlined style={{ fontSize: "20px" }} />
            ) : (
              <EditOutlined
                className="hover:text-blue-500 duration-300 ease-in-out"
                style={{ fontSize: "22px" }}
                onClick={() => {
                  fetchCombinedUser(record.id);
                  setEditMode(true);
                  setCurrentRecordId(record.id);
                }}
              />
            )}
          </Tooltip>
          <Tooltip title="Delete User" placement="top">
            <DeleteOutlined
              className="delete-icon"
              style={{ fontSize: "22px" }}
              onClick={() => {
                setCurrentRecordId(record.id);
                setModal2Open(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Users</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            placeholder="Search User"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            style={{
              width: 200,
            }}
          />
        </span>
      </span>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        Add New
      </Button>
    </React.Fragment>
  );
  return (
    <div className="layout-main">
      <Drawer
        title={editMode ? "Edit User" : "Add User"}
        width={500}
        onClose={onClose}
        open={open}
        styles={{
          paddingBottom: 80,
        }}
      >
        <UserForm
          onSubmitSuccess={fetchUsers}
          hide={onClose}
          editMode={editMode}
          editorValues={combinedUser}
        />
      </Drawer>
      <React.Fragment>
        <div>
          <Spin spinning={apiLoading}>
            <div>
              <Toolbar
                className="mb-4"
                left={leftContents}
                right={rightContents}
              ></Toolbar>
            </div>
            <Table dataSource={filteredUsers} columns={columns} />
          </Spin>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Users;
