import { setCookie, getCookie, removeCookie } from 'tiny-cookie';

export const getSessionIdentifier = () => {
  return getCookie('_is_session') || localStorage.getItem('_is_session');
};

export const setSessionIdentifier = (val) => {
  setCookie('_is_session', val);
  localStorage.setItem('_is_session', val);
};

export const clearSessionIdentifier = () => {
  removeCookie('_is_session');
  localStorage.removeItem('_is_session');
};

export const getLastSessionActivity = () => {
  return (
    getCookie('_last_session_activity') ||
    localStorage.getItem('_last_session_activity')
  );
};

export const setLastSessionActivity = (val) => {
  setCookie('_last_session_activity', val);
  localStorage.setItem('_last_session_activity', val);
};

export const clearLastSessionActivity = () => {
  removeCookie('_last_session_activity');
  localStorage.removeItem('_last_session_activity');
};
