export const downloadedFile = (response, cycleData) => {
  const moment = require("moment");
  var fileName =
    "Rashan Detail (" +
    moment(cycleData.startDate).format("MMM-YY").toUpperCase() +
    ").pdf";
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

export const downloadDonationReceiptFile = (response, donation) => {
  const moment = require("moment");
  var fileName =
    donation.donorName +
    " - " +
    moment(donation.creationDate).format("LL").toUpperCase() +
    " - " +
    donation.amount +
    ".pdf";
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

export const downloadAllDonationsReceiptFile = (response, cycle) => {
  const moment = require("moment");
  var fileName = "E-Receipts for " + cycle.cycleName + ".pdf";
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

export const checkToken = (history) => {
  if (
    (!localStorage.getItem("user") || localStorage.getItem("user") === {}) &&
    !localStorage.getItem("__Secure-floorplanner_token")
  ) {
    history.push("/");
  }
};
