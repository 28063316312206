import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const uploadCycleImage = (data) => {
    return axios.post(APP_CONTEXT_URL + "/v1/cycleimage", data, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};
export const deleteCycleImage = (id) => {
    return axios.delete(APP_CONTEXT_URL + "/v1/deletecycleimage/" + id, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const getReciptImage = (cycleId,fileName) => {
    return axios.post(APP_CONTEXT_URL + "/v1/getCycleImage/" + cycleId,fileName ,{
        headers: {
            'Content-Type': 'plain/text',
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
            
        },
    });
};