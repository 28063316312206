import React, { useState, useEffect, useRef } from "react";
import { getItemDetailsById } from "../../../apis/ItemsApi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ItemPriceDetail = (props) => {
  const dt = useRef(null);
  const [itemsDetails, setItemDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const moment = require("moment");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadItemData = () => {
    setLoading(true)
    getItemDetailsById(props?.item?.id).then((res) => {
      if (res.status === 200) {
        setItemDetails(res.data);
        setLoading(false)
      }
      setLoading(false)
    });
  };

  useEffect(() => {
    if (props?.item?.id) {
      loadItemData();
    }
  }, [props]);

  return (
    <>
      <DataTable
        ref={dt}
        value={itemsDetails}
        // selection={selectedCycle}
        // onRowClick={(e) => onRowClick(e.data)}
        // onSelectionChange={(e) => setSelectedCycle(e.value)}
        dataKey="id"
        // paginator
        // rows={10}
        // rowHover
        // rowsPerPageOptions={[10, 50, 100]}
        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cycle"
        scrollable
        // filters={filters}
        // filterDisplay="menu"
        // globalFilterFields={[
        //   "startDate",
        //   "noOfItems",
        //   "totalDonation",
        //   "totalAmount",
        //   "tax",
        //   "discountedPrice",
        // ]}
        loading={loading}
        // emptyMessage="No cycles found."
        responsiveLayout="scroll"
        className="table-sel mytab "
      >
        <Column
          field="startDate"
          header="Cycle"
          style={{ width: "16rem" }}
          filterPlaceholder="Search by startDate"
          body={(record) => {
            return (
              "CYC-" +
              moment(record?.cycleId?.startDate).format("MMM-YY").toUpperCase()
            );
          }}
        />
        <Column
          field="price"
          header="Price"
          style={{ width: "16rem" }}
          filterPlaceholder="Search by price"
        />

        <Column
          field="quantity"
          header="Quantity"
          style={{ width: "16rem" }}
          filterPlaceholder="Search by quantity"
          body={(record) => {
            return (record?.quantity+" "+record?.itemId?.unit)
              
  }}
        />
      </DataTable>
    </>
  );
};
export default ItemPriceDetail;
