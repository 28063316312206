import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllItems = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/items", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addItems = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/items", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const updateItems = (values) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/items", values, {
//       headers: {
//           "Access-Control-Allow-Origin": "*",
//           Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//       },
//   });
// };

export const deleteItems = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/items/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const  getItemDetailsById = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/cycleitemsDetail/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const itemsName = (items) => {
  return axios.post(APP_CONTEXT_URL + "/v1/itemFilter",
    items,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      }
  });
};