import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "./Distributor.scss";
import { Form, Input, DatePicker, TimePicker, InputNumber, Select } from "antd";
import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
// import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { FileUpload } from "primereact/fileupload";
import { MultiSelect } from "primereact/multiselect";
import {
  getAllDistributors,
  addDistributors,
  updateDistributors,
  deleteDistributors,
  uploadImageDistributor,
  countDistributors,
  getDistributorsFilter,
} from "../../../apis/DistributorApi";
import { getAllLocation } from "../../../apis/LocationApi";
import { getAllAreas } from "../../../apis/AreaApi";
import { Paginator } from "primereact/paginator";
const Distributor = () => {
  const { Search } = Input;
  const [distributors, setDistributors] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [areaList, setAreaList] = useState(null);
  const [deleteDeservantDialog, setDeleteDeservantDialog] = useState(false);
  const [deleteDistributor, setDeleteDistributor] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const moment = require("moment");
  const [form] = Form.useForm();
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [basicFirst, setBasicFirst] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalDistributor, setTotalDistributor] = useState();
  const [locationTable, setLocationTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [field, setField] = useState("id");
  const [order, setOrder] = useState("asc");
  const [selectedArea, setSelectedArea] = useState(null);
  const [searchedValue, setSearchedValue] = useState("");
  const ref = useRef();
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: "userId.id", order: -1 },
  ]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "areaId.areaName": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.email": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.address": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const onGlobalFilterChange = (e) => {
    setFilters(e.target.value);
    setSearchedValue(e.target.value); //new
    setGlobalFilterValue(e.target.value);
    if (ref.current) clearTimeout(ref.current);
    if (e.target.value !== "") {
      ref.current = setTimeout(() => {
        setFilters(e?.target.value);
        var filter = {
          search: e?.target.value,
        };
        filterDistributors(filter);
      }, 1000);
    } else {
      setSearchedValue("");
      setFilters("");
      DistributorCount();
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const openNew = () => {
    reset();
    setsidebarVisible(true);
  };
  const confirmDeleteSelected = (id) => {
    setDeleteDeservantDialog(true);
    setDeleteDistributor(id);
  };

  const loadAreaData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data);
        setLoading(false);
      }
    });
  };
  // const header = (
  //   <div className="table-header tab-header-adjuster-distributor">
  //     <h5 className="mx-0 my-1">Manage Distributors</h5>
  //     <span className="p-input-icon-left del-con">
  //       <span className="p-input-icon-left">
  //         <i className="pi pi-search" />
  //         <InputText
  //           value={globalFilterValue}
  //           onChange={onGlobalFilterChange}
  //           placeholder="Search"
  //         />
  //       </span>
  //       <Button
  //         label="New"
  //         icon="pi pi-plus"
  //         className="p-button-success mr-2"
  //         onClick={openNew}
  //       />
  //       </span>
  //   </div>
  // );

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Distributors</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            className="w-20rem"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );
  const loadAllDistributors = () => {
    setLoading(true);
    getAllDistributors(field, order, currentPage, pageSize).then((res) => {
      if (res.status === 200) {
        setDistributors(res.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const DistributorCount = (currentPage, pageSize) => {
    setLoading(true);
    countDistributors().then((res) => {
      if (res.status === 200) {
        setTotalDistributor(res.data);
        loadAllDistributors(currentPage, pageSize);
      }
      setLoading(false);
    });
  };

  const fetchAreaList = () => {
    getAllAreas().then((res) => {
      if (res.status === 200) {
        setAreaData(res.data);
        setAreaList(res.data);
      }
    });
  };

  useEffect(() => {
    // DistributorCount();
    fetchAreaList();
    loadAreaData();
  }, []);
  const hideDialog = () => {
    loadAllDistributors();
    form.resetFields();
    reset();
    setsidebarVisible(false);
  };

  const areaShow = (rowData) => {
    return rowData.areaId.areaName;
  };

  const onSubmit = async (data) => {
    if (isEdit) {
      let dataForApi = {
        id: valuesForEdit ? valuesForEdit.id : null,
        userId: {
          id: valuesForEdit.userId.id,
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          address: data.address,
          cnic: data.cnic,
          location: { id: data.location.id },
        },

        areaId: { id: data.areaId.id },
        // location: {id: data.location.id },
      };
      await updateDistributors(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Distributor Updated Successfully",
            life: 3000,
          });
        }
      });
    } else {
      let dataForApi = {
        id: valuesForEdit ? valuesForEdit.id : null,
        userId: {
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          location: data.location,
          cnic: data.cnic,
          address: data.address,
        },
        areaId: { id: data.areaId.id },
      };
      addDistributors(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
        }
      });
    }
  };

  const deleteSelectedDeservants = () => {
    deleteDistributors(deleteDistributor).then((response) => {
      if (response.status === 200) {
        loadAllDistributors();
        setDeleteDeservantDialog(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Distributor Deleted",
          life: 3000,
        });
      } else {
        toast.current.show({
          className: "error-shower",
          severity: "Error",
          summary: "Error",
          detail:
            "Delete the deservants associated with this distributor first",
          life: 6000,
        });
        setDeleteDeservantDialog(false);
        reset();
      }
    });
  };
  const hideDeleteProductsDialog = () => {
    setDeleteDeservantDialog(false);
    setDeleteDistributor("");
  };
  const deleteDeservantDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedDeservants}
      />
    </React.Fragment>
  );

  useEffect(() => {
    if (valuesForEdit) {
      setValue("userId", valuesForEdit.userId.id);
      setValue("areaId", valuesForEdit.areaId);
      setValue("name", valuesForEdit.userId.name);

      setValue("mobile", valuesForEdit.userId.mobile);
      setValue("email", valuesForEdit.userId.email);
      setValue("cnic", valuesForEdit.userId.cnic);
      setValue("creationDate", new Date(moment(valuesForEdit.creationDate)));
      setValue("address", valuesForEdit.userId.address);
      setValue("location", valuesForEdit.userId.location.locationName);
    }
  }, [valuesForEdit]);

  const onBasicPageChange = (event) => {
    setLoading(true);
    setBasicFirst(event.first);
    setCurrentPage(event.page);
    setPageSize(event.rows);
    setLoading(false);
  };

  const customBase64Uploader = (event) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadImageDistributor(valuesForEdit.id, formData).then((res) => {
      if (res.status === 200) {
        hideDialog();
        reset();
      }
    });
  };

  const filterDistributors = (filter) => {
    getDistributorsFilter(filter, currentPage, pageSize).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setDistributors(response.data.distributors);
        setTotalDistributor(response.data.count);
      } else {
        // show error
      }
    });
  };

  useEffect(() => {
    var filter = {};
    setLoading(true);
    if (searchedValue === "" && selectedArea?.length > 0) {
      filter["areaNames"] = selectedArea.map((area) => area.areaName);
    }
    filterDistributors(filter);
  }, [currentPage, pageSize, field, order, selectedArea]);

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const representativeMultiSelectBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-0">
          <div>
            <Avatar
              label={rowData?.areaName?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          </div>
          <div>
            <p>{rowData?.areaName}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const areaFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setSelectedArea(e.value);
          options.filterCallback(e?.value); //new
        }}
        options={areaData}
        itemTemplate={representativeMultiSelectBodyTemplate}
        optionLabel="areaName"
        placeholder="Select Area"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "22.5rem" }}
      />
    );
  };

  const representativeBodyTemplate = (rowData) => {
    var imgpath = "data:image/jpg;base64, " + rowData.userId?.profileImage;
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          {rowData.userId?.profileImage ? (
            <Avatar
              className="mr-2"
              size="large"
              shape="circle"
              image={imgpath}
            />
          ) : (
            <Avatar
              label={rowData?.userId?.name?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          )}
          <div>
            <p>{rowData?.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            dataKey="id"
            value={distributors}
            className="p-datatable-distributor mytab"
            scrollable
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            globalFilterFields={[
              "userId.name",
              "areaId.areaName",
              "userId.mobile",
              "userId.email",
              "userId.address",
              "userId.cnic",
              "userId.locationName",
            ]}
            emptyMessage="No distributor found."
            responsiveLayout="scroll"
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            onSort={(e) => {
              e?.multiSortMeta[0]?.order === 1
                ? setOrder("asc")
                : setOrder("desc");
              setMultiSortMeta(e.multiSortMeta);
              setField(e?.multiSortMeta[0]?.field);
            }}
          >
            <Column
              field="userId.name"
              header="Name"
              style={{ minWidth: "12rem" }}
              body={representativeBodyTemplate}
              sortable
              sortField="name"
              // filterPlaceholder="Search by userId.name"
              showFilterMenu={true}
              filterMenuStyle={{ width: "12rem" }}
              // filter
              // filterElement={distributorFilter}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />
            <Column
              field="userId.location.locationName"
              header="Location"
              style={{ minWidth: "14rem" }}
              sortable
              sortField="location"
              filterPlaceholder="Search by locationName"
            />

            <Column
              field="areaId.areaName"
              body={areaShow}
              header="Area Name"
              style={{ minWidth: "20rem" }}
              sortable
              sortField="areaName"
              filterPlaceholder="Search by areaId.areaName"
              showFilterMenu={true}
              // filterMenuStyle={{ width: '20rem' }}
              filter
              filterElement={areaFilter}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />

            <Column
              field="userId.mobile"
              header="Mobile"
              body={(record) => {
                return record.userId.mobile;
              }}
              style={{ minWidth: "12rem" }}
              filterPlaceholder="Search by userId.mobile"
            />

            <Column
              field="userId.email"
              header="Email"
              body={(record) => {
                return record.userId.email;
              }}
              style={{ minWidth: "12rem" }}
              filterPlaceholder="Search by userId.email"
            />

            <Column
              field="userId.address"
              header="Address"
              body={(record) => {
                return record.userId.address;
              }}
              style={{ minWidth: "12rem" }}
              filterPlaceholder="Search by userId.address"
            />

            <Column
              field="userId.cnic"
              header="CNIC"
              body={(record) => {
                return record.userId.cnic;
              }}
              style={{ minWidth: "12rem" }}
              filterPlaceholder="Search by userId.cnic"
            />

            <Column
              header="Action"
              fixed="Right"
              frozen
              alignFrozen="right"
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined"
                    onClick={(e) => {
                      setValuesForEdit(field);
                      setsidebarVisible(true);
                      setIsEdit(true);
                      // form.setFieldsValue({
                      //   id: field.id,
                      //   areaName: field.areaName,
                      //   creationDate: moment(
                      //     new Date(field.creationDate),
                      //     "MM/DD/YYYY"
                      //   ),
                      //   //   creationDate : moment(field.creationDate).format("MM/DD/YYYY"),
                      // });

                      // setProductDialog(true);
                    }}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded red-color-btn p-button-spacing"
                    onClick={(e) => confirmDeleteSelected(field.id)}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            rows={pageSize}
            totalRecords={totalDistributor}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
            template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Distributor"
          ></Paginator>
        </div>
        <Sidebar
          visible={sidebarVisible}
          position="right"
          style={{ width: "60em" }}
          onHide={() => {
            setsidebarVisible(false);
            setValuesForEdit(null);
            setIsEdit(false);
          }}
        >
          <div className="flex justify-content-center">
            <div className="card width-adjuster-for-add-deservant-form">
              <h5 className="text-center font-for-register">Distributor</h5>
              <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid">
                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="areaName"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Area Name </b>
                      <span className="color-for-str">*</span>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="areaId"
                        control={control}
                        rules={{ required: "Area is required." }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            {...field}
                            autoFocus
                            options={areaList}
                            optionLabel={(option) => {
                              return option.areaName;
                            }}
                            placeholder="Select Area"
                          />
                        )}
                      />
                      {getFormErrorMessage("areaId")}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "30px" }}>
                    <label
                      htmlFor="location"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Location </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="location"
                        control={control}
                        // rules={{ required: "you need to choose a referre." }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            {...field}
                            autoFocus
                            options={locationTable}
                            optionLabel={(option) => {
                              return option.locationName;
                            }}
                          />
                        )}
                      />
                      {/* {getFormErrorMessage("referredBy")} */}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="name"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Name </b>
                      <span className="color-for-str">*</span>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("name")}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="mobile"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Mobile </b>
                      <span className="color-for-str">*</span>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{
                          required: "Mobile Number is required.",
                          pattern: new RegExp(/^[0-9]{11}$/),
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("mobile")}
                    </div>
                    {isEdit ? (
                      <div
                        className="field grid"
                        style={{ width: "100%", marginTop: "15px" }}
                      >
                        <label
                          htmlFor="profileImage"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          <b> Profile Image </b>
                        </label>
                        <div className="col-10 md:col-8 left-margin-giver">
                          <Controller
                            control={control}
                            name="demo"
                            render={({ field, fieldState }) => (
                              <FileUpload
                                mode="basic"
                                name="file"
                                accept="image/*"
                                maxFileSize={1000000}
                                customUpload
                                uploadHandler={customBase64Uploader}
                                chooseLabel="Browse"
                                auto
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <>
                    <div className="field grid" style={{ marginTop: "5px" }}>
                      <label
                        htmlFor="Email"
                        className="col-12 mb-2 md:col-3 md:mb-0"
                      >
                        <b> Email </b>
                      </label>

                      <div className="col-10 md:col-8">
                        <Controller
                          name="email"
                          control={control}
                          // rules={{ required: "Email is required." }}
                          rules={{
                            pattern: {
                              value: new RegExp(
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                              ),
                              message:
                                "Invalid email address. E.g. example@email.com",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <InputText
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              id={field.name}
                              {...field}
                              autoFocus
                            />
                          )}
                        />
                        {getFormErrorMessage("email")}
                      </div>
                    </div>
                  </>

                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="address"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b> Address </b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="address"
                        control={control}
                        // rules={{ required: "Address is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            type={"address"}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("address")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "5px" }}>
                    <label
                      htmlFor="cnic"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>CNIC</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="cnic"
                        control={control}
                        // rules={{ required: "CNIC is required." }}
                        rules={{
                          pattern: {
                            value: RegExp(/^[0-9]{5}-[0-9]{7}-[0-9]$/),
                            message: "Please enter correct format of CNIC",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            type={"cnic"}
                            // keyfilter="alphanum"
                            placeholder="Enter valid CNIC Format "
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                            autoFocus
                          />
                        )}
                      />
                      {getFormErrorMessage("cnic")}
                    </div>
                  </div>

                  {/* <div className="field grid" style={{ marginTop: "5px" }}>
              <label
                htmlFor="creationDate"
                className="col-12 mb-2 md:col-3 md:mb-0"
              >
                Creation Date
              </label>
              <div className="col-10 md:col-8">
                <Controller
                  name="creationDate"
                  control={control}
                  rules={{ required: "Creation Date is required." }}
                  render={({ field, fieldState }) => (
                    <Calendar
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.name}
                      {...field}
                      autoFocus
                      showIcon
                    />
                  )}
                />
                {getFormErrorMessage("creationDate")}
              </div>
            </div> */}

                  <Button type="submit" label="Submit" className="submit-btn" />
                  <Button
                    type="cancel"
                    label="Cancel"
                    className="p-button-danger cancel-btn"
                    onClick={hideDialog}
                  />

                  {/* </div> */}
                </div>
              </form>
            </div>
          </div>
        </Sidebar>
        <Dialog
          visible={deleteDeservantDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteDeservantDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {
              <span>
                Are you sure you want to delete the selected products?
              </span>
            }
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Distributor;
