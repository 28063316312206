import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';
import { Provider as StateProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import { initAxiosInterceptors } from './utils/axios-interceptors'
import reportWebVitals from "./reportWebVitals";

initAxiosInterceptors();
const Root = () => (
  <Router>
    <StateProvider key={4321} store={store}>
      <App />
    </StateProvider>
  </Router>
);
reportWebVitals();
ReactDOM.render(<Root />, document.getElementById('root'));