import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allDonor = (field, order, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/allDonor?page=" + current + "&size=" + pageSize,
    { field, order },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const addDonor = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/donor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const updateDonor = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/donor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteDonor = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/donor/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uploadImageDonor = (donorId, file) => {
  return axios.post(APP_CONTEXT_URL + `/v1/uploadimagedonor/${donorId}`, file, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getDonorCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donorCount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const donorSearch = (text, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/donorSearch?page=" + current + "&size=" + pageSize,
    { text },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const donorSearchCount = (text, current, pageSize) => {
  return axios.get(APP_CONTEXT_URL + "/v1/donorSearchCount/" + text, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllDonorList = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/getAllDonorList", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllDonors = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/getAllDonors", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const donorBYList = (referred) => {
  return axios.post(APP_CONTEXT_URL + "/v1/donorsReferrer", referred, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uniqueReferrers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donor/uniqueReferrers", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const getDonorFilter = (filter, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/getDonorByFilter?page=" +
      current +
      "&size=" +
      pageSize,
    filter,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};
