import React from "react";
import "./privacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="main-body text-gray-900">
      <h1 className="text-center mt-6 text-6xl lg:text-7xl text-gray-900">
        Nukrim's Privacy Policy
      </h1>
      <div className="intro mt-6r text-xl lg:text-4xl">
        Nukrim is committed to protecting the privacy and security of your
        personal information. This privacy policy explains how we collect, use,
        disclose, and safeguard your information when you use our app.
      </div>
      <div className="container mt-6 text-xl lg:text-3xl">
        Please read this privacy policy carefully. If you do not agree with the
        terms of this privacy policy, please do not access the app. We reserve
        the right to make changes to this Privacy Policy at any time and for any
        reason. You are encouraged to periodically review this Privacy Policy to
        stay informed of updates. You will be deemed to have been made aware of,
        will be subject to, and will be deemed to have accepted the changes in
        any revised Privacy Policy by your continued use of the app after the
        date such revised Privacy Policy is posted.
      </div>
      <div className=" mt-6 text-4xl">
        <h2 className="text-4xl lg:text-5xl">1. Information We Collect</h2>
        <p>
          We collect personal information to provide and improve our services.
          Personal information may include your contact details such as your
          name, email address, and phone number, account credentials, payment
          and transaction details, and interaction records related to donations
          and distributions.
        </p>

        <h2 className="text-4xl lg:text-5xl">2. How We Use Your Information</h2>
        <p>
          We use your information to manage accounts, which includes activities
          like registration, login, and profile management. It also helps us
          process donations and distributions by tracking contributions,
          managing donation cycles, and recording distributions. Additionally,
          your information allows us to provide support by assisting with
          inquiries, technical issues, and gathering user feedback. Lastly, we
          use your information to communicate with you by sending updates,
          notifications, and important information about the donation cycles.
        </p>

        <h2 className="text-4xl lg:text-5xl">3. Information Sharing</h2>
        <p>
          We are committed to protecting your privacy and do not sell or share
          your personal information with third parties. Additionally, we may
          disclose information when required by law, regulation, or legal
          process, or to protect the rights, property, or safety of our users.
        </p>

        <h2 className="text-4xl lg:text-5xl">4. Data Security</h2>
        <p>
          We take data security seriously and implement industry-standard
          measures to protect your information from unauthorized access,
          disclosure, alteration, or destruction. Access to personal information
          is restricted to authorized personnel who need it to perform their job
          functions.
        </p>

        <h2 className="text-4xl lg:text-5xl">5. User Rights</h2>
        <p>
          You have several rights concerning your personal data. You can access
          and update your personal information through your account settings. If
          necessary, you may request the deletion of your personal data by
          contacting us, and we will comply with such requests as required by
          applicable law. Additionally, you can opt out of receiving
          non-essential communications by contacting us.
        </p>

        <h2 className="text-4xl lg:text-5xl">6. Changes To This Policy</h2>
        <p>
          We may update this privacy policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review this policy periodically to stay
          informed of how we collect, use, and protect your information.
        </p>

        <h2 className="text-4xl lg:text-5xl">7. Contact Us</h2>
        <p>
          If you have any questions or concerns about this privacy policy or our
          data practices, please contact us at nukrim@muhaimintech.com
        </p>
      </div>
      <div className="end"></div>
    </div>
  );
};

export default PrivacyPolicy;
