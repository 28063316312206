import { setCookie, getCookie, removeCookie } from "tiny-cookie";

export const getAuthToken = () => {
  //TDOD, have to get token from cookies
  // return "Basic ZmFoZWVtOnNwbDEyMw==";
  return (
    getCookie("__Secure-rashanDistribution_token") ||
    localStorage.getItem("__Secure-rashanDistribution_token")
  );
};

export const setAuthToken = (token) => {
  setCookie("__Secure-floorplanner_token", token);
  localStorage.setItem("__Secure-rashanDistribution_token", token);
};

export const clearAuth = () => {
  removeCookie("__Secure-floorplanner_token");
  localStorage.removeItem("__Secure-rashanDistribution_token");
};
