import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllDistributors = (field,order,current, pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/getDistributors?page=" + current + "&size=" + pageSize,{field,order}, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addDistributors = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addDistributor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const updateDistributors = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/updateDistributor", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteDistributors = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteDistributor/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const countDistributors = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/distributorscount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const uploadImageDistributor = (distributorId, file) => {
  return axios.post(APP_CONTEXT_URL + `/v1/uploadimagedistributor/${distributorId}`, file, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const distributorSearchCount = (text, current, pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/distributorSearchCount", text, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const distributorSearch = (text, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/distributorSearch?page=" + current + "&size=" + pageSize,
    { text },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const allDistributorsList = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/allDistributorsList", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const areaDistributorsList = (areas) => {
  return axios.post(APP_CONTEXT_URL +  "/v1/areaDistributors",areas, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const areaFliterList = (list,current,pageSize) => {
//   return axios.post(APP_CONTEXT_URL +  "/v1/filterArea?page=" +
//   current +
//   "&size=" +
//   pageSize,list ,{
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

// export const getFilterDistributorCount= (list) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/filterdistributorcount",list ,{
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };
export const getDistributorsFilter= (filter,current,pageSize) => {
    return axios.post(APP_CONTEXT_URL + "/v1/getDistributorsByFilter?page=" +
      current +
      "&size=" +
      pageSize,filter ,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      },
    });
  };