import React, { useState, useEffect, useRef } from "react";
import "./perDonorDonations.scss";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Form } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { MultiSelect } from 'primereact/multiselect';
import { getAllDonationsBySpecificDonor } from "../../../apis/DonationApi";

const PerDonorDonations = (props) => {
  const [allDonationsBySpecificDonor, setAllDonationsBySpecificDonor] =
    useState([]);
  const [donarName, setDonarName] = useState();
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "cycleid.startDate": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    amount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    donationType: { value: null, matchMode: FilterMatchMode.IN },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const loadAllDonationsBySpecificDonor = (donorId) => {
    getAllDonationsBySpecificDonor(donorId).then((res) => {
      if (res.status === 200) {
        setAllDonationsBySpecificDonor(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (props) {
      loadAllDonationsBySpecificDonor(props?.donarId.id);
      setDonarName(props?.donarId.userId.name);
    }
  }, []);

  const renderHeader = () => {
    return (
      <div className="table-header tab-adjuster-perdonor-donations">
        <h5 className="m-0">
          {"Donor Details: " +
            donarName?.charAt(0).toUpperCase() +
            donarName?.slice(1).toLowerCase()}
        </h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            value={allDonationsBySpecificDonor}
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilterFields={["cycleid.startDate", "amount", "donationType"]}
            emptyMessage="No donations found."
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            loading={loading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} donations"
            header={header}
            className="table-sel"
          >
            <Column
              // selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="cycleid.startDate"
              header="Cycle Name"
              body={(record) => {
                return moment(record.cycleid.startDate).format("MMMM, YYYY");
              }}
              sortable
              filterPlaceholder="Search by cycleid.startDate"
              style={{ minWidth: "14rem" }}
            />

            <Column
              field="amount"
              header="Amount"
              body={(record) => {
                return record.amount;
              }}
              sortable
              filterPlaceholder="Search by amount"
              style={{ minWidth: "14rem" }}
            />

            <Column
              field="donationType"
              header="Donation type"
              body={(record) => {
                return record.donationType;
              }}
              sortable
              filterPlaceholder="Search by donationtype"
              style={{ minWidth: "14rem" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PerDonorDonations;
