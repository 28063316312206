import axios from 'axios';
import { resetEntities } from './../actions/data';
import { dispatch } from './../store/index';
import { clearAuth } from './../utils/authToken';

export function initAxiosInterceptors() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.appHistory.push('/login');
        clearAuth();
        dispatch(resetEntities());
      }

      return error;
    }
  );
}
