import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { deservantsBagDeliverStatus } from "../../../apis/CycleDeseravntsApi";

function DistributorStatus(props) {
  const [selectedDeservants, setSelectedDeservants] = useState([]);

  const toast = useRef(null);
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Deservant's Status</h5>
    </React.Fragment>
  );

  const submitData = () => {
    var deservantsIds = [];
    selectedDeservants.forEach((deservants) => {
    deservantsIds.push(deservants.deservantId.id); 
    });

    deservantsBagDeliverStatus(props.cycleId, deservantsIds).then(
      (response) => {
        if (response.status === 200) {
          props.updateData();
          props.hide();
        }
      }
    );
  };

  useEffect(() => {
    let attendedDeservants = [];
    props.deservants.forEach((deservant) => {
      if (deservant.deliveryStatus === true) attendedDeservants.push(deservant);
    });
    setSelectedDeservants(attendedDeservants);
  }, [props.deservants]);

  return (
    <React.Fragment>
      <div>
        <Toolbar style={{ border: "hidden" }} left={leftContents} />
      </div>
      <Toast ref={toast} position="top-center" />

      <div className="card">
        <DataTable
          value={props.deservants}
          responsiveLayout="scroll"
          selection={selectedDeservants}
          onSelectionChange={(e) => setSelectedDeservants(e.value)}
          globalFilterFields={["deservant.userId.name"]}
          emptyMessage="No Desrvent found."
          className="table-sel mytab"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3em" }}
          ></Column>
          <Column
            key="id"
            header="Deservant's Name"
            sortable
            filterPlaceholder="Search by deliveryStatus"
            headerStyle={{ width: "3em" }}
            field="deservantId.userId.name"
            body={(record) => {
              return record.deservantId.userId.name;
            }}
            style={{ marginRight: "130px" }}
          ></Column>
        </DataTable>
        <Button label="Submit" className="submit-btn" onClick={submitData} />;
      </div>
    </React.Fragment>
  );
}

export default DistributorStatus;
