import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import CycleDistributorData from "../CycleDistributor/cycleDistributorData";
import CycleItemData from "../CycleItems/cycleItemData";
import "./cycleDetail.scss";

function CycleDetails(props) {
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">
        {"CYC-" +
          (props.location.state.cycleId.cycleName)

        }
      </h5>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div>
        <Toolbar
          style={{ border: "hidden", width: "99%" }}
          left={leftContents}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "81%" }}>
          <TabView className="selector-identifier">
            <TabPanel header="Deservants">
              <CycleDistributorData cycleId={props.location.state.cycleId} />
            </TabPanel>
            <TabPanel header="Items">
              <CycleItemData cycleId={props.location.state.cycleId} />
            </TabPanel>
          </TabView>
        </div>
        <div
          style={{
            width: "16%",
            marginLeft: "32px",
          }}
          className="padd-adjuster-for-summary"
        >
          <Card>
            <h4 className="heading-selector">
              <b>
                <u>Cycle Summary</u>
              </b>
            </h4>
            <div className="cycle-summary-view">
              <div className="summaryCon">
                <p className="valumarginlizer">Single Bag Price:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.bagPrice.toFixed(
                    0
                  )}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Transport Charges:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.transportCharges.toFixed(
                    0
                  )}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Total Deservants:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.totalDeservants.toFixed(
                    0
                  )}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Total Bags Price:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.totalBagPrice.toFixed(
                    0
                  )}
                </p>
              </div>

              <div className="summaryCon">
                <p className="valumarginlizer">Discounted Price:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.discountedPrice.toFixed(
                    0
                  )}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Amount Paid:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.amountPaid.toFixed(
                    0
                  )}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Tax:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.tax.toFixed(0)}
                </p>
              </div>
              <div className="summaryCon">
                <p className="valumarginlizer">Grand Total:</p>
                <p className="valumarginlizer">
                  {props.location.state.cycleId.expenseDetails.grandTotal.toFixed(
                    0
                  )}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CycleDetails;
