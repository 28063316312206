import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allCycleDistributors = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/cycledistributor", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const getAllCurrentCycleDeservants = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/currentcycledeservants/{cycleId}", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const getDeservantCount = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/cycledeservantscount", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};


export const deservantsBagDeliverStatus = (cycleId, values) => {
    return axios.post(APP_CONTEXT_URL + "/v1/updatedeliverystatus/" + cycleId, values, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      },
    });
  };