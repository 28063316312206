import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./style.css";
// import RegisterForm from "../register/registerform";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputMask } from "primereact/inputmask";
import * as Yup from "yup";
import { createOne } from "../../apis/UserApi";
import { Spin } from "antd";

const Register = (props) => {
  document.documentElement.style.fontSize = "13px";

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password must be provided")
      .min(5, "Password must be at 5 char long"),
    cPwd: Yup.string()
      .required("Password must be provided")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
    name: Yup.string().required("Name must be provided"),
    username: Yup.string().required("UserName must be provided"),
    mobile: Yup.string().required("mobile must be provided"),
  });

  const { control, register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;
  const toast = useRef(null);
  const[apiLoading,setApiLoading]=useState(false);

  const onSignupSubmit = (data) => {
    setApiLoading(true)
    createOne(data).then((res) => {
      if (res.status === 200) {
        reset();
        props.setVisibility(false);
        setApiLoading(false)
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Successful Registration",
          life: 3000,
        });
      } else {
        setApiLoading(false)
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.response.data,
          life: 6000,
        });
      }
      setApiLoading(false)
    });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} position="top-center" />
      <Dialog
        header="Sign Up"
        visible={props.visible}
        onHide={() => {
          props.setVisibility(false);
          reset();
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "40vw" }}
      >
        <Spin spinning={apiLoading}>
        <div>
          <form onSubmit={handleSubmit(onSignupSubmit)} className="p-fluid">
            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="name"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Name<span style={{ color: "red" }}> *</span>
                </label>
              </div>

              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    className="custom-width-sp mb-3"
                    {...register("name")}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.name?.message}</div>
            </div>
            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="username"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  UserName<span style={{ color: "red" }}> *</span>
                </label>
              </div>

              <Controller
                name="username"
                control={control}
                rules={{
                  required: "UserName is required.",
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.username}
                    {...field}
                    autoFocus
                    // pattern="^[^\s]*$"
                    // placeholder="without Space"
                    // onBlurCapture={(e) => {
                    //   if (e.target.value !== "") {
                    //     checkUserName(e.target.value);
                    //   }
                    // }}
                    className="custom-width-sp mb-3"
                    {...register("username")}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.username?.message}</div>
            </div>
            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="email"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Email
                </label>
              </div>
              <Controller
                name="email"
                control={control}
                // rules={{ required: "Email is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    type={"email"}
                    className="custom-width-sp mb-3"
                  />
                )}
              />
            </div>

            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="mobile"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Mobile<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <Controller
                name="mobile"
                control={control}
                // rules={{ required: "Mobile is required." }}
                render={({ field, fieldState }) => (
                  <InputMask
                    id={field.mobile}
                    mask="99999999999"
                    {...field}
                    autoFocus
                    required
                    className="custom-width-sp mb-3"
                  />
                )}
              />
              <div className="invalid-feedback">{errors.mobile?.message}</div>
            </div>

            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="password"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Password<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <Controller
                name="password"
                control={control}
                rules={{ required: "Email is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    className={`form-control custom-width-sp mb-3 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    {...register("password")}
                    type="password"
                  />
                )}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>

            <div className="form-align-justify">
              <div className="conLabels">
                <label
                  htmlFor="cPwd"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Confirm Password<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <Controller
                name="cPwd"
                control={control}
                rules={{ required: "Email is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    className={`form-control custom-width-sp mb-3 ${
                      errors.cPwd ? "is-invalid" : ""
                    }`}
                    {...register("cPwd")}
                    type="password"
                  />
                )}
              />
              <div className="invalid-feedback">{errors.cPwd?.message}</div>
            </div>

            <Button
              label="Submit"
              icon="pi pi-check"
              type="submit"
              // onClick={() => onHideDialog()}
              autoFocus
            />
          </form>
        </div>
        </Spin>
      </Dialog>
      
    </React.Fragment>
  );
};
export default Register;
