import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";
import { getEstimatedDonation } from "../../../apis/CycleApi";
import { allDonationsByCyleId, getAmountofCurrentCycle } from "../../../apis/DonationApi";
import "./estimatedDonationsChart.scss";
const EstimatedDonationsChart = () => {
  const [estimatedDonation, setEstimatedDonation] = useState();
  const [estimatedAmount, setEstimatedAmount] = useState();
  const [currentCycleDate, setCurrentCycleDate] = useState();
  const [currentCycleAmount, setCurrentCycleAmount] = useState();
  const [loading, setLoading] = useState(true);

  const currentCycleData = () => {
    getAmountofCurrentCycle().then((res) => {
      if (res.status === 200) {
        setCurrentCycleAmount(res.data);
        // setLoading(false);
      }
    });
    allDonationsByCyleId().then((res) => {
      if (res.status === 200) {
        var sDate = res.data[0];
        setCurrentCycleDate(sDate?.cycleid?.startDate);
        // setLoading(false);
      }
    });
  };

  const getEstimatedDonationData = () => {
    var apiValue = estimatedAmount;
    var perWeekValue = apiValue / 8;
    var test = 0;
    var arr = [];
    for (var i = 1; i <= 9; i++) {
      arr.push(test);
      test = test + perWeekValue;
    }
    var weekNo = currentweekno();
    var arr1 = [];
    var currentAmount = currentCycleAmount / weekNo;
    
    arr1=currentCycleAmount
    if (arr1 || arr) {
      setEstimatedDonation({
        labels: [
          "Start of first week",
          "Week1",
          "Week2",
          "Week3",
          "Week4",
          "Week5",
          "Week6",
          "Week7",
          "Week8",
        ],
        datasets: [
          {
            label: "Estimated Donation",
            data: arr,
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
          {
            label: "Current Cycle Donation",
            data: arr1,
            fill: false,
            borderColor: "#FFA726",
            tension: 0.4,
          },
        ],
      });
    }
  };

  const currentweekno = () => {
    new Date().getFullYear();
    new Date().getDay();
    var startDate = new Date(currentCycleDate);
    var currentDate = new Date();
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);
    return weekNumber;
  };

  const EstAmount = () => {
    getEstimatedDonation().then((res) => {
      if (res.status === 200) {
        setEstimatedAmount(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    EstAmount();
    // getEstimatedDonationData();
    currentCycleData();
    // currentweekno();
  }, []);

  useEffect(() => {
    // EstAmount();
    if (estimatedAmount || currentCycleAmount || currentCycleDate) {
      getEstimatedDonationData();
    }
  }, [estimatedAmount, currentCycleAmount, currentCycleDate]);

  // useEffect(() => {
  //   EstAmount();
  //   getEstimatedDonationData();
  // }, [estimatedAmount]);

  // useEffect(() =>{
  //   currentCycleData();
  //    currentweekno();
  //  },[])

  // useEffect(() =>{
  //  currentCycleData();
  //   currentweekno();
  // },[currentCycleAmount])

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div className="layout-main widther">
      <div className="card">
        {/* <span className="block text-500 font-medium mb-1">
          Estimated Donations
        </span> */}
        <Chart
          type="line"
          data={estimatedDonation}
          options={basicOptions}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default EstimatedDonationsChart;
