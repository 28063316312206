import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allLecturer = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/lecturer", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addLecturer = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/lecturer", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteLecturer = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/lecturer/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const uploadImageLecturer = (lecturerId, file) => {
  return axios.post(
    APP_CONTEXT_URL + `/v1/uploadimagelecturer/${lecturerId}`,
    file,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const getLecturerCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/lectureCount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllLecture = (field, order, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/getAllLecture?page=" + current + "&size=" + pageSize,
    { field, order },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const lecturerSearchCount = (text) => {
  return axios.get(APP_CONTEXT_URL + "/v1/lecturerSearchCount/" + text, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const lecturerListName = (names) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/lecturerListName/" , names, {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const lecturerSearch = (text, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/lecturerSearch?page=" + current + "&size=" + pageSize,
    { text },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const getLecturerFilter= (filter,current,pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/getLecturerByFilter?page=" +
    current +
    "&size=" +
    pageSize,filter ,{
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};