import React, { useState, useEffect, useRef } from "react";
import "./totalDistributors.scss";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Form } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { getAllDistributors } from "../../../apis/DistributorApi";

const TotalDistributors = () => {
  const [allDistributors, setAllDistributors] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "areaId.areaName": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.email": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.address": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const loadAllDistributors = () => {
    getAllDistributors().then((res) => {
      if (res.status === 200) {
        setAllDistributors(res.data);
      }
    });
  };

  useEffect(() => {
    loadAllDistributors();
  }, []);

  const renderHeader = () => {
    return (
      <div className="table-header tab-adjuster-totalDistributors">
        <h5 className="m-0">Distributors</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const representativeBodyTemplate = (rowData) => {
    var imgpath = "data:image/jpg;base64, " + rowData.userId?.profileImage;
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.userId?.name?.charAt(0).toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            value={allDistributors}
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilterFields={[
              "userId.name",
              "areaId.areaName",
              "userId.mobile",
              "userId.email",
              "userId.address",
              "userId.cnic",
            ]}
            emptyMessage="No distributors found."
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} distributors"
            header={header}
            className="table-sel"
          >
            <Column
              // selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="userId.name"
              header="Name"
              sortable
              filterPlaceholder="Search by userId.name"
              style={{ minWidth: "11rem" }}
              body={representativeBodyTemplate}
            />

            <Column
              field="areaId.areaName"
              header="Area Name"
              body={(record) => {
                return record.areaId.areaName;
              }}
              sortable
              filterPlaceholder="Search by areaId.areaName"
              style={{ minWidth: "11rem" }}
            />

            <Column
              field="userId.mobile"
              header="Mobile"
              body={(record) => {
                return record.userId.mobile;
              }}
              sortable
              filterPlaceholder="Search by userId.mobile"
              style={{ minWidth: "11rem" }}
            />

            <Column
              field="userId.email"
              header="Email"
              body={(record) => {
                return record.userId.email;
              }}
              sortable
              filterPlaceholder="Search by userId.email"
              style={{ minWidth: "11rem" }}
            />

            <Column
              field="userId.address"
              header="Address"
              body={(record) => {
                return record.userId.address;
              }}
              sortable
              filterPlaceholder="Search by userId.address"
              style={{ minWidth: "11rem" }}
            />

            <Column
              field="userId.cnic"
              header="CNIC"
              body={(record) => {
                return record.userId.cnic;
              }}
              sortable
              filterPlaceholder="Search by userId.cnic"
              style={{ minWidth: "11rem" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default TotalDistributors;
