import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Avatar } from "primereact/avatar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { allDonationsByCyleId } from "../../../apis/DonationApi";
import "./perCycleDonations.scss";
import { MultiSelect } from 'primereact/multiselect';
const PerCycleDonations = (props) => {
  const [donations, setDonations] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "donorId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    amount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "referenceId.username": { value: null, matchMode: FilterMatchMode.IN },
    donationType: { value: null, matchMode: FilterMatchMode.IN },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    allDonationsByCyleId().then((res) => {
      if (res.status === 200) {
        setDonations(res.data);
        setDate(res.data[0].cycleid.startDate);
        setLoading(false);
      }
    });
  }, []);

  const renderHeader = () => {
    return (
      <div className="table-header tab-adjuster-percycle-donations">
        <h5 className="m-0">
          {"Cycle Name :" + moment(date).format("MMMM, YYYY")}
        </h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  // const preDonorBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-donorId.userId.name-option">
  //           <span className="image-text"> {option.donorId.userId.name}</span>
  //       </div>
  //   );
  // };
  // const preDonorFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //         <MultiSelect 
  //          value={selectedDonor} 
  //          itemTemplate={preDonorBody}
  //         onChange={(e) => setSelectedDonor(e.value)} 
  //         options={donations} 
  //         optionLabel="donorId.userId.name" 
  //         // display="chip" 
  //         placeholder="Select Name" 
  //         maxSelectedLabels={1} 
  //         className="w-full md:w-14rem" />
  //     </div>
  // ); 
    
  // };
  // const preAmountBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-amount-option">
  //           <span className="image-text"> {option.amount}</span>
  //       </div>
  //   );
  // };
  // const preAmountFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //      value={selectedAmount} 
  //      itemTemplate={preAmountBody}
  //     onChange={(e) => setSelectedAmount(e.value)} 
  //     options={donations} 
  //     optionLabel="amount" 
  //     // display="chip" 
  //     placeholder="Select Any" 
  //     maxSelectedLabels={1} 
  //     className="w-full md:w-14rem" />
  // </div>
  //   );
 
  // };
  // const preDonationBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-donationType-option">
  //           <span className="image-text"> {option.donationType}</span>
  //       </div>
  //   );
  // };
  // const preDonationFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //      value={selectedDonation} 
  //      itemTemplate={preDonationBody}
  //     onChange={(e) => setSelectedDonation(e.value)} 
  //     options={donations} 
  //     optionLabel="donationType" 
  //     // display="chip" 
  //     placeholder="Select Any" 
  //     maxSelectedLabels={1} 
  //     className="w-full md:w-14rem" />
  // </div>
      
  //   );
  //   // <MultiSelect  
  //   // options={donations} 
  //   // donors={preDonationBody} 
  //   // // onChange={(e) => options.filterApplyCallback(e.value)} 
  //   // optionLabel="donationType" 
  //   //  placeholder="Any" 
  //   //  className="p-column-filter" 
  //   // maxSelectedLabels={1} 
  //   // />
  // };

  const representativeBodyTemplate = (rowData) => {
    var imgpath =
      "data:image/jpg;base64, " + rowData.donorId.userId?.profileImage;
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.donorId.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.donorId.userId?.name?.charAt(0).toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.donorId.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            value={donations}
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilterFields={[
              "donorId.userId.name",
              "amount",
              "referenceId.username",
              "donationType",
            ]}
            emptyMessage="No donations found."
            loading={loading}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} donations"
            header={header}
            className="table-sel"
          >
            <Column
              // selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="donorId.userId.name"
              header="Donor"
              body={representativeBodyTemplate}
              sortable
              filterPlaceholder="Search by donorId.userId.name"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={preDonorFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />

            <Column
              field="amount"
              header="Amount"
              body={(record) => {
                return record.amount;
              }}
              sortable
              dataType="numeric"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={preAmountFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />
            <Column
              field="donationType"
              header="Donation Type"
              body={(record) => {
                return record.donationType;
              }}
              sortable
              filterPlaceholder="Search by donationType"
              // style={{ minWidth: "14rem" }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '14rem' }}
              // filter
              // filterElement={preDonationFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />
            <Column
              field="creationDate"
              header="Creation Date"
              body={(record) => {
                return moment(record.creationDate).format("yyyy-MM-DD");
              }}
              sortable
              dataType="numeric"
              style={{ minWidth: "14rem" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PerCycleDonations;
