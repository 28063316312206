import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const addProgramAttendees = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/programAttendees", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAttendeesByProgram = (programId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/programAttendees/" + programId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const  getAttendeesById = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/Attendees/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};


export const deleteProgramAttendee = (programId, deservantId) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/programattendees/" + programId + "/" + deservantId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const deservantsAttendence = (programId, values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateattendence/" + programId, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};


export const allProgramAttendesInfo = () => {
  return axios.post(APP_CONTEXT_URL + "/v1/allProgramAttendesInfo", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getReferredByFilter= (filter,current,pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/getattendeesByFilter?page=" +
    current +
    "&size=" +
    pageSize,filter ,{
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uniqueReferrers = (programId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/attendees/uniqueReferrers/"+programId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};