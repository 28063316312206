import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { allLecturer } from "../../../apis/LecturerApi";
import { addProgram } from "../../../apis/TarbiyahProgramApi";
import "./tarbiyahClassForm.scss";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { getAllProgramLocations } from "../../../apis/programLocationApi";

const TarbiyahProgramForm = (props) => {
  const toast = useRef(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [lecturer, setLecturer] = useState([]);
  const [location, setLocation] = useState([]);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const loadAllLecturer = () => {
    allLecturer().then((res) => {
      if (res.status === 200) {
        setLecturer(res.data.reverse());
      }
    });
  };

  const loadLocations = () => {
    getAllProgramLocations().then((res) => {
      if (res.status === 200) {
        setLocation(res.data);
      }
    });
  };

  useEffect(() => {
    reset();
    loadAllLecturer();
    loadLocations();
  }, [reset]);

  useEffect(() => {
    loadAllLecturer();
    loadLocations(); 
    if (props?.editorValues?.date) {
      var date = new Date(
        moment(props?.editorValues?.date).format("YYYY-MM-DD HH:mm:ss")
      );
      setValue("date", date);
      setValue("lecturers", props?.editorValues.lecturers);
      setValue("classTopic", props?.editorValues.classTopic);
      setValue("location", props?.editorValues?.location);
    }
    else {
      reset();
    }
  }, [props?.editorValues]);

  const onSubmit = async (data) => {
    var dataForApi;
    if (props?.editorValues?.id) {
      dataForApi = {
        id: props?.editorValues.id,
        date: new Date(moment(data["date"]).format("YYYY-MM-DD HH:mm:ss")),
        lecturers: data.lecturers,
        classTopic: data.classTopic,
        location: data.location,
      };
    } else {
      dataForApi = {
        date: new Date(moment(data["date"]).format("YYYY-MM-DD HH:mm:ss")),
        lecturers: data.lecturers,
        classTopic: data.classTopic,
        location: data.location,
      };
    }
    addProgram(dataForApi).then((res) => {
      if (res.status === 200) {
        props.updatePrograms();
        reset();
        props.hide();
      }
    });
  };
  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <div className="card width-adjuster-for-add-deservant-form">
          <h5 className="text-center font-for-register">New Tarbiyah Class</h5>
          <div>
            <Toast ref={toast} position="top-center" />
            <div className="card"></div>
          </div>
          <form
            className="p-fluid form-selector"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="p-fluid">
              <div className="field grid" style={{ marginTop: "30px" }}>
                <label
                  htmlFor="date"
                  className="col-12 mb-2 md:col-3 md:mb-0 margin-adjuster-form"
                >
                  Date
                  <span className="color-for-str">*</span>
                </label>
                <div className="col-10 md:col-8">
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "date is required." }}
                    render={({ field: { ref, ...rest } }) => (
                      <Calendar
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        disablePast={true}
                        showIcon
                        showTime
                        KeyboardButtonProps={{
                          "aria-label": "change end date",
                        }}
                        {...rest}
                      />
                    )}
                  />
                  {getFormErrorMessage("date")}
                </div>
              </div>

              <div className="field grid" style={{ marginTop: "5px" }}>
                <label
                  htmlFor="lecturers"
                  className="col-12 mb-2 md:col-3 md:mb-0"
                >
                  Lecturer
                  <span className="color-for-str">*</span>
                </label>
                <div className="col-10 md:col-8">
                <Controller
                    name="lecturers"
                    control={control}
                    rules={{ required: "Lecturer is required." }}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                      value={field.value}
                      className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        // required={(true)}
                        display="chip"
                        options={lecturer}
                        optionLabel={(option) => option.userId.name}
                        onChange={(e) => field.onChange(e.value)}    
                        placeholder="Select Lecturer Name"
                        id={field.name} 
                        autoFocus
                      />
                    )}
                  /> 
                  {getFormErrorMessage("lecturers")}
                </div>
              </div>

              <div className="field grid" style={{ marginTop: "40px" }}>
                <label
                  htmlFor="classTopic"
                  className="col-12 mb-2 md:col-3 md:mb-0"
                >
                  classTopic
                  <span className="color-for-str">*</span>
                </label>
                <div className="col-10 md:col-8">
                  <Controller
                    name="classTopic"
                    control={control}
                    rules={{ required: "Class Topic is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        className={classNames({
                          "p-invalid": fieldState.error,
                        })}
                        id={field.title}
                        {...field}
                      />
                    )}
                  />
                  {getFormErrorMessage("classTopic")}
                </div>
              </div>

              <div className="field grid" style={{ marginTop: "30px" }}>
                <label
                  htmlFor="location"
                  className="col-12 mb-2 md:col-3 md:mb-0"
                >
                  Location
                  <span className="color-for-str">*</span>
                </label>
                <div className="col-10 md:col-8">
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "Location is required." }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                      // required={(true)}
                        value={field.value}
                        optionLabel="address"
                        placeholder="Select a Location"
                        name="address"
                        options={location}
                        control={control}
                        onChange={(e) => field.onChange(e.value)}
                        className={classNames({
                          "p-invalid": fieldState.error,
                        })}
                      />
                    )}
                  />
                  {getFormErrorMessage("location")}
                </div>
              </div>
            </div>
            <span>
              <Button type="submit" label="Submit" className="submit-btn" />
            </span>
            <Button
              type="button"
              label="Cancel"
              className="p-button-danger cancel-btn"
              onClick={props?.hide}
            />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TarbiyahProgramForm;
