import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { deservantsAttendence } from "../../../apis/ProgramAttendeesApis";
import "./deservantAttendence.scss";
function DeservantAttendence(props) {
  const [selectedDeservants, setSelectedDeservants] = useState([]);
  const toast = useRef(null);
  const [time, setTime] = useState(null);
  // const {
  //   control,
  //   formState: { errors },
  //   handleSubmit,
  //   reset,
  //   setValue,
  // } = useForm();
  const leftContents = (
    // <React.Fragment>
    <div style={{ width: "100%", border: "1px solid red" }}>
      <h5 className="text_center">Deservant's Attendence</h5>
    </div>
    // </React.Fragment>
  );
  const submitData = () => {
    var deservantsIds = [];
    selectedDeservants.forEach((deservant) => {
      deservantsIds.push(deservant.id);
      // setSelectedDeservants()
    });
    deservantsAttendence(props.programDetail.program.id, deservantsIds).then(
      (response) => {
        if (response.status === 200) {
          props.updateData();
          props.hide();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Program Location Updated Successfully",
            life: 5000,
          });
        }
      }
    );
  };

  useEffect(() => {
    let attendedDeservants = [];
    props.deservants.forEach((deservant) => {
      if (deservant.attended === true) attendedDeservants.push(deservant);
    });
    setSelectedDeservants(attendedDeservants);
  }, [props.deservants]);

  const updateTime = (time, options) => {
    setTime(time);
    setSelectedDeservants(
      selectedDeservants.map((record) => {
        if (record.id === options.id) {
          return {
            myTime: moment(time).format("hh:mm:ss"),
            ...record,
          };
        }
        return record;
      })
    );
  };
  return (
    <React.Fragment>
      <div style={{ textAlign: "center" }}>
        {/* <Toolbar style={{ border: "hidden"}} left={leftContents} /> */}
        <h5>Deservant's Attendence</h5>
      </div>
      <Toast ref={toast} position="top-center" />

      <div className="layout-main ">
        <div className="datatable-doc-demo">
          <div className="card ">
            <DataTable
              value={props.deservants}
              selection={selectedDeservants}
              onSelectionChange={(e) => {
                setSelectedDeservants(e.value);
              }}
              scrollable
              filterDisplay="menu"
              responsiveLayout="scroll"
              globalFilterFields={["deservant.userId.name"]}
              emptyMessage="No Attendees found."
              className="table-sel mytab"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
              <Column
                key="id"
                field="deservant.userId.name"
                header="Deservants"
                body={(record) => {
                  return record.deservant.userId.name;
                }}
                sortable
                filterPlaceholder="deservant.userId.name"
                // style={{ minWidth: "13rem" }}
              />

              <Column
                key="time"
                field="time"
                header="time"
                // editor={(options) =>
                // <Calendar
                //   timeOnly
                //     dateFormat="dd/mm/yy"
                //     value={time}
                //     onChange={(e) => setTime(e.value)}
                //   />}
                body={(options) => (
                  <Calendar
                    id={options.id}
                    name={options.id}
                    timeOnly
                    showTime
                    dateFormat="hh:mm:ss"
                    value={options?.myTime ? options?.myTime : null}
                    onChange={(e) => {
                      updateTime(e.value, options);
                    }}
                  />
                )}
              />
            </DataTable>

            <Button
              label="Submit"
              className="submit-btn"
              style={{ marginRight: "-1px", marginTop: "5px" }}
              onClick={submitData}
            />
            <Button
              type="cancel"
              label="Cancel"
              className="p-button-danger cancel-btn"
              style={{ marginTop: "5px" }}
              onClick={props.hide}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DeservantAttendence;
