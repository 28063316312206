import { Form, Input } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
// import { ProductService } from '../service/ProductService';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Controller, useForm } from "react-hook-form";
import { addArea, deleteArea, getAllAreas, areaLocation } from "../../../apis/AreaApi";
import "./Area.scss";
import { getAllLocation } from "../../../apis/LocationApi";
// import { useForm } from "react-hook-form";
const Area = (props) => {
  const moment = require("moment");
  const [areaData, setAreaData] = useState([]);
  const [areaTable, setAreaTable] = useState([]);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [productDialog, setProductDialog] = useState(false);
  const [locationTable, setLocationTable] = useState([]);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [areaValuesForEdit, setAreaValuesForEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [date, setDate] = useState(null);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [deleteAreas, setDeleteAreas] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // const [selectedArea, setSelectedArea] = useState (null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    areaName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    creationDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const loadAreaData = () => {
    getAllAreas().then((res) => {
      if (res.status === 200) {
        setAreaTable(res.data)
        setAreaData(res.data.reverse());
        setAreaTable(res.data)
        setLoading(false);
      }
    });
  };

  const loadLocationData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data)
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    loadAreaData();
    loadLocationData();
  }, []);
  const confirmDeleteSelected = (id) => {
    setDeleteProductsDialog(true);
    setDeleteAreas(id);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const saveProduct = () => {
    alert("saved");
  };
  const hideDialog = () => {
    form.resetFields();
    setProductDialog(false);
    reset();
    setsidebarVisible(false);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
    setDeleteAreas("");
  };
  // const onFinish = () => {
  //   form.validateFields().then((values) => {
  //     let newValues = {
  //       id: form.getFieldValue("id") ? form.getFieldValue("id") : null,
  //       ...values,
  //     };
  //     addArea(newValues).then((res) => {
  //       if (res.status === 200) {
  //         hideDialog();
  //         loadAreaData();
  //       }
  //     });
  //     toast.current.show({
  //       severity: "success",
  //       summary: "Successful",
  //       detail: "Area Added Successfully",
  //       life: 3000,
  //     });
  //   });
  //   return false;
  // };

  const setValuesInForm = (field) => {
    setAreaValuesForEdit(field);
    setValue("areaName", field.areaName);
    setValue("Location", field.location);
  };


  const onSubmit = async (data) => {
    if (isEdit) {
      let dataForApi = {
        id: areaValuesForEdit ? areaValuesForEdit.id : null,
        areaName: data.areaName,
        location: {
          id: data.location.id,
          locationName: data.location.location
        }
      };
      
      addArea(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          setAreaValuesForEdit(true);
          setsidebarVisible(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Area Updated Successfully",
            life: 3000,
          });
        }
      });
    } else {
      let dataForApi = {
        areaName: data.areaName,
        location: {
          id: data.location.id,
          locationName: data.location.location
        },
      };
      addArea(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          loadAreaData();
          loadLocationData();
        }
      });
    }
  };

  const openNew = () => {
    setId(null);
    setProductDialog(true);
    reset();
    setsidebarVisible(true);
  };
  const deleteSelectedArea = () => {
    deleteArea(deleteAreas).then((response) => {
      if (response.status === 200) {
        loadAreaData();
        setDeleteProductsDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Area Deleted",
          life: 3000,
        });
      } else {
        toast.current.show({
          className: "error-shower",
          severity: "Error",
          summary: "Error",
          detail: "Delete the Area from the distributor first",
          life: 6000,
        });
        setDeleteProductsDialog(false);
      }
    });
  };
  const areaBody = (option) => {
    return (
      <div className="p-multiselect-areaName-option">
        <span className="image-text"> {option.areaName}</span>
      </div>
    );
  };

  // useEffect(() => {
  //   const ids = selectedArea?.map(obj => obj.id);
  //   if(ids?.length > 0)
  //   {
  //     areaLocation(ids).then((res) => {
  //       if (res.status === 200) {
  //         setAreaTable(res.data)
  //       }
  //       else // api call failed 
  //         setAreaTable([]);
  //     });
  //   }
  //   else 
  //   loadAreaData();
  // }, [selectedArea])
  // const areaFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //     value={selectedArea} 
  //     onChange={(e) => setSelectedArea(e.value)} 
  //     options={areaData} 
  //     optionLabel="areaName" 
  //     // display="chip" 
  //     placeholder="Select Area" 
  //     maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>

  // </div>
  //   );
  // };

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedArea}
      />
    </React.Fragment>
  );
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Areas For Distribution</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const PaginatorDemo = () => {
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);
    const onBasicPageChange = (event) => {
      setBasicFirst(event.first);
      setBasicRows(event.rows);
    };
  };
  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-crud-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            ref={dt}
            value={areaTable}
            dataKey="id"
            paginator
            rows={10}
            scrollable
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Areas"
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={["areaName", "creationDate"]}
            loading={loading}
            emptyMessage="No areas found."
            responsiveLayout="scroll"
            className="table-sel mytab"
          >

            <Column
              field="areaName"
              header="Area Name"
              style={{ minWidth: "12rem" }}
              sortable
              filterPlaceholder="Search by areaName"
            // showFilterMenu={true}
            // filterMenuStyle={{ width: '10rem' }}
            // filter
            // filterElement={areaFilter}
            // showFilterMenuOptions={false}
            // filterApply
            // filterClear
            ></Column>
            <Column
              field="location.locationName"
              header="Location Name"
              style={{ minWidth: "12rem" }}
              sortable
              filterPlaceholder="Search by locationName"
            ></Column>
            <Column
              field="creationDate"
              header="Creation Date"
              body={(record) => {
                return moment(record.creationDate).format("yyyy-MM-DD");
              }}
              style={{ minWidth: "16rem" }}
              sortable
              filterPlaceholder="Search by creationDate"
            ></Column>

            <Column
              header="Action"
              fixed="Right"
              frozen
              alignFrozen=""
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined"
                    onClick={() => {
                      setsidebarVisible(true);
                      setValuesInForm(field);
                      setIsEdit(true)
                    }}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded red-color-btn p-button-spacing"
                    onClick={(e) => confirmDeleteSelected(field.id)}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
        <Sidebar
          visible={sidebarVisible}
          position="right"
          style={{ width: "60em" }}
          onHide={() => {
            setsidebarVisible(false);
            setAreaValuesForEdit(null);
            setIsEdit(false);
          }}
        >
          <div className="flex justify-content-center">
            <div className="card width-card width-adjuster-for-add-deservant-form">
              <h5 className="text-center font-for-register">Area Name</h5>
              <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>

                <div className="field grid" style={{ marginTop: "5px" }}>
                  <label
                    htmlFor="areaName"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    <b> Area Name </b> <span className="color-for-str">*</span>
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="areaName"
                      control={control}
                      rules={{ required: "Area Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("areaName")}
                  </div>
                </div>

                <div className="field grid" style={{ marginTop: "30px" }}>
                  <label
                    htmlFor="location"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    <b> Location </b> <span className="color-for-str">*</span>
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="location"
                      control={control}
                      rules={{ required: "Location is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          autoFocus
                          options={locationTable}
                          optionLabel={(option) => {
                            return option.locationName;
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <Button type="submit" label="Submit" className="submit-btn" />
                <Button
                  type="cancel"
                  label="Cancel"
                  className="p-button-danger cancel-btn"
                  onClick={hideDialog}
                />
              </form>
            </div>
          </div>

        </Sidebar>
        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {<span>Are you sure you want to delete the selected Area?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default Area;
