import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import AddDeservants from "./addDeservants";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import {
  deleteProgramAttendee,
  getAttendeesByProgram,
  getReferredByFilter,
  uniqueReferrers,
} from "../../../apis/ProgramAttendeesApis";
import { Dialog } from "primereact/dialog";
import DeservantAttendence from "./deservantAttendence";
import { MultiSelect } from "primereact/multiselect";
import { filter } from "lodash";

const DeservantData = (props) => {
  const toast = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState();
  const [attendenceSidebarVisible, setAttendenceSidebarVisible] =
    useState(false);
  const [attendees, setAttendees] = useState([]);
  const [deleteAttendees, setDeleteAttendees] = useState({});
  const [deleteAttendeesDialog, setDeleteAttendeesDialog] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [referrers, setReferrers] = useState([]);
  const [selectedReffred, setSelectedReffred] = useState(null);
  const [searchText, setSearchText] = useState("");
  const ref = useRef();
  const [totalDeservent, setTotalDeservent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [basicFirst, setBasicFirst] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [field, setField] = useState("id");
  const [order, setOrder] = useState("asc");
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: "deservant.userId.id", order: -1 },
  ]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "deservant.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservant.referredBy.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservant.referredBy.areaId.areaName": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    attended: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    setFilters(e.target.value);
    setGlobalFilterValue(e.target.value);
    if (ref.current) clearTimeout(ref.current);
    if (e.target.value !== "") {
      ref.current = setTimeout(() => {
        setSearchText(e?.target.value);
      }, 1000);
    } else {
      setSearchText("");
      searchReferrers();
    }
  };
  const searchReferrers = () => {
    setLoading(true);
    uniqueReferrers(props.program.id).then((res) => {
      if (res.status === 200) {
        setReferrers(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    searchReferrers();
  }, []);
  const currentProgramDeservant = () => {
    getAttendeesByProgram(props.program.id).then((response) => {
      if (response.status === 200) {
        setAttendees(response.data);
        setLoading(false);
      }
    });
  };
  const onBasicPageChange = (event) => {
    setLoading(true);
    setBasicFirst(event.first);
    setCurrentPage(event.page);
    setPageSize(event.rows);
    setLoading(false);
  };
  const filterReferred = (filter) => {
    getReferredByFilter(filter, currentPage, pageSize).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setAttendees(response.data.attendees);
        setTotalDeservent(response.data.count);
      } else {
        // show error
      }
    });
  };

  useEffect(() => {
    var filter = {};
    setLoading(true);
    if (selectedReffred?.length > 0) {
      filter["referredBy"] = selectedReffred.map((referrer) => referrer.name);
    }
    if (searchText) {
      filter["search"] = searchText;
    }
    filter["programid"] = props.program.id;
    filterReferred(filter);
  }, [currentPage, pageSize, field, order, selectedReffred, searchText]);
  const hideDeleteAttendeeDialog = () => {
    setDeleteAttendeesDialog(false);
    setDeleteAttendees("");
  };

  const deleteSelectedAttendees = () => {
    deleteProgramAttendee(
      deleteAttendees.programId,
      deleteAttendees.deservantId
    ).then((response) => {
      if (response.status === 200) {
        currentProgramDeservant(deleteAttendees.programId);
        setDeleteAttendeesDialog(false);

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Attendee Deleted",
          life: 3000,
        });
      }
      setDeleteAttendeesDialog(false);
    });
  };
  const representativeMultiSelectBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-0">
          <div>
            <Avatar
              label={rowData?.name?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          </div>
          <div>
            <p>{rowData?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const reffredBody = (option) => {
    return (
      <div className="p-multiselect-deservant.referredBy.userId.name-option">
        <span className="image-text">{option.deservant.referredBy.name}</span>
      </div>
    );
  };
  const reffredByFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setSelectedReffred(e?.value);
          options.filterCallback(e?.value);
        }}
        options={referrers}
        optionLabel="name"
        itemTemplate={representativeMultiSelectBodyTemplate}
        // display="chip"
        placeholder="Select Referred By"
        maxSelectedLabels={1}
        // className="w-full md:w-12rem"
        className="p-column-filter"
        style={{ minWidth: "19.5rem" }}
      />
    );
  };
  const confirmDeleteSelected = (record) => {
    var attendeeData = {
      programId: record.tarbiyahProgram.id,
      deservantId: record.deservant.id,
    };
    setDeleteAttendees(attendeeData);

    setDeleteAttendeesDialog(true);
  };

  const deleteAttendeeDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteAttendeeDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedAttendees}
      />
    </React.Fragment>
  );

  useEffect(() => {
    currentProgramDeservant(props.id);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          className="p-button-sm"
          onClick={(e) => {
            e.stopPropagation();
            setSidebarVisible(true);
          }}
        />
        <Button
          label="Attendence"
          // icon="pi pi-plus"
          className="p-button-sm"
          style={{ marginInline: "1rem" }}
          onClick={(e) => {
            e.stopPropagation();
            setAttendenceSidebarVisible(true);
          }}
        />
      </div>
      <Toast ref={toast} position="top-center" />
      <div className="card">
        <DataTable
          value={attendees}
          filterDisplay="menu"
          responsiveLayout="scroll"
          globalFilterFields={[
            "deservant.userId.name",
            "deservant.referredBy.userId.name",
            "deservant.referredBy.areaId.areaName",
            "attended",
          ]}
          emptyMessage="No Attendees found."
          dataKey="id"
          className="table-sel mytab"
          multiSortMeta={multiSortMeta}
          onSort={(e) => {
            e?.multiSortMeta[0]?.order === 1
              ? setOrder("asc")
              : setOrder("desc");
            setMultiSortMeta(e.multiSortMeta);
            setField(e?.multiSortMeta[0]?.field);
          }}
        >
          <Column
            key="id"
            field="deservant.userId.name"
            header="Deservants"
            body={(record) => {
              return record.deservant.userId.name;
            }}
            sortable
          />

          <Column
            field="deservant.userId.cnic"
            header="CNIC"
            body={(record) => {
              return record.deservant.userId.cnic;
            }}
            filterPlaceholder="Search by deservant.userId.cnic"
            style={{ minWidth: "13rem" }}
          />

          <Column
            field="deservant.referredBy.name"
            header="ReferredBy"
            body={reffredBody}
            sortable
            filterPlaceholder="Search by deservant.referredBy.userId.name"
            style={{ minWidth: "14rem" }}
            showFilterMenu={true}
            // filterMenuStyle={{ width: '13rem' }}
            filter
            filterElement={reffredByFilter}
            showFilterMenuOptions={false}
            filterApply
            filterClear
          />
          <Column
            field="deservant.referredBy.location.locationName"
            header="Area"
            body={(record) => {
              return record.deservant.referredBy.location.locationName;
            }}
            sortable
          />

          <Column
            field="invited"
            header="Invited"
            body={(record) => {
              if (record.invited === true) return "Yes";
              else return "No";
            }}
            filterPlaceholder="Search by invited"
            style={{ minWidth: "13rem" }}
          />

          <Column
            field="attended"
            header="Attended"
            body={(record) => {
              if (record.attended === true) return "Yes";
              else return "No";
            }}
            filterPlaceholder="Search by attended"
            style={{ minWidth: "13rem" }}
          />

          <Column
            field="deservant.userId.mobile"
            header="Mobile"
            body={(record) => {
              return record.deservant.userId.mobile;
            }}
            filterPlaceholder="Search by deservant.userId.mobile"
            style={{ minWidth: "13rem" }}
          />
          <Column
            header="Action"
            fixed="Right"
            frozen
            alignFrozen="right"
            field={(field) => (
              <div>
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded delete-button"
                  onClick={() => {
                    confirmDeleteSelected(field);
                  }}
                />
              </div>
            )}
          ></Column>
        </DataTable>
        <Paginator
          first={basicFirst}
          rows={pageSize}
          totalRecords={totalDeservent}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={onBasicPageChange}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Attendees"
        ></Paginator>
      </div>
      <Sidebar
        visible={sidebarVisible}
        position="right"
        style={{ width: "65em" }}
        onHide={() => {
          setSidebarVisible(false);
        }}
      >
        <AddDeservants
          programId={props.program.id}
          hide={() => {
            setSidebarVisible(false);
          }}
          currentProgramDeservant={() =>
            currentProgramDeservant(props.program.id)
          }
        />
      </Sidebar>
      <Sidebar
        visible={attendenceSidebarVisible}
        position="right"
        style={{ width: "40em" }}
        onHide={() => {
          setAttendenceSidebarVisible(false);
        }}
      >
        <DeservantAttendence
          programDetail={props}
          deservants={attendees}
          hide={() => {
            setAttendenceSidebarVisible(false);
          }}
          updateData={currentProgramDeservant}
        />
      </Sidebar>
      <Dialog
        visible={deleteAttendeesDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteAttendeeDialogFooter}
        onHide={hideDeleteAttendeeDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete this Record?</span>}
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default DeservantData;
