import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allPrograms = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/tarbiyahProgram", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const addProgram = (values) => {
    return axios.post(APP_CONTEXT_URL + "/v1/tarbiyahProgram", values, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const deletePrograms = (id) => {
    return axios.delete(APP_CONTEXT_URL + "/v1/tarbiyahProgram/" + id, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
}; 