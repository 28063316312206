import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allCycles = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/cycle", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const addCycle = (values) => {
    return axios.post(APP_CONTEXT_URL + "/v1/cycle", values, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const updateCycle = (values) => {
    return axios.post(APP_CONTEXT_URL + "/v1/cycle", values, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const countOfCycles = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/countcycles", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        }
    },
    )
}

export const deleteCycle = (id) => {
    return axios.delete(APP_CONTEXT_URL + "/v1/cycle/" + id, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};
export const expenseDetail = (id) => {
    return axios.get(APP_CONTEXT_URL + "/v1/cycleExpenseDetail/" + id, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const getLatestCycle = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/getLatestCycle/", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const allCycleDates = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/allCycleDates/", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const perCycleData = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/cycleDonations/", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};

export const getEstimatedDonation = () => {
    return axios.get(APP_CONTEXT_URL + "/v1/getestimateddonation/", {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
    });
};


export const finalReport = (id) => {
    return axios.get(APP_CONTEXT_URL + "/v1/finalreport/" + id, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
        },
        responseType: "blob"
    });
};
