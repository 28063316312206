import { Form } from "antd";
import moment from "moment";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import { Form } from "antd"; 
import {
  getAllProgramLocations,
  addProgramLocation,
  updateProgramLocation,
  deleteProgramLocation,
} from "../../../apis/programLocationApi";

const NewLocation = (props) => {
  const toast = useRef(null);
  const toastBC = useRef(null);
  const [locationEdit, setlocationEdit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const onEditFunction = () => {
    if (props?.editorValues) {
      setValue("title", props?.editorValues.title);
      setValue("address", props?.editorValues.address);
      setValue("contactPerson", props?.editorValues.contactPerson);
      setValue("contactNo", props?.editorValues.contactNo);
        } 
        else {
          reset();
        }
      } 

      useEffect(() => {
        fetchLocation();
         onEditFunction();
        if (props?.editorValues) {
            setIsEdit(true);
    } else {
      reset();
    }
  } ,[]);
  
  useEffect(() => {
    fetchLocation();
    onEditFunction();
    if (props?.editorValues) {
      setIsEdit(true);
    } else {
      reset();
    }
  }, [props?.editorValues]);
  const fetchLocation = () => {
    getAllProgramLocations().then((res) => {
      if (res.status === 200) {
        setlocationEdit(res.data);
      }
    });
  };


  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [form] = Form.useForm();
  const OnSubmit = (data) => {
    if (isEdit) {
      let dataForApi = {
        id: props?.editorValues.id ? props?.editorValues.id : null,
        // .program_location: {id:data.program_location.id},
        title: data.title,
        address: data.address,
        contactPerson: data.contactPerson,
        contactNo: data.contactNo,
        creationDate: new Date(
          moment(data["creationDate"]).format("YYYY/MM/DD")
        ),
        
      };
      addProgramLocation(dataForApi).then((res) => {
        if (res.status === 200) {
          props.updatePrograms();
            reset();
            props.hide();
          setIsEdit(false);
        }
      });
    } else {
      let dataForApi = {
        id: props?.locationEdit.id ? props?.locationEdit.id : null,
        id: null,
        title: data.title,
        address: data.address,
        contactPerson: data.contactPerson,
        contactNo: data.contactNo,
      };
      addProgramLocation(dataForApi).then((res) => {
        if (res.status === 200) {
            reset();
          props.hide();
          props.updatePrograms();
        }
      });
    }
  };
  return (
    <div className="flex justify-content-center">
      <div className="card width-adjuster-for-add-deservant-form">
        <h5 className="text-center font-for-register">Program Location</h5>
        <form className="p-fluid" onSubmit={handleSubmit(OnSubmit)}>
          <div className="p-fluid">
            <div className="field grid" style={{ marginTop: "40px" }}>
              <label
                htmlFor="title"
                className="col-12 mb-2 md:col-3 md:mb-0"
              >
                <b>Title</b>
                <span className="color-for-str">*</span>
              </label>
              <div className="col-10 md:col-8">
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Title is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.title}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("title")}
              </div>
            </div>
            <div className="field grid" style={{ marginTop: "5px" }}>
              <label htmlFor="address" className="col-12 mb-2 md:col-3 md:mb-0">
                <b>Address</b>
                <span className="color-for-str">*</span>
              </label>
              <div className="col-10 md:col-8">
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: "Address is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.address}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("address")}
              </div>
            </div>
            <div className="field grid" style={{ marginTop: "5px" }}>
              <label
                htmlFor="contactPerson"
                className="col-12 mb-2 md:col-3 md:mb-0"
              >
                <b>Person Name</b>
                <span className="color-for-str">*</span>
              </label>
              <div className="col-10 md:col-8">
                <Controller
                  name="contactPerson"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.contactPerson}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("contactPerson")}
              </div>
            </div>
            <div className="field grid" style={{ marginTop: "5px" }}>
              <label
                htmlFor="contactNo"
                className="col-12 mb-2 md:col-3 md:mb-0"
              >
                <b>Contact Number</b>
                <span className="color-for-str">*</span>
              </label>
              <div className="col-10 md:col-8">
                <Controller
                  name="contactNo"
                  control={control}
                  rules={{ required: "Number is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      id={field.contactNo}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("contactNo")}
              </div>
            </div>
          </div>
          <Button type="submit" label="Submit" className="submit-btn" />
        </form>
        <Button
          type="cancel"
          label="Cancel"
          className="p-button-danger cancel-btn"
          onClick={props.hide}
        />
      </div>
    </div>
  );
};
export default NewLocation;
