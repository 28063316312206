import React, { useState, useEffect, useRef } from "react";
import "./allCycles.scss";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Form } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";

import { allCycles } from "../../../apis/CycleApi";

const AllCycles = () => {
  const history = useHistory();

  const [allCyclesData, setAllCycles] = useState([]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");


  const [filters, setFilters] = useState({
    "global": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "startDate": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "noOfItems": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "tax": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "totalDonation": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const loadAllCycles = () => {
    allCycles().then((res) => {
      if (res.status === 200) {
        setAllCycles(res.data);
      }
    });
  };

  const onRowClick = (cycleId) => {
    history.push({
      pathname: "/cycleDetails",
      state: { cycleId: cycleId },
    });
  };

  useEffect(() => {
    loadAllCycles();
  }, []);

  const renderHeader = () => {
    return (
      <div className="table-header tab-adjuster-allcycles">
        <h5 className="m-0">All Cycles</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className="layout-main">
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable
            value={allCyclesData}
            onRowClick={(e) => onRowClick(e.data.id)}
            filters={filters}
            globalFilterFields={[
              "startDate",
              "noOfItems",
              "tax",
              "totalDonation",
            ]}
            emptyMessage="No Cycles found."
            responsiveLayout="scroll"
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cycles"
            header={header}
            className="table-sel"
          >
            <Column
              // selectionMode="single"
              headerStyle={{ width: "3em" }}
            ></Column>

            <Column
              field="startDate"
              header="Start Date"
              body={(record) => {
                return (
                  "CYC-" +
                  moment(record.startDate).format("MMM-YY").toUpperCase()
                );
              }}
              sortable
              filterPlaceholder="Search by startDate"
              style={{ minWidth: "10rem" }}
            />

            <Column
              field="noOfItems"
              header="No Of Items"
              body={(record) => {
                return record.noOfItems;
              }}
              sortable
              filterPlaceholder="Search by noOfItems"
              style={{ minWidth: "10rem" }}
            />

            <Column
              field="tax"
              header="Tax"
              body={(record) => {
                return record.tax;
              }}
              sortable
              filterPlaceholder="Search by tax"
              style={{ minWidth: "10rem" }}
            />

            <Column
              field="totalDonation"
              header="Total Donation"
              body={(record) => {
                return record.totalDonation;
              }}
              sortable
              filterPlaceholder="Search by totalDonation"
              style={{ minWidth: "10rem" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default AllCycles;
