import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { getReciptImage } from "../../../apis/CycleImageApi";
import { allCycles, finalReport } from "../../../apis/CycleApi";
import { downloadedFile } from "../../../functions/functions";
import "./cycle.scss";
import CycleForm from "./cycleForm";
import { useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Spin } from "antd";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { getAllLocation } from "../../../apis/LocationApi";
import { wrap } from "lodash";
function Cycle() {
  const history = useHistory();
  const toast = useRef(null);
  const dt = useRef(null);
  const moment = require("moment");
  const [cycleId, setCycleId] = useState(null);
  const [cycleData, setCycleData] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [dialogVisible, setdialogVisible] = useState(false);
  const [headerValue, setheaderValue] = useState({});
  const [imageValue, setimageValue] = useState({});
  // const [locationTable,setLocationTable] =useState([]);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [selectedCycDate, setSelectedCycDate] = useState(null);
  const [selectedDeservant, setSelectedDeservant] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedTax, setSelectedTax] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // startDate: {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    // },
    cycleName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    location: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    noOfItems: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    totalDonation: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    totalDeservants: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    totalAmount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    tax: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });
  const addcycleImageList = (list, record) => {
    return list.map((value) => {
      return (
        <div
          onClick={(e) => {
            getReciptImageCall(record.id, value);
            e.stopPropagation();
            setheaderValue(value);
            setdialogVisible(true);
          }}
        >
          <Tag
            style={{
              fontSize: "100%",
              backgroundColor: "#00B1FF",
              // marginTop: "1px",
              marginBottom: "2px",
            }}
            className="mr-2"
            // icon="pi pi-user"
            value={value}
          ></Tag>
        </div>
      );
    });
  };

  const getReciptImageCall = (id, value) => {
    setApiLoading(true);
    getReciptImage(id, value).then((res) => {
      if (res.status === 200) {
        setimageValue(res.data?.image);
        setApiLoading(false);
      }
      setLoading(false);
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const openNew = () => {
    setCycleId("");
    setValuesForEdit(null);
    setsidebarVisible(true);
  };

  const loadCycleData = () => {
    allCycles().then((res) => {
      if (res.status === 200) {
        setCycleData(res.data.sort((a, b) => b.id - a.id));
        setLoading(false);
      }
    });
  };
  // const cycDateBody = (option) => {
  //   return (
  //     <div className="p-multiselect-startDate-option">
  //       <span className="image-text">{option.startDate}</span>
  //     </div>
  //   );
  // };
  // const cycDateFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //         <MultiSelect
  //             value={selectedCycDate}
  //             onChange={(e) => setSelectedCycDate(e.value)}
  //             options={cycleData}
  //             optionLabel="startDate"
  //             // display="chip"
  //             placeholder="Select Date"
  //             maxSelectedLabels={1}
  //             className="w-full md:w-12rem"
  //         />
  //     </div>
  //   );
  // };
  // const cycDonationBody = (option) => {
  //   return (
  //     <div className="p-multiselect-totalDonation-option">
  //       <span className="image-text">
  //         {option.totalDonation}</span>
  //     </div>
  //   );
  // };
  // const cycDonationFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //         <MultiSelect
  //         value={selectedDonation}
  //         onChange={(e) => setSelectedDonation(e.value)}
  //         options={cycleData}
  //         optionLabel="totalDonation"
  //         // display="chip"
  //         placeholder="Select Any"
  //         maxSelectedLabels={1}
  //         className="w-full md:w-12rem"/>
  //     </div>

  //   );
  // };
  // const cycDeservantBody = (option) => {
  //   return (
  //           <div className="p-multiselect-expenseDetails.totalDeservants-option">
  //           <span className="image-text">{option?.expenseDetails?.totalDeservants}</span>
  //       </div>
  //   );
  // };
  // const cycDeservantFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //         <MultiSelect
  //         value={selectedDeservant}
  //         itemTemplate={cycDeservantBody}
  //         onChange={(e) =>setSelectedDeservant(e.value)}
  //         options={cycleData}
  //         optionLabel="totalDeservants"
  //         // display="chip"
  //         placeholder="Select DeservantS"
  //         maxSelectedLabels={1}
  //         className="w-full md:w-12rem"/>
  //     </div>

  //   );

  // };
  // const cycPerbagBody = (option) => {
  //   return (
  //     <div className="p-multiselect-expenseDetails.bagPrice-option">
  //       <span className="image-text">{option?.expenseDetails.bagPrice}</span>
  //     </div>
  //   );
  // };

  // const cycPerbagFilter = (options) => {

  //   return(
  //     <div className="card flex justify-content-center">
  //         <MultiSelect
  //             value={selectedItems}
  //             itemTemplate={cycPerbagBody}
  //             onChange={(e) => setSelectedItems(e.value)}
  //             options={cycleData}
  //             optionLabel="noOfItems"
  //             // display="chip"
  //             placeholder="Select Items"
  //             maxSelectedLabels={1}
  //         className="w-full md:w-12rem"/>
  // </div>
  //   );
  // };
  // const cycTaxBody = (option) => {
  //   return (
  //     <div className="p-multiselect-expenseDetails.tax-option">
  //       <span className="image-text">{option?.expenseDetails?.tax}</span>
  //     </div>
  //   );
  // };

  // const cycTaxFilter = (options) => {

  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect
  //         value={selectedTax}
  //         itemTemplate={cycTaxBody}
  //         onChange={(e) => setSelectedTax(e.value)}
  //         options={cycleData}
  //         optionLabel="tax"
  //         // display="chip"
  //         placeholder="Select Any"
  //         maxSelectedLabels={1}
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );
  //   // <MultiSelect
  //   //   options={cycleData}
  //   //   itemTemplate={cycTaxBody}
  //   //   // onChange={(e) => options.filterApplyCallback(e.value)}
  //   //   optionLabel="tax"
  //   //   placeholder="Any"
  //   //   className="p-column-filter"
  //   //   maxSelectedLabels={1}

  //   // />
  // };
  // const cycAmountBody = (option) => {
  //   return (
  //     <div className="p-multiselect-totalAmount-option">
  //       <span className="image-text">{option?.totalAmount}</span>
  //     </div>
  //   );
  // };

  // const cycAmountFilter = (options) => {

  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect
  //         value={selectedAmount}
  //         itemTemplate={cycAmountBody}
  //         onChange={(e) => setSelectedAmount(e.value)}
  //         options={cycleData}
  //         optionLabel="totalAmount"
  //         // display="chip"
  //         placeholder="Select Any"
  //         maxSelectedLabels={1}
  //     className="w-full md:w-12rem"/>
  // </div>
  //   );

  //   // <MultiSelect
  //   //   options={cycleData}
  //   //   itemTemplate={cycAmountBody}
  //   //   // onChange={(e) => options.filterApplyCallback(e.value)}
  //   //   optionLabel="totalAmount"
  //   //   placeholder="Any"
  //   //   className="p-column-filter"
  //   //   maxSelectedLabels={1}

  //   // />
  // };
  const cycleReport = (cycleData) => {
    setReportLoading(true);
    finalReport(cycleData.id).then(async (res) => {
      if (res.status === 200) {
        const data = res;
        downloadedFile(data, cycleData);
        setReportLoading(false);
      }
    });
  };

  useEffect(() => {
    loadCycleData();
  }, []);
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Rashan Distribution Cycle</h5>
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );

  const hideDialogCycle = () => {
    setValuesForEdit(null);
    setsidebarVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Rashan Distribution Cycle Updated Successfully",
      life: 5000,
    });
  };

  const onRowClick = (cycleId) => {
    history.push({
      pathname: "/cycleDetails",
      state: { cycleId: cycleId },
    });
  };

  return (
    <div className="layout-main layout-main-table">
      <div>
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            ref={dt}
            value={cycleData}
            selection={selectedCycle}
            onRowClick={(e) => onRowClick(e.data)}
            onSelectionChange={(e) => setSelectedCycle(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowHover
            rowsPerPageOptions={[10, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cycle"
            scrollable
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              // "startDate",
              "cycleName",
              "location",
              "noOfItems",
              "totalDonation",
              "totalDeservants",
              "totalAmount",
              "tax",
              "discountedPrice",
            ]}
            loading={loading}
            emptyMessage="No cycles found."
            responsiveLayout="scroll"
            className="table-sel mytab"
          >
            {/* <Column
              className="cyclehover"
              field="startDate"
              header="Date"
              style={{ width: "8rem" }}
              sortable
              filterPlaceholder="Search by startDate"
              body={(record) => {
                return moment(record.startDate).format("MMM-YY").toUpperCase();
              }}
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '8rem' }}
              // filter
              // filterElement={cycDateFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            /> */}
            <Column
              className="cyclehover"
              field="cycleName"
              header="Cycle Name"
              style={{ width: "16rem" }}
              sortable
              sortField="cycleName"
              filterPlaceholder="Search by cycleName"
            />
            <Column
              className="cyclehover"
              field="location.locationName"
              header="Location"
              style={{ minWidth: "14rem" }}
              sortable
              sortField="location.locationName"
              filterPlaceholder="Search by locationName"
            />
            <Column
              className="cyclehover"
              field="totalDonation"
              header="Donations"
              style={{ width: "8rem" }}
              sortable
            // filterPlaceholder="Search by totalDonation"
            // showFilterMenu={true}
            // filterMenuStyle={{ width: '8rem' }}
            // filter
            // filterElement={cycDonationFilter}
            // showFilterMenuOptions={false}
            // filterApply
            // filterClear
            />
            <Column
              className="cyclehover"
              field="totalDeservants"
              header="Deservants"
              style={{ width: "8rem" }}
              sortable
              filterPlaceholder="Search by totalDeservants"
              body={(record) => {
                return record?.expenseDetails.totalDeservants.toFixed(0);
              }}
            // showFilterMenu={true}
            // filterMenuStyle={{ width: '8rem'}}
            // filter
            // filterElement={cycDeservantFilter}
            // showFilterMenuOptions={false}
            // filterApply
            // filterClear
            />

            <Column
              className="cyclehover"
              field="noOfItems"
              header="Price per bag"
              style={{ width: "8rem" }}
              sortable
              filterPlaceholder="Search by bag price"
              body={(record) => {
                return record?.expenseDetails.bagPrice.toFixed(0);
              }}
            // showFilterMenu={true}
            // filterMenuStyle={{ width: '12rem' }}
            // filter
            // filterElement={cycPerbagFilter}
            // showFilterMenuOptions={false}
            // filterApply
            // filterClear
            />
            <Column
              className="cyclehover"
              field="totalAmount"
              header="Total Amount"
              style={{ width: "8rem" }}
              sortable
              filterPlaceholder="Search by totalAmount"
              body={(record) => {
                return record?.totalAmount.toFixed(0);
              }}
            // body={cycAmountBody}
            //  showFilterMenu={true}
            //  filterMenuStyle={{ width: '8rem' }}
            //  filter
            //  filterElement={cycAmountFilter}
            //  showFilterMenuOptions={false}
            //  filterApply
            //  filterClear
            />

            <Column
              className="cyclehover"
              field="tax"
              header="Tax"
              style={{ width: "2rem" }}
              sortable
              filterPlaceholder="Search by tax"
              body={(record) => {
                return Math.floor(record?.expenseDetails.tax.toFixed(0));
              }}
            />
            <Column
              className="cyclehover"
              field="status"
              header="Status"
              style={{ width: "2rem" }}
            />
            <Column
              colSpan={2}
              className="cyclehover"
              field="cycleImagesList"
              header="Receipts"
              style={{ display: "initial" }}
              sortable
              filterPlaceholder="Search by cycleImagesList"
              body={(record) => {
                return record?.cycleImagesList.length <= 0
                  ? "None"
                  : addcycleImageList(record?.cycleImagesList, record);
              }}
            />

            <Column
              header="Action"
              fixed="Right"
              // style={{ width: "16rem" }}
              frozen
              alignFrozen="right"
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    disabled={field.status === "COMPLETED" ? true : false}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-spacing"
                    tooltip="Edit"
                    tooltipOptions={{
                      position: "top",
                    }}
                    onClick={(e) => {
                      console.log("EDITED:: ", field)
                      e.stopPropagation();
                      setValuesForEdit(field);
                      setsidebarVisible(true);
                    }}
                  ></Button>

                  <Button
                    loading={reportLoading}
                    icon="pi pi-download"
                    className="p-button-rounded p-button-spacing"
                    tooltip="Generate Report"
                    tooltipOptions={{
                      position: "top",
                    }}
                    onClick={(e) => {
                      cycleReport(field);
                    }}
                  ></Button>
                </div>
              )}
            ></Column>
          </DataTable>
          <Sidebar
            visible={sidebarVisible}
            position="right"
            style={{ width: "60em" }}
            onHide={() => {
              setsidebarVisible(false);
              setValuesForEdit(null);
            }}
          >
            <CycleForm
              id={cycleId ? cycleId : ""}
              hide={hideDialogCycle}
              editorValues={valuesForEdit ? valuesForEdit : null}
              loadCycleData={loadCycleData}
            />
          </Sidebar>

          <Dialog
            header={headerValue}
            visible={dialogVisible}
            // style={{ width: 'auto' , height: 'auto' }}
            sortable
            filterPlaceholder="Search by cycleImagesList"
            onHide={() => {
              setdialogVisible(false);
              setimageValue("");
            }}
          >
            <div>
              <Spin spinning={apiLoading} size="large">
                <img src={imageValue} width="600" height="400" />
              </Spin>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Cycle;
