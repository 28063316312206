import { Form } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import {
  deleteProgramLocation,
  getAllProgramLocations,
  areaProgramLocation,
} from "../../../apis/programLocationApi";
import "./programLocation.scss";
import NewLocation from "./programLocationForm";

const Location = () => {
  const moment = require("moment");

  const [locationData, setlocationData] = useState([]);
  const [locationTable, setLocationTable] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [date, setDate] = useState(null);
  const [deleteLocation, setDeleteLocation] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState({});
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    contactPerson: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    contactNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const loadLocationData = () => {
    getAllProgramLocations().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data);
        setlocationData(res.data.reverse());
        setLoading(false);
        setValuesForEdit(null);
      }
    });
  };
  useEffect(() => {
    loadLocationData();
  }, []);
  const confirmDeleteSelected = (id) => {
    setDeleteProductsDialog(true);
    setDeleteLocation(id);
  };

  const hideDialog = () => {
    form.resetFields();
    setProductDialog(false);
    setValuesForEdit(null);
    setsidebarVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Program Location Updated Successfully",
      life: 5000,
    });
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
    setDeleteLocation("");
  };
  const deleteSelectedLocation = () => {
    deleteProgramLocation(deleteLocation).then((response) => {
      if (response.status === 200) {
        loadLocationData();
        setDeleteProductsDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Location Deleted",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Delete Location from Tarbiyah Program first",
          life: 3000,
        });
        setDeleteProductsDialog(false);
      }
    });
  };
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedLocation}
      />
    </React.Fragment>
  );

  // useEffect(() => {
  //   const ids = selectedTitle?.map(obj => obj.id);
  //   // console.log("IDSS", ids)
  //   if(ids?.length > 0)
  //   {
  //     areaProgramLocation(ids).then((res) => {
  //       if (res.status === 200) {
  //         // console.log("locations",res.data)
  //         setLocationTable(res.data)
  //       }
  //       else // api call failed
  //         setLocationTable([]);
  //     });
  //   }
  //   else
  //     loadLocationData();
  // }, [selectedTitle])

  // const areaBody = (option) => {
  //   return (
  //     <div className="p-multiselect-location_title-option">
  //       <span className="image-text">{option.title}</span>
  //     </div>
  //   );
  // };
  // const locationRowFilter = (options) => {
  //   return (
  //           <div className="card flex justify-content-center">
  //           <MultiSelect
  //           value={selectedTitle}
  //           onChange={(e) => setSelectedTitle(e.value)}
  //           options={locationData}
  //           optionLabel="title"
  //           // display="chip"
  //           placeholder="Select Area"
  //           maxSelectedLabels={1}
  //           className="w-full md:w-12rem"/>

  //       </div>
  //   );
  // };

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Tarbiyah Program Location</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={(e) => {
            setsidebarVisible(true);
            setValuesForEdit(null);
          }}
        />
      </span>
    </React.Fragment>
  );

  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-crud-demo">
        <Toast ref={toast} position="top-center" />

        <div className="card">
          <DataTable
            ref={dt}
            value={locationTable}
            dataKey="id"
            paginator
            rows={10}
            scrollable
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Location"
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              "title",
              "address",
              "contactPerson",
              "contactNo",
            ]}
            loading={loading}
            emptyMessage="No location found."
            responsiveLayout="scroll"
            className="table-sel mytab"
          >
            <Column
              field="title"
              header="Title"
              style={{ minWidth: "12rem" }}
              sortable
              // filterPlaceholder="Search by Area"
              // showFilterMenu={true}
              // filterMenuStyle={{ width: "12rem" }}
              // body={areaBody}
              // filter
              // filterElement={locationRowFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            />
            <Column
              field="address"
              header="Address"
              style={{ minWidth: "12rem" }}
              sortable
            ></Column>
            <Column
              field="contactPerson"
              header="Contact Person"
              style={{ minWidth: "12rem" }}
              sortable
              // filterPlaceholder="Search by contactPerson"
              // showFilterMenu={true}
              // filterMenuStyle={{ width: "12rem" }}
              // body={personBody}
              // filter
              // filterElement={locationPersonFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            ></Column>
            <Column
              field="contactNo"
              header="Contact Number"
              style={{ minWidth: "12rem" }}
              sortable
            ></Column>

            <Column
              field="creationDate"
              header="Creation Date"
              body={(record) => {
                return moment(record.creationDate).format("yyyy-MM-DD");
              }}
              style={{ minWidth: "16rem" }}
              sortable
              // filterPlaceholder="Search by creationDate"
            ></Column>
            <Column
              header="Action"
              fixed="Right"
              frozen
              alignFrozen="right"
              field={(field) => (
                <div className="edit-delete-btn">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined"
                    onClick={(e) => {
                      setValuesForEdit(field);
                      setsidebarVisible(true);
                    }}
                  />

                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded red-color-btn p-button-spacing"
                    onClick={(e) => confirmDeleteSelected(field.id)}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {
              <span>
                Are you sure you want to delete the selected location?
              </span>
            }
          </div>
        </Dialog>
      </div>
      <Sidebar
        visible={sidebarVisible}
        position="right"
        style={{ width: "60em" }}
        onHide={() => {
          setsidebarVisible(false);
          setValuesForEdit(null);
        }}
      >
        <NewLocation
          hide={hideDialog}
          updatePrograms={loadLocationData}
          editorValues={valuesForEdit ? valuesForEdit : {}}
        />
      </Sidebar>
    </div>
  );
};

export default Location;
