import { Button } from "primereact/button";
import { Column } from "primereact/column";
import React, { useEffect, useState, useRef } from "react";
import "./cycleItemData.scss";
import { useForm, Controller } from "react-hook-form";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import {
  addCycleItems,
  getAllCycleItemsByCycleId,
} from "../../../apis/cycleItemApi";
import { COMPLETED } from "../../../utils/constants";
import AddCycleItem from "../CycleItems/addCycleitem";
import "./cycleItemData.scss";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";

function CycleItemData(props) {
  const toast = useRef(null);
  const [cycleData, setCycleData] = useState([]);
  const [cycleItemFromVisible, setCycleItemFormVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "itemId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    quantity: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    price: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const {
    control,
    formState: { errors },
    reset,
  } = useForm();

  const openNewCycleItemForm = () => {
    setCycleItemFormVisible(true);
  };
  const loadAllCycleItemData = () => {
    getAllCycleItemsByCycleId(props.cycleId.id).then((res) => {
      if (res.status === 200) {
        setCycleData(res.data);
        setLoading(false);
      }
    });
  };
  const onSubmit = (data) => {
    let dataForApi = {
      id: props?.editorValues ? props?.editorValues.id : null,
      unit: data.unit,
      name: data.name,
    };
    addCycleItems(dataForApi).then((res) => {
      if (res.status === 200) {
        reset();
        props.hide();
      }
    });
  };

  useEffect(() => {
    loadAllCycleItemData();
  }, []);
  // const cycleItemBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-itemId.name-option">
  //           <span className="image-text"> {option.itemId.name}</span>
  //       </div>
  //   );
  // };
  // const cycItemFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedItemFilter} 
  //         itemTemplate={cycleItemBody} 
  //         onChange={(e) => setSelectedItemFilter(e.value)} 
  //         options={cycleData} 
  //         optionLabel="itemId.name" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  //   </div>
  //   );
  // };
  // const cycleQuantityBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-quantity-option">
  //           <span className="image-text"> {option.quantity}</span>
  //       </div>
  //   );
  // };
  // const cycQuantityFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedQuantityFilter} 
  //         itemTemplate={cycleQuantityBody} 
  //         onChange={(e) => setSelectedQuantityFilter(e.value)} 
  //         options={cycleData} 
  //         optionLabel="quantity" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  //   </div>
  //   );
  // };
  // const cyclePriceBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-price-option">
  //           <span className="image-text"> {option.price}</span>
  //       </div>
  //   );
  // };
  // const cycPriceFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //         value={selectedPriceFilter} 
  //         itemTemplate={cyclePriceBody} 
  //         onChange={(e) => setSelectedPriceFilter(e.value)} 
  //         options={cycleData} 
  //         optionLabel="price" 
  //         // display="chip" 
  //         placeholder="Select Any" 
  //         maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>
  //   </div>
  //   );
    
  //   // <MultiSelect  
  //   // options={cycleData} 
  //   // cycleitem={cyclePriceBody} 
  //   // // onChange={(e) => options.filterApplyCallback(e.value)} 
  //   // optionLabel="price" 
  //   //  placeholder="Any" 
  //   //  className="p-column-filter" 
  //   // maxSelectedLabels={1} 
  //   // />
  // };
  const hideDialogCycleItem = () => {
    setValuesForEdit(null);
    setCycleItemFormVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Cycle Items Updated Successfully",
      life: 5000,
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        {props?.cycleId?.status != COMPLETED ? (
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-sm"
            onClick={(e) => {
              e.stopPropagation();
              openNewCycleItemForm();
            }}
          />
        ) : (
          ""
        )}
        <Toast ref={toast} position="top-center" />
      </div>
      <DataTable
        value={cycleData}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} CycleItems"
        responsiveLayout="scroll"
        className="table-sel mytab2"
        scrollable
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "itemId.name",
          "quantity",
          "price",
          "discountedPrice",
        ]}
        loading={loading}
        emptyMessage="No items found."
      >
        <Column
          field="itemId.name"
          header="Item Name"
          style={{ minWidth: "10rem" }}
          body={(record) => {
            return record.itemId.name;
          }}
          sortable
          filterPlaceholder="Search by itemId.name"
          // showFilterMenu={true}
          //     filterMenuStyle={{ width: '12rem' }}
          //     filter
          //     filterElement={cycItemFilter}
          //     showFilterMenuOptions={false}
          //     filterApply
          //     filterClear
        />

        <Column
          field="quantity"
          header="Quantity"
          // body={(record) => {
          //   return record.CycleItem.quantity;
          // }}
          style={{ minWidth: "10rem" }}
          sortable
          filterPlaceholder="Search by quantity"
          // showFilterMenu={true}
          // filterMenuStyle={{ width: '12rem' }}
          // filter
          // filterElement={cycQuantityFilter}
          // showFilterMenuOptions={false}
          // filterApply
          // filterClear
        />

        <Column
          field="price"
          header="Price"
          style={{ minWidth: "10rem" }}
          sortable
          filterPlaceholder="Search by price"
          // showFilterMenu={true}
          // filterMenuStyle={{ width: '12rem' }}
          // filter
          // filterElement={cycPriceFilter}
          // showFilterMenuOptions={false}
          // filterApply
          // filterClear
        />

        {/* <Column
          field="discountedPrice"
          header="Discounted Price"
          style={{ minWidth: "10rem" }}
          sortable
          filterPlaceholder="Search by discountedPrice"
        /> */}
        {props?.cycleId?.status != COMPLETED ? (
          <Column
            header="Action"
            fixed="Right"
            field={(field) => (
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-outlined"
                onClick={(e) => {
                  setValuesForEdit(field);
                  setCycleItemFormVisible(true);
                }}
              ></Button>
            )}
          ></Column>
        ) : null}
      </DataTable>
      <Sidebar
        visible={cycleItemFromVisible}
        position="right"
        style={{ width: "60em" }}
        onHide={() => {
          setCycleItemFormVisible(false);
          setValuesForEdit(null);
        }}
      >
        <AddCycleItem
          id={props.cycleId.id ? props.cycleId.id : 1}
          hide={hideDialogCycleItem}
          loadItems={loadAllCycleItemData}
          editorValues={valuesForEdit ? valuesForEdit : null}
        />
      </Sidebar>
    </React.Fragment>
  );
}
export default CycleItemData;
