import React, { useState } from "react";
import "./style.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import "primeflex/primeflex.css";
import { useDispatch } from "react-redux";
import { loginUser } from "./../../actions/user";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
// import RegisterForm from "../register/registerform";
import { yupResolver } from "@hookform/resolvers/yup";
import RashanAppLogo from "./../../resources/RashanAppLogo.PNG";
import * as Yup from "yup";
import Register from "./register";

const LoginForm = (props) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  document.documentElement.style.fontSize = "13px";
  const dispatch = new useDispatch();
  // const [checkForRegisterForm, setCheckForRegisterForm] = useState(false);
  const [roles, setRoles] = useState("");
  const history = useHistory();
  const location = useLocation();
  const formSchema = Yup.object().shape({
    pwd: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    cPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("pwd")], "Passwords does not match"),
  });

  const defaultValues = {
    username: "",
    password: "",
  };

  const { control, register, handleSubmit, reset, formState } = useForm({
    // resolver: yupResolver(formSchema),
    defaultValues,
  });
  const { errors } = formState;
  function handleLogin(values) {
    dispatch(loginUser(values, history, location, props));
  }

  const onSubmit = (data) => {
    handleLogin(data);
    reset();
  };
  const onSignupSubmit = (data) => {
    // handleLogin(data);
    // reset();
  };

  const passwordHeader = <h6>Pick a password</h6>;

  const changeDialogVisibility = (bool) => {
    setDialogVisible(bool);
  };
  // const renderFooter = (name) => {
  //   return (
  //     <div>
  //       <Button
  //         label="Submit"
  //         icon="pi pi-check"
  //         type="submit"
  //         // onClick={() => onHideDialog()}
  //         autoFocus
  //       />
  //     </div>
  //   );
  // };

  return (
    <React.Fragment>
      {/* {!checkForRegisterForm && ( */}
      <div
        className="flexgrid-demo p-dir-col custom-button-color"
        style={{ margin: "0" }}
      >
        <div className="p-col">
          <div className="box">
            <div className="flex align-items-center justify-content-center">
              <div
                className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
                style={{ width: "35%" }}
              >
                <div className="text-center mb-5">
                  <img
                    src={RashanAppLogo}
                    alt="logo"
                    // style={{ mar }}
                    className="mt-3"
                  />
                  <div className="text-900 text-3xl font-medium mb-3"></div>
                  <span className="text-600 font-medium line-height-3">
                    {/* Don't have an account? */}
                  </span>
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    // onClick={registerBtn}
                  >
                    {/* Create Account! */}
                  </a>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-900 font-medium mb-2 custom-text-align"
                    >
                      User Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      name="username"
                      control={control}
                      rules={{ required: "Username is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          placeholder="Username"
                          id={field.name}
                          {...field}
                          autoFocus
                          className="w-full mb-3"
                        />
                      )}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-900 font-medium mb-2 custom-text-align"
                    >
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "Password is required." }}
                      render={({ field, fieldState }) => (
                        <div className="custom-password-field">
                          <Password
                            placeholder="Password"
                            id={field.name}
                            {...field}
                            autoFocus
                            className="w-full mb-3"
                            toggleMask
                            header={passwordHeader}
                            feedback={false}
                            // type="password"
                          />
                        </div>
                      )}
                    />
                    <div className="btnNsignup">
                      {/* <span className="noAc">
                        No Account?{" "}
                        <span
                          className="singup"
                          onClick={() => setDialogVisible(true)}
                        >
                          Create One!
                        </span>
                      </span> */}
                      <Button
                        type="submit"
                        label="Login"
                        icon="pi pi-user"
                        className="custom-button"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <Dialog
          header="Sign Up"
          visible={dialogVisible}
          onHide={() => onHideDialog()}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "40vw" }}
        >
          <div>
            <form onSubmit={handleSubmit(onSignupSubmit)} className="p-fluid">
              <div className="form-align-justify">
                <label
                  htmlFor="name"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Name*
                </label>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className="custom-width-sp mb-3"
                    />
                  )}
                />
              </div>
              <div className="form-align-justify">
                <label
                  htmlFor="email"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Email
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className="custom-width-sp mb-3"
                    />
                  )}
                />
              </div>

              <div className="form-align-justify">
                <label
                  htmlFor="pwd"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Password*
                </label>
                <Controller
                  name="pwd"
                  control={control}
                  rules={{ required: "Email is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={`form-control custom-width-sp mb-3 ${
                        errors.pwd ? "is-invalid" : ""
                      }`}
                      {...register("pwd")}
                      type="password"
                    />
                  )}
                />
                <div className="invalid-feedback">{errors.pwd?.message}</div>
              </div>

              <div className="form-align-justify">
                <label
                  htmlFor="cPwd"
                  className="block text-900 font-medium mb-2 mr-7"
                >
                  Confirm Password*
                </label>
                <Controller
                  name="cPwd"
                  control={control}
                  rules={{ required: "Email is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={`form-control custom-width-sp mb-3 ${
                        errors.cPwd ? "is-invalid" : ""
                      }`}
                      {...register("cPwd")}
                      type="password"
                    />
                  )}
                />
                <div className="invalid-feedback">{errors.cPwd?.message}</div>
              </div>

              <Button
                label="Submit"
                icon="pi pi-check"
                type="submit"
                // onClick={() => onHideDialog()}
                autoFocus
              />
            </form>
          </div>
        </Dialog> */}
        {/* dialog for option */}
        {/* <Dialog
        header="Continue as"
        visible={choiceDialogVisible}
        onHide={() => onHideChoiceDialog()}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "40vw" }}
        
        >
          <div className="choiceMainCon">
              <div className="cCard" onClick={()=>choiceHandler('deservant')}><span className="cText">Deservant</span></div>
              <div className="cCard" onClick={()=>choiceHandler('donor')}><span className="cText">Donor</span></div>
          </div>
        </Dialog> */}
      </div>
      {/* )} */}
      {/* {checkForRegisterForm && (
        <RegisterForm checkFalse={() => setCheckForRegisterForm(false)} />
      )} */}

      <Register visible={dialogVisible} setVisibility={changeDialogVisibility} />
    </React.Fragment>
  );
};
export default LoginForm;
