import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { getAttendeesById } from "../../../apis/ProgramAttendeesApis";
import { Input } from "antd";
import "./DeservantDetails.scss";

const DeservantDetails = (props) => {
  const { Search } = Input;
  const dt = useRef(null);
  const [deservantDetails, setDeservantDetials] = useState([]);
  const [loading, setLoading] = useState(true);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");
  const [field, setField] = useState("id");
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: "tarbiyahProgram?.id", order: -1 },
  ]);
  const loadDeservantData = (id) => {
    setLoading(true);
    getAttendeesById(id).then((res) => {
      if (res.status === 200) {
        setDeservantDetials(res.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const addDeservantList = (list) => {
    return list.map((userId) => {
      return (
        <Tag
          style={{
            fontSize: "100%",
            backgroundColor: "#00B1FF",
            marginTop: "1px",
          }}
          className="mr-2"
          // icon="pi pi-user"
          value={userId?.userId?.name}
        ></Tag>
      );
    });
  };
  useEffect(() => {
    if (props?.deservant?.id) {
      loadDeservantData(props?.deservant?.id);
    }
  }, props);

  // useEffect(() => {
  //   if (searchedValue === "") {
  //     DeservantCount(currentPage, pageSize);
  //   } else {
  //     loadSerachData(searchedValue, currentPage, pageSize);
  //   }
  // }, [currentPage, pageSize]);

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   setSearchedValue(e.target.value);
  //   if (dt.current) clearTimeout(dt.current);
  //   if (e.target.value !== "") {
  //     dt.current = setTimeout(() => {
  //       loadSerachData(value, currentPage, pageSize);
  //     }, 1000);
  //   } else {
  //     setSearchedValue("");
  //     DeservantCount(currentPage, pageSize);
  //   }
  //   // setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  const rightContents = (
    <React.Fragment>
      <div className="card flex flex-wrap justify-content-center gap-3">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            className="w-20rem"
            // value={globalFilterValue}
            // onChange={onGlobalFilterChange}
            placeholder="Search by Lecturer"
          />
        </span>
      </div>
    </React.Fragment>
  );

  const leftContents = (
    <React.Fragment>
      <b>
        <h3 className="mx-0 my-1">Attented Classes</h3>
      </b>
    </React.Fragment>
  );

  return (
    <div style={{ display: "flex", background: "#edf1f5" }}>
      <div style={{ padding: 10 }}>
        <div>
          <Toolbar
            //style={{ border: "hidden" }}
            right={rightContents}
            left={leftContents}
          />
        </div>
        <DataTable
          emptyMessage="This Deservant is not invited in any Program"
          ref={dt}
          paginator
          rows={10}
          rowHover
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cycle"
          value={deservantDetails.filter((value) => {
            if (value?.attended === true) {
              return value;
            }
          })}
          dataKey="id"
          loading={loading}
          responsiveLayout="scroll"
          className="table-sel mytab "
          globalFilterFields={["Lecturer", "ProgramDate", "Topic Name"]}
          sortMode="multiple"
          multiSortMeta={multiSortMeta}
          onSort={(e) => {
            e?.multiSortMeta[0]?.order === 1
              ? setOrder("asc")
              : setOrder("desc");
            setMultiSortMeta(e.multiSortMeta);
            setField(e?.multiSortMeta[0]?.field);
          }}
        >
          <Column
            field="Lecturer"
            header="Lecturer"
            style={{ width: "26rem" }}
            sortable
            sortField="Lecturer"
            filterPlaceholder="Search by Lecturer"
            body={(record) => {
              return record?.tarbiyahProgram.lecturer <= 0
                ? "None"
                : addDeservantList(record?.tarbiyahProgram?.lecturers);
            }}
          />
          <Column
            field="ProgramDate"
            header="Program Date"
            style={{ width: "26rem" }}
            sortable
            sortField="ProgramDate"
            filterPlaceholder="Search by Program Date"
            body={(record) => {
              return moment(record?.tarbiyahProgram?.date)
                .format("dd-MMM, DD YYYY HH:mm")
                .toUpperCase();
            }}
          />

          <Column
            field="Topic Name"
            header="Topic Name"
            style={{ width: "26rem" }}
            filterPlaceholder="Search by Topic name"
            body={(record) => {
              return record?.tarbiyahProgram?.classTopic;
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};
export default DeservantDetails;
