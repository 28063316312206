import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import {
  deleteCycleDistributor,
  distributorsbycycle,
  getDistributorAndDeservantById,
  getDistributorAndDeservantByIdCount,
  getReferredByFilter,
  uniqueReferrers,
} from "../../../apis/CycleDistributorApi";

import { Sidebar } from "primereact/sidebar";
import { COMPLETED } from "../../../utils/constants";
import CycleDistributors from "../CycleDistributor/cycleDistributors";
import DistributorStatus from "./cycleDistributorStatus";
import { Paginator } from "primereact/paginator";
import { MultiSelect } from 'primereact/multiselect';

function CycleDistributorData(props) {
  const toast = useRef(null);
  const [cycleDeservants, setCycleDeservants] = useState([]);
  const [cycleDistributorVisible, setCycleDistributorVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState();
  const [deleteDeservant, setDeleteDeservant] = useState({});
  const [deleteDeservantDialog, setDeleteDeservantDialog] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [basicFirst, setBasicFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [referrers, setReferrers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [deservantPriority] = useState(["High", "Medium", "Low"]);
  const ref = useRef();
  const [selectedReffered, setSelectedReffered] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [field, setField] = useState("id");
  const [order, setOrder] = useState("asc");
  const [totalCycleDistributor, setTotalCycleDistributor] = useState();

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "deservantId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservantId.userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "deservantId.priority": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservantId.dependentMember": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "distributorId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    deliveryStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservantId.userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const onGlobalFilterChange = (e) => {
    setFilters(e.target.value);
    setGlobalFilterValue(e.target.value);
    if (ref.current) clearTimeout(ref.current);
    if (e.target.value !== "") {
      ref.current = setTimeout(() => {
        setSearchText(e?.target.value);
      }, 1000);
    } else {
      setSearchText("");
      searchReferrers();
    }
  };
  const searchReferrers = () => {
    setLoading(true);
    uniqueReferrers().then((res) => {
      if (res.status === 200) {
        setReferrers(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    searchReferrers();
  }, []);

  const filterReferred = (filter) => {
    getReferredByFilter(filter, currentPage, pageSize).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setCycleDeservants(response.data.deservant);
        setTotalCycleDistributor(response.data.count);
      }
      else {
        // show error
      }
    });
  }

  useEffect(() => {

    var filter = {}
    setLoading(true);
    if (selectedReffered?.length > 0) {
      filter["distributorId"] = selectedReffered.map(referrer => referrer.name);
    }
    if (selectedPriority?.length > 0) {
      filter["priority"] = selectedPriority;
    }
    if (searchText) {
      filter["search"] = searchText;
    }
    filter["cycleId"] = props.cycleId.id
    filterReferred(filter);
  }, [currentPage, pageSize, field, order, selectedReffered, selectedPriority, searchText]);

  const openNewDistributorForm = () => {
    setCycleDistributorVisible(true);
  };

  const currentCycleDeservant = (id) => {
    setLoading(true);
    getDistributorAndDeservantById(
      props.cycleId.id,
      currentPage,
      pageSize
    ).then((res) => {
      if (res.status === 200) {
        setCycleDeservants(res.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const hideDialog = () => {
    setValuesForEdit(null);
    setCycleDistributorVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Cycle Deservants Updated Successfully",
      life: 5000,
    })
  };
  const onPageChange = (event) => {
    setBasicFirst(event.first);
    setCurrentPage(event.page);
    setPageSize(event.rows);
  };

  const confirmDeleteSelected = (record) => {
    var deservantData = {
      cycleId: record.cycleId.id,
      deservantId: record.deservantId.id,
    };
    setDeleteDeservant(deservantData);
    setDeleteDeservantDialog(true);
  };

  const hideDeleteDeservantDialog = () => {
    setDeleteDeservantDialog(false);
    setDeleteDeservant("");
  };

  const deleteSelectedDeservant = () => {
    deleteCycleDistributor(
      deleteDeservant.cycleId,
      deleteDeservant.deservantId
    ).then((response) => {
      if (response.status === 200) {
        // currentCycleDeservantCount(deleteDeservant.cycleId);
        currentCycleDeservant(deleteDeservant.cycleId);
        setDeleteDeservantDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Deservant Deleted",
          life: 3000,
        });
      }
      setDeleteDeservantDialog(false);
    });
  };

  const deleteDeservantDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteDeservantDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedDeservant}
      />
    </React.Fragment>
  );


  const cycleReferredBody = (option) => {
    return (
      <div className="p-multiselect-distributorId.userId.name-option">
        <span className="image-text"> {option.distributorId.userId.name}</span>
      </div>
    );
  };
  const representativeMultiSelectBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-0">
          <div>
            <Avatar
              label={rowData?.name?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          </div>
          <div>
            <p>{rowData?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const cyccleRefferedFilter = (options) => {
    return (

      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setSelectedReffered(e?.value)
          options.filterCallback(e?.value);
        }}
        options={referrers}
        itemTemplate={representativeMultiSelectBodyTemplate}
        optionLabel="name"
        placeholder="Select ReferredBy"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: '19rem' }}
      />

    );
  };

  const cyclePrioritydBody = (option) => {
    return (
      <div className="p-multiselect-deservantId.priority-option">
        <span className="image-text"> {option.deservantId.priority}</span>
      </div>
    );
  };
  const cycPriorityFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setSelectedPriority(e.value);
          options.filterCallback(e?.value);
        }}
        options={deservantPriority}
        // optionLabel="deservantId.priority"
        placeholder="Select Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: '4rem' }}
      />

    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        {props?.cycleId?.status != COMPLETED ? (
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-sm"
            style={{ marginRight: "3px" }}
            onClick={(e) => {
              e.stopPropagation();
              openNewDistributorForm();
            }}
          />
        ) : (
          ""
        )}
        {props?.cycleId?.status != COMPLETED ? (
          <Button
            label="Status"
            icon="pi pi-plus"
            className="p-button-sm"
            onClick={(e) => {
              setStatusUpdate(true);
              e.stopPropagation();
            }}
          />
        ) : (
          ""
        )}
      </div>

      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            value={cycleDeservants}
            // paginator
            className="p-datatable-customers mytab2"
            responsiveLayout="scroll"
            scrollable
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              "deservantId.userId.name",
              "deservantId.userId.cnic",
              "deservantId.priority",
              "deservantId.dependentMember",
              "distributorId.userId.name",
              "deliveryStatus",
              "deservantId.userId.mobile",
            ]}
            loading={loading}
            emptyMessage="No deservants found."
          >
            <Column
              field="deservantId.userId.name"
              header="Name"
              sortable
              filterPlaceholder="Search by deservantId.userId.name"
              style={{ width: "8rem" }}
              body={(record) => {
                return record.deservantId.userId.name;
              }}
            />
            <Column
              field="distributorId.userId.name"
              header="Referred By"
              body={cycleReferredBody}
              sortable
              filterPlaceholder="Search by deservantId.referrer"
              style={{ minWidth: "12rem" }}
              showFilterMenu={true}
              // filterMenuStyle={{ width: '12rem' }}
              filter
              filterElement={cyccleRefferedFilter}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />
            <Column
              field="deservantId.priority"
              header="Priority"
              sortable
              filterPlaceholder="Search by Priority"
              style={{ width: "12rem" }}
              showFilterMenu={true}
              filter
              filterElement={cycPriorityFilter}
              showFilterOperator={false}
              showFilterMenuOptions={false}
              filterApply
              filterClear
            />
            <Column
              field="deservantId.dependentMember"
              header="Dependent Member"
              sortable
              filterPlaceholder="Search by deservantId.dependentMember"
              style={{ width: "8rem" }}
              body={(record) => {
                return record.deservantId.dependentMember;
              }}
            />
            <Column
              field="deliveryStatus"
              header="Status"
              sortable
              filterPlaceholder="Search by deliveryStatus"
              style={{ width: "8rem" }}
              body={(record) => {
                if (record.deliveryStatus) return "Delivered";
                else return "Not Delivered";
              }}
            />
            <Column
              field="deservantId.userId.mobile"
              header="Mobile"
              sortable
              filterPlaceholder="Search by deservantId.userId.mobile"
              style={{ width: "8rem" }}
              body={(record) => {
                return record.deservantId.userId.mobile;
              }}
            />
            {props?.cycleId?.status != COMPLETED ? (
              <Column
                header="Action"
                fixed="Right"
                style={{ width: "8rem" }}
                field={(field) => (
                  <div>
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded delete-button"
                      onClick={() => {
                        confirmDeleteSelected(field);
                      }}
                    />
                  </div>
                )}
              ></Column>
            ) : null}
          </DataTable>
          <Paginator
            first={basicFirst}
            rows={pageSize}
            totalRecords={totalCycleDistributor}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
            template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Deservants"
          ></Paginator>
          <Sidebar
            visible={cycleDistributorVisible}
            position="right"
            style={{ width: "80em" }}
            onHide={() => {
              setCycleDistributorVisible(false);
              setValuesForEdit(null);
            }}
          >
            <CycleDistributors
              id={props.cycleId.id ? props.cycleId.id : 1}
              hide={hideDialog}
              currentCycleDeservants={() =>
                currentCycleDeservant(props.cycleId.id)
              }
            />
          </Sidebar>
        </div>
        <Dialog
          visible={deleteDeservantDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteDeservantDialogFooter}
          onHide={hideDeleteDeservantDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {
              <span>
                Are you sure you want to delete the selected Deservant?
              </span>
            }
          </div>
        </Dialog>
      </div>
      <Sidebar
        visible={statusUpdate}
        position="right"
        style={{ width: "35em" }}
        onHide={() => {
          setStatusUpdate(false);
        }}
      >
        <DistributorStatus
          cycleId={props.cycleId.id}
          deservants={cycleDeservants}
          hide={() => {
            setStatusUpdate(false);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Delivery Status Updated",
              life: 3000,
            });
          }}
          updateData={currentCycleDeservant}
        />
      </Sidebar>
    </React.Fragment>
  );
}

export default CycleDistributorData;
