/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import LoginForm from "./../login/login";
import Home from "./../home";
import { useSelector, useDispatch } from "react-redux";
import { loadUserInfo } from "./../../actions/user";
import {
  getSessionIdentifier,
  clearSessionIdentifier,
  setSessionIdentifier,
} from "../../utils/sessionIdentifier";
import { getAuthToken } from "./../../utils/authToken";
import doSessionManagement from "../../utils/sessionManager";
import { Switch, Route } from "react-router-dom";
import PrivacyPolicy from "../privacyPolicy/privacyPolicy";
import AccountDeletion from "../accountDeletion/accountDeletion";

const Root = () => {
  const [token, setToken] = useState(getAuthToken());
  const dispatch = new useDispatch();
  const history = createBrowserHistory();
  const [sessionManage, setSessionManage] = useState(true);
  const { currentUser = {} } = useSelector(({ app }) => app);

  const authStatus = () => {
    history.push("/dashboard");
    setToken(getAuthToken());
  };

  if (currentUser) {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      dispatch(loadUserInfo());
      setSessionIdentifier(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const token = getAuthToken();
    if (!getSessionIdentifier()) {
      clearSessionIdentifier();
    }
    if (token && sessionManage && !getSessionIdentifier()) {
      doSessionManagement(token);
      setSessionManage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogout = () => {
    setToken(null);
  };

  return (
    <main className="nordoy-app">
      <div className="content">
        <Switch>
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/account-deletion" component={AccountDeletion} />
          <Route path="/">
            {!token ? (
              <LoginForm authStatus={authStatus} />
            ) : (
              <Home defaultView="dashboard" onLogout={onLogout} />
            )}
          </Route>
        </Switch>
      </div>
    </main>
  );
};

export default Root;
