import { Form, Spin, Table } from "antd";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import "./donor.scss";
import { Input } from "antd";
import { getAllDonors } from "../../../apis/DonorApi";

const Donor = () => {
  const { Search } = Input;
  const [donors, setDonors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filteredDonors, setFilteredDonors] = useState([]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setGlobalFilterValue(value);

    const filteredData = donors.filter((donor) => {
      const { userId, referredBy } = donor;
      return (
        userId.name.toLowerCase().includes(value) ||
        (userId.email && userId.email.toLowerCase().includes(value)) ||
        userId.mobile.toLowerCase().includes(value) ||
        (userId.address && userId.address.toLowerCase().includes(value)) ||
        (userId.location &&
          userId.location.locationName.toLowerCase().includes(value)) ||
        (referredBy && referredBy.name.toLowerCase().includes(value))
      );
    });

    setFilteredDonors(filteredData);
  };

  useEffect(() => {
    fetchDonors();
  }, []);

  const fetchDonors = async () => {
    setLoading(true);
    const response = await getAllDonors();
    if (response.status === 200) {
      setDonors(response.data);
      setFilteredDonors(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "userId",
      key: "userId.name",
      sorter: (a, b) => a.userId.name.localeCompare(b.userId.name),
      render: (userId) => <>{userId.name}</>,
    },
    {
      title: "Email",
      dataIndex: "userId",
      key: "userId.email",
      render: (userId) => (userId.email ? <>{userId.email}</> : ""),
    },
    {
      title: "Mobile No.",
      dataIndex: "userId",
      key: "userId.mobile",
      sorter: (a, b) => a.userId.mobile.localeCompare(b.userId.mobile),
      render: (userId) => <>{userId.mobile}</>,
    },
    {
      title: "Referred By",
      dataIndex: "referredBy",
      key: "referredBy.name",
      render: (referredBy) => (referredBy ? <>{referredBy.name}</> : ""),
    },
    {
      title: "Address",
      dataIndex: "userId",
      key: "userId.address",
      render: (userId) => (userId.address ? <>{userId.address}</> : ""),
    },
    {
      title: "Location",
      dataIndex: "userId",
      key: "userId.location.locationName",
      sorter: (a, b) =>
        a.userId.location.locationName.localeCompare(
          b.userId.location.locationName
        ),
      render: (userId) => <>{userId.location.locationName}</>,
    },
  ];

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Donor</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            className="w-20rem"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </span>
    </React.Fragment>
  );

  return (
    <div className="layout-main">
      <React.Fragment>
        <div>
          <div className="mb-4">
            <Toolbar
              style={{ border: "hidden" }}
              left={leftContents}
              right={rightContents}
            />
          </div>
          <Spin spinning={loading}>
            <Table dataSource={filteredDonors} columns={columns} />
          </Spin>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Donor;
