import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  deleteCycleImage,
  uploadCycleImage,
} from "../../../apis/CycleImageApi";
import { RECEIPT_TYPE } from "../../../utils/constants";
import "./cycleRecipt.scss";

function CycleReceipt(props) {
  const [inputList, setInputList] = useState([{ receipt: {}, file: null }]);
  const [cycleImage, setCycleImage] = useState();
  const toast = useRef(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    // const list = [...inputList];
    // list.splice(index, 1);
    // setInputList(list);
    // remove api
    deleteCycleImage(cycleImage).then((res) => {
      if (res.status === 200) {
        let newInputList = inputList.filter((item, i) => index !== i);
        setInputList([...newInputList]);
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { receipt: {}, file: null }]);
  };

  const tobase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      // let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onBasicUpload = async (event, x, i) => {
    const file = event.files[0];
    var base64data = await tobase64(file);

    let newData = {
      receipt: x.receipt.name,
      image: base64data,
      cycleId: {
        id: props.cycleId,
      },
    };
    // Add image api

    uploadCycleImage(newData).then((res) => {
      if (res.status === 200) {
        let newInputList = inputList.filter((item, index) => index !== i);
        let savedData = {
          id: res.data.id,
          receipt: RECEIPT_TYPE.find((item) => item.name === res.data.receipt),
          file: res.data.image,
        };
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Image Uploaded Successful",
          life: 3000,
        });
        newInputList.concat(savedData);

        setInputList([...newInputList, savedData]);
      }
    });
  };

  return (
    <div className="App">
      <Toast ref={toast} position="top-center" />
      {inputList.map((x, i) => {
        return (
          <React.Fragment>
            <div className="box">
              <div className="flex align-items-center">
                <label
                  htmlFor="receipt"
                  className="col-12 mb-2 md:col-3 md:mb-0"
                >
                  Image Type
                </label>
                <div
                  className="col-10 md:col-8"
                  style={{ width: "max-content" }}
                >
                  <Controller
                    name="receipt"
                    control={control}
                    rules={{ required: " is required." }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        id={field.name}
                        {...field}
                        style={{ width: "210px" }}
                        autoFocus
                        options={RECEIPT_TYPE}
                        optionLabel={(option) => option.name}
                        value={x.receipt}
                        onChange={(e) => handleInputChange(e, i)}
                        placeholder="Select Image Type"
                      />
                    )}
                  />

                  {getFormErrorMessage("receipt")}
                </div>
                <div>
                  <FileUpload
                    mode="basic"
                    uploadHandler={(file) => {
                      onBasicUpload(file, x, i);
                    }}
                    // className="file-upload truncate"
                    name="file"
                    value={x.file}
                    chooseLabel="Browse"
                    accept="image/*"
                    maxFileSize={10000000}
                    customUpload
                    auto
                  />
                </div>
                <div>
                  {inputList.length > 1 && (
                    <Button
                      type="remove"
                      label="Remove"
                      className="submit-btn ml-1"
                      onClick={(field) => {
                        field.preventDefault();
                        handleRemoveClick(i);
                        setCycleImage(i);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex">
              <div>
                {inputList.length - 1 === i && (
                  <Button
                    type="add"
                    className="mt-1"
                    label="Add Image"
                    onClick={handleAddClick}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default CycleReceipt;
