import React, { useEffect, useState,useRef } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import {
  addItems, getAllItems
} from "../../../apis/ItemsApi";
import "./addItems.scss";
import { Toast } from "primereact/toast";

const AddItems = (props) => {
  const toast = useRef(null);

  const [showMessage, setShowMessage] = useState(false);
  
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );

  const fetchItems = () => {
    getAllItems().then((res) => {
      if (res.status === 200) {
      }
    });
  };

  useEffect(() => {
    fetchItems();
    if (props?.editorValues) {
      setValue("name", props?.editorValues.name);
      setValue("unit", props?.editorValues.unit);
    } else {
      reset();
    }
  }, [props?.editorValues]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const onSubmit = (data) => {
    let dataForApi = {
      id: props?.editorValues ? props?.editorValues.id : null,
      unit: data.unit,
      name: data.name,
    };
    addItems(dataForApi).then((res) => {
      if (res.status === 200) {
        reset();
        props.hide();
        props.load();
      }
      
    });
  };

  return (
    <div className="layout-main">
      <div className="form-demo">
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex align-items-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h5>Item Registered Successfully!</h5>
          </div>
        </Dialog>
        <Toast ref={toast} position="top-center" />

        <div className="flex justify-content-center">
          <div className="card width-adjuster-for-add-item-form">
            <h5 className="text-center font-for-register">Item</h5>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="p-fluid">
                <div className="field grid" style={{ marginTop: "30px" }}>
                  <label
                    htmlFor="Name"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Name
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: "name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("name")}
                  </div>
                </div>

                <div className="field grid" style={{ marginTop: "5px" }}>
                  <label
                    htmlFor="Unit"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    Unit
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="unit"
                      control={control}
                      rules={{ required: "unit is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("unit")}
                  </div>
                </div>
              </div>
              <Button type="submit" label="Submit" className="submit-btn" />
            </form>
            <Button
              type="cancel"
              label="Cancel"
              className="p-button-danger cancel-btn"
              onClick={props.hide}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItems;
