import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { allPrograms, deletePrograms } from "../../../apis/TarbiyahProgramApi";
import TarbiyahProgramForm from "./tarbiyahClassForm";
import "./tarbiyahProgram.scss";

const Classes = () => {
  const moment = require("moment");
  const history = useHistory();
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [programData, serProgramData] = useState(null);
  const [valuesForEdit, setValuesForEdit] = useState({});
  const [deleteProgramDialog, setDeleteProgramDialog] = useState(false);
  const [deleteProgram, setDeleteProgram] = useState();
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const dt = useRef(null);

  const loadProgramData = () => {
    allPrograms().then((res) => {
      if (res.status === 200) {
        serProgramData(res.data.reverse());
        setLoading(false);
      }
    });
  };

  const deleteSelectedItems = () => {
    deletePrograms(deleteProgram).then((response) => {
      if (response.status === 200) {
        loadProgramData();
        setDeleteProgramDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Tarbiyah Program Deleted",
          life: 3000,
        });
      }
    });
  };

  const lecturerBodyTemplate = (rowData) => {
    let steps = [];
    // eslint-disable-next-line array-callback-return
    rowData.lecturers.map((lecturer) => {
      steps.push(
        <Tag
          style={{ fontSize: "100%", backgroundColor: "#00B1FF" }}
          className="mr-2"
          icon="pi pi-user"
          value={lecturer.userId.name}
        ></Tag>
      );
    });
    return steps;
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProgramDialog(false);
    setDeleteProgram("");
  };
  const deleteItemDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedItems}
      />
    </React.Fragment>
  );

  const onRowClick = (program) => {
    history.push({
      pathname: "/programDetail",
      state: { programs: program },
    });
  };
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Tarbiyah Program</h5>
    </React.Fragment>
  );

  const hideDialog = () => {
    setValuesForEdit(null);
    setsidebarVisible(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Tarbiyah List Updated Successfully",
      life: 5000,
    });
  };

  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            setValuesForEdit({});
            setsidebarVisible(true);
          }}
        />
      </span>
    </React.Fragment>
  );
  const confirmDeleteSelected = (id) => {
    setDeleteProgramDialog(true);
    setDeleteProgram(id);
  };

  useEffect(() => {
    loadProgramData();
  }, []);

  return (
    <React.Fragment>
      <div className="layout-main">
        <div>
          <Toolbar
            style={{ border: "hidden" }}
            left={leftContents}
            right={rightContents}
          />
        </div>
        <div className="datatable-doc-demo">
          <Toast ref={toast} position="top-center" />
          <div className="card">
            <DataTable
              ref={dt}
              value={programData}
              dataKey="id"
              onRowClick={(e) => onRowClick(e.data)}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              rowHover
              scrollable
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Lecturer"
              filterDisplay="menu"
              loading={loading}
              emptyMessage="No Lecturer found."
              responsiveLayout="scroll"
              className="table-sel mytab"
            >
              <Column
                className="tarbiyahhover"
                // style={{ width: "30rem" }}
                body={(record) => {
                  return moment(record.date)
                    .format(" dd-MMM, DD YYYY HH:mm")
                    .toUpperCase();
                }}
                header="Date"
              />
              <Column
                className="tarbiyahhover"
                // style={{ width: "30rem" }}
                body={(record) => {
                  return record.classTopic;
                }}
                header="Topic"
              />
              <Column
                className="tarbiyahhover"
                // style={{ width: "30rem" }}
                body={lecturerBodyTemplate}
                header="Lecturers"
              />
              <Column
                className="tarbiyahhover"
                // style={{ width: "30rem" }}
                body={(record) => {
                  return record?.location?.address;
                }}
                header="Locations"
              />
              <Column
                header="Action"
                fixed="Right"
                field={(field) => (
                  <div className="edit-delete-btn">
                    <Button
                      icon="pi pi-pencil"
                      className="p-button-rounded p-button-outlined"
                      onClick={(e) => {
                        setValuesForEdit(field);
                        setsidebarVisible(true);
                        // setValuesInForm(field);
                      }}
                    />

                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded red-color-btn p-button-spacing"
                      onClick={(e) => confirmDeleteSelected(field.id)}
                    />
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <Sidebar
        visible={sidebarVisible}
        position="right"
        style={{ width: "50em" }}
        onHide={() => {
          setsidebarVisible(false);
          setValuesForEdit(null);
        }}
      >
        <TarbiyahProgramForm
          hide={hideDialog}
          updatePrograms={loadProgramData}
          editorValues={valuesForEdit ? valuesForEdit : {}}
        />
      </Sidebar>
      <Dialog
        visible={deleteProgramDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteItemDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the Selected Program?</span>}
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Classes;
