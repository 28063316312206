import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";
import { perCycleData } from "../../../apis/CycleApi";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
const DonationsChart = (props) => {
  const [donationAmount, setDonationAmount] = useState();

  const perCycleDonations = () => {
    var cycleNames = [];
    var donationsAmount = [];
    for (var cycleAmount in props.donations) {
      var date = moment(cycleAmount).format("MMMM-YYYY");
      cycleNames.push(date);
      donationsAmount.push(props.donations[cycleAmount]);
    }
    setDonationAmount({
      labels: cycleNames,
      datasets: [
        {
          label: "Amount",
          backgroundColor: "#72A1DB",
          data: donationsAmount,
        },
      ],
    });
  };

  useEffect(() => {
    perCycleDonations();
  }, [props.donations]);

  const getLightTheme = () => {
    let donationsOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        datalabels: {
          color: '#f3f6f4',
          font:{
            size:"14px",
            weight:"600"
          }
        },
        legend: {
          display: false,
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    return {
      donationsOptions,
    };
  };
  const { donationsOptions } = getLightTheme();

  return (
    <div className="layout-main-height">
      <div className="datatable-doc-demo">
        <div className="card">
          <Chart type="bar" data={donationAmount} options={donationsOptions}  plugins={[ChartDataLabels]} />
        </div>
      </div>
    </div>
  );
};

export default DonationsChart;
