import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Tooltip,
  Avatar,
  Divider,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  ClockCircleTwoTone,
  ClockCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  pendingDeservantsApi,
  updatePendingDeservantDeservantsApi,
} from "../../../apis/DeservantApi";
import "./pendingUsers.scss"; // Import the SCSS file
import moment from "moment";

const PendingUsers = () => {
  const [pendingDeservants, setPendingDeservants] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchPendingDeservants();
  }, []);

  const fetchPendingDeservants = async () => {
    setApiLoading(true);
    const response = await pendingDeservantsApi();
    if (response.status === 200) {
      setPendingDeservants(
        response.data
          ?.filter((user) => user.status === "PENDING") // Filter users with status 'PENDING'
          .sort((user1, user2) => user1.id - user2.id) // Sort them by id
      );
    }
    setApiLoading(false);
  };

  const handleAccept = async (id) => {
    const response = await updatePendingDeservantDeservantsApi(id, "VERIFIED");
    if (response.status === 200) {
      fetchPendingDeservants();
      message.success("Approved successfully");
    } else {
      message.success("Error while approving");
    }
  };

  const handleDecline = async (id) => {
    const response = await updatePendingDeservantDeservantsApi(id, "REJECTED");
    if (response.status === 200) {
      fetchPendingDeservants();
      message.success("Rejected successfully");
    } else {
      message.success("Error while rejecting");
    }
  };

  const showModal = (user) => {
    setSelectedUser(user);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const PendingUserCard = ({ userData }) => {
    return (
      <div className="flex flex-col justify-center items-center h-[236px] w-[344px] cardbody border border-gray-400 rounded-2xl mx-6 my-4">
        <div className="h-[55px] w-full rounded-t-2xl bg-[#29ABE2] text-stone-200 flex justify-start items-center pl-5 font-semibold">
          <ClockCircleOutlined
            style={{
              marginRight: "10px",
              fontSize: "24px",
            }}
          />

          {userData?.requestedDate
            ? moment(userData?.requestedDate).format("MMM DD, YYYY")
            : "DD/MM/YYYY"}
        </div>
        <div className="h-[181px] w-full rounded-b-2xl ">
          <div className="mt-3 mr-5 text-right">
            <Tooltip title="View Details">
              <InfoCircleOutlined
                className="info-icon"
                onClick={() => showModal(userData)}
                style={{ fontSize: 24 }}
              />
            </Tooltip>
          </div>
          <div className="flex items-center ml-10">
            <div>
              <Avatar shape="square" size={64} icon={<UserOutlined />} />
            </div>
            <div className="flex flex-col justify-center items-start">
              <p className="text-3xl">
                {userData?.userId?.name} <br />
                <span className="text-lg">
                  Referred By: {userData?.referredBy?.name || "N/A"}
                </span>
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center mt-5 space-x-6">
            <Button
              onClick={() => handleAccept(userData.id)}
              className="rounded-full w-48 bg-[#29ABE2] hover:bg-white text-white hover:text-blue-500 hover:scale-105 transition duration-300"
            >
              Accept
            </Button>
            <Button
              type="primary"
              onClick={() => handleDecline(userData.id)}
              className="rounded-full w-48 bg-gray-200 text-gray-700 hover:bg-gray-500 hover:text-white hover:scale-105 transition duration-300"
            >
              Decline
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <h1>Pending Users</h1>
      {apiLoading ? (
        <p>Loading...</p>
      ) : (
        <Row gutter={[16, 16]} justify="start">
          {pendingDeservants.map((user) => (
            <PendingUserCard userData={user} />
          ))}
        </Row>
      )}

      {selectedUser && (
        <Modal
          title="User Info"
          visible={isModalVisible}
          onCancel={handleModalClose}
          width={700}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          className="custom-modal" // Add custom class for styling
        >
          <div className="modal-header">
            <Avatar
              style={{ marginLeft: "40px" }}
              src={
                selectedUser.userId.profileImage ||
                "https://via.placeholder.com/40"
              }
              size={80}
            />
            <div className="top-right-info">
              <ClockCircleTwoTone
                className="clock-icon"
                twoToneColor="#5c5c5c"
              />{" "}
              {/* Adjusted icon color */}
              <span className="requested-date">
                {moment(selectedUser.requestedDate).format(
                  "DD MMM YYYY, h:mm a"
                )}
              </span>
            </div>
          </div>

          <div className="modal-content">
            <div className="left-column">
              {/* <p><strong style={{ marginRight: '15px' }}>Name:</strong> {selectedUser.userId.name}</p>
                            <p><strong style={{ marginRight: '15px' }}>Mobile:</strong> {selectedUser.userId.mobile}</p>
                            <p><strong style={{ marginRight: '15px' }}>Email:</strong> {selectedUser.userId.email || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Address:</strong> {selectedUser.userId.address || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }} >CNIC:</strong> {selectedUser.userId.cnic || 'N/A'}</p> */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <strong style={{ width: "120px", marginRight: "20px" }}>
                    Name:
                  </strong>
                  <span>{selectedUser.userId.name}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <strong style={{ width: "120px", marginRight: "20px" }}>
                    Mobile:
                  </strong>
                  <span>{selectedUser.userId.mobile}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <strong style={{ width: "120px", marginRight: "20px" }}>
                    Email:
                  </strong>
                  <span>{selectedUser.userId.email || "N/A"}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <strong style={{ width: "120px", marginRight: "20px" }}>
                    Address:
                  </strong>
                  <span>{selectedUser.userId.address || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "120px", marginRight: "15px" }}>
                    CNIC:
                  </strong>
                  <span>{selectedUser.userId.cnic || "N/A"}</span>
                </div>
              </div>
            </div>

            <div className="right-column">
              {/* <p><strong style={{ marginRight: '15px' }}>Referred By:</strong> {selectedUser.referredBy?.name || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Priority:</strong> {selectedUser.priority || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Zakat Applicable:</strong> {selectedUser.zakatApplicable || 'N/A'}</p>
                            <Divider style={{ borderColor: '#B0B0B0' }}></Divider>
                            <p><strong style={{ marginRight: '15px' }}>Occupation:</strong> {selectedUser.occupation || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Family Members:</strong> {selectedUser.familyMember || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Adult Members:</strong> {selectedUser.adultMember || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Dep Members:</strong> {selectedUser.dependentMember || 'N/A'}</p>
                            <p><strong style={{ marginRight: '15px' }}>Description:</strong> {selectedUser.description || 'N/A'}</p> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Referred By:
                  </strong>
                  <span>{selectedUser.referredBy?.name || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Priority:
                  </strong>
                  <span>{selectedUser.priority || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Zakat Applicable:
                  </strong>
                  <span>{selectedUser.zakatApplicable || "N/A"}</span>
                </div>

                <Divider style={{ borderColor: "#B0B0B0" }} />

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Occupation:
                  </strong>
                  <span>{selectedUser.occupation || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Family Members:
                  </strong>
                  <span>{selectedUser.familyMember || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Adult Members:
                  </strong>
                  <span>{selectedUser.adultMember || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Dep Members:
                  </strong>
                  <span>{selectedUser.dependentMember || "N/A"}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong style={{ width: "150px", marginRight: "15px" }}>
                    Description:
                  </strong>
                  <span>{selectedUser.description || "N/A"}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default PendingUsers;
