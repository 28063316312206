import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const allDonations = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donations", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const allDonationsByCyleId = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/latestdonations", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const addDonations = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/donations", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const updateDonations = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/donations", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteDonations = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deletedonations/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getDonationsCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donationscount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAmountofCurrentCycle = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donationsbycycleid", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllDonationsByCycle = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donationsbycycle", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getAllDonationsBySpecificDonor = (donorId) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/donationsbyspecificdonor/" + donorId,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const donationsByCycle = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/donationsbycycle/" + id + "page=${current}&size=${pageSize}",  {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getTotalAmountofCurrentCycle = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/totalDonationsByCycle", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getDonorFilter = (filter, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/getDonorFilter?page=" +
      current +
      "&size=" +
      pageSize,
    filter,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem(
          "__Secure-rashanDistribution_token"
        ),
      },
    }
  );
};

export const uniqueReferrers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/donations/uniqueReferrers", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const donationReceipt = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/donations/downloadReceipt/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
    responseType: "blob",
  });
};

export const downaloalAllReceipts = (cycleId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadCycleDonations/" + cycleId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
    responseType: "blob",
  });
};
