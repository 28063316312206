import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import {
  getAllDeservants,
  getDeservantsForProgram,
} from "../../../apis/DeservantApi";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { addProgramAttendees } from "../../../apis/ProgramAttendeesApis";

const AddDeservants = (props) => {
  const [deservants, setDeservants] = useState([]);
  const [selectedDeservants, setSelectedDeservants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "referredBy.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "userId.cnic": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },

    "referredBy.areaId.areaName": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const submitData = () => {
    var allAttendees = [];
    var attendee;
    selectedDeservants.forEach((selectedDeservant) => {
      attendee = {
        deservant: selectedDeservant.id,
        tarbiyahProgram: props.programId,
        invited: true,
      };
      allAttendees.push(attendee);
    });
    addProgramAttendees(allAttendees).then((response) => {
      if (response.status === 200) {
        props.currentProgramDeservant();
        props.hide();
      }
    });
  };

  const fetchDeservants = () => {
    getDeservantsForProgram(props.programId).then((res) => {
      setDeservants(res.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchDeservants();
  }, []);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">Deservants</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="layout-main">
        <div className="datatable-doc-demo">
          <div className="card">
            <DataTable
              value={deservants}
              selection={selectedDeservants}
              onSelectionChange={(e) => setSelectedDeservants(e.value)}
              filters={filters}
              filterDisplay="menu"
              responsiveLayout="scroll"
              globalFilterFields={[
                "referredBy.name",
                "userId.name",
                "userId.cnic",
                "userId.mobile",
              ]}
              loading={loading}
              emptyMessage="No Cycle Deservants found."
              dataKey="id"
              header={renderHeader}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Deservants"
              //   header={header}
              className="table-sel mytab"
            >
              <Column
                key="referredBy.id"
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>

              <Column
                key="distributor.id"
                field="distributor.userId.name"
                header="Distributors"
                body={(record) => {
                  return record.distributor.userId.name;
                }}
                sortable
                style={{ minWidth: "13rem" }}
              />

              <Column
                key="refferedBy.id"
                field="referredBy.name"
                header="Referred By"
                body={(record) => {
                  return record.referredBy.name;
                }}
                sortable
                filterPlaceholder="Search by referredBy.distributorId.name"
                style={{ minWidth: "13rem" }}
              />

              <Column
                key="distributor.areaId.areaName"
                field="distributor.areaId.areaName"
                header="Area"
                body={(record) => {
                  return record.distributor.areaId.areaName;
                }}
                sortable
                filterPlaceholder="Search by referredBy.areaId.areaName"
                style={{ minWidth: "10rem" }}
              />

              <Column
                field="userId.name"
                header="Deservants"
                body={(record) => {
                  return record.userId.name;
                }}
                sortable
                filterPlaceholder="Search by userId.name"
                style={{ minWidth: "10rem" }}
              />

              <Column
                field="userId.cnic"
                header="Cnic"
                body={(record) => {
                  return record.userId.cnic;
                }}
                sortable
                filterPlaceholder="Search by userId.cnic"
                style={{ minWidth: "10rem" }}
              />

              <Column
                field="userId.mobile"
                header="Mobile"
                body={(record) => {
                  return record.userId.mobile;
                }}
                sortable
                filterPlaceholder="Search by userId.mobile"
                style={{ minWidth: "1rem" }}
              />
            </DataTable>
          </div>
          <Button
            label="Submit"
            className="submit-btn"
            style={{ marginRight: "-3px", marginTop: "3px" }}
            onClick={submitData}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddDeservants;
