import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import moment from "moment";

import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { currentCycleDeservants } from "../../../apis/CycleDistributorApi";

const CurrentCycleDeservantTile = (props) => {
  const [cycleDeservants, setCycleDeservants] = useState();
  const dt = useRef(null);
  const [cycleId, setCycleId] = useState(null);
  const [date, setDate] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "deservantId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "distributorId.userId.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "deservantId.familyMember": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "deservantId.userId.mobile": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  var currentCycleDate;
  const currentCycleDeservant = (data) => {
    currentCycleDeservants(data).then((res) => {
      if (res.status === 200) {
        setCycleDeservants(res.data);
      }
    });
  };
  useEffect(() => {
    currentCycleDeservant();
    // currentCycleDate = res.data[0]?.cycleId.startDate;
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">
          {"Cycle Name : " + moment(currentCycleDate).format("MMMM, YYYY")}
        </h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    var imgpath =
      "data:image/jpg;base64, " + rowData.deservantId.userId?.profileImage;

    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.deservantId.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.deservantId.userId?.name
                  ?.charAt(0)
                  .toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.deservantId.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <div className="card">
        <DataTable
          ref={dt}
          value={cycleDeservants}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rowHover
          filters={filters}
          //   filterDisplay="menu"
          responsiveLayout="scroll"
          globalFilterFields={[
            "deservantId.userId.name",
            "distributorId.userId.name",
            "deliveryStatus",
            "deservantId.userId.mobile",
          ]}
          emptyMessage="No Deservant found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} deservants"
        >
          <Column
            field="deservantId.userId.name"
            header="Name"
            sortable
            filterPlaceholder="Search by deservantId.userId.name"
            style={{ width: "8rem" }}
            body={representativeBodyTemplate}
          />
          <Column
            field="distributorId.userId.name"
            header="Distributor Name"
            body={(record) => {
              return record.distributorId.userId.name;
            }}
            sortable
            filterPlaceholder="deservantId.userId.name"
            style={{ width: "8rem" }}
          />

          <Column
            field="familyMember"
            header="Family Members"
            sortable
            style={{ width: "8rem" }}
            body={(record) => {
              return record.familyMember;
            }}
            filterPlaceholder="Search by deservantId.familyMember"
          />
          <Column
            field="deservantId.userId.mobile"
            header="Mobile"
            sortable
            filterPlaceholder="Search by deservantId.userId.mobile"
            style={{ width: "8rem" }}
            body={(record) => {
              return record.deservantId.userId.mobile;
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};
export default CurrentCycleDeservantTile;
