/**
 *
 *   APPLICATION CONSTANTS
 *
 */

export const APP_CONTEXT_URL = "rd";
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOAD_CURRENT_USER = "SET_CURRENT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

/** CONTRIES Constants */
export const GET_CONTRIES = "GET_CONTRIES";
export const SET_CONTRY = "SET_CONTRY";

export const GET_ENTITIES = "GET_ENTITIES";
export const SET_ENTITY = "SET_ENTITY";
export const RESET_ENTITIES = "RESET_ENTITIES";

/** DATE Format Constants */
export const DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";

/** Regex mobile numbers */
export const MOBILE_NUMBER = "^(?:\\+(\\d{1,3}))(\\d{9,14})$";

/** Regex Alphabatic Inputs */
export const ALPHABETICAL_INPUTS = "^[a-zA-Z ]+$";

//** Regex Numeric Inputs */
export const NUMERIC_INPUTS = "^[0-9]+$";
export const NORDOYMAINCOLOR = "#fbb618";

export const STRIPE_API_KEY = "pk_test_JGrCX5KZb6skvTePKwzSuYdR00Vmd6BwsS";

export const CELL_NINJA = "q16iov25luzxh5g8402zwomrk85t11x";

export const HOME_TAB_ACCESS = "HOME_TAB_ACCESS";

export const VIEW_ALL_PROJECTS = "VIEW_ALL_PROJECTS";

export const VIEW_SSR_TABLE = "VIEW_SSR_TABLE";

export const VIEW_MOBILE_META_TABLE = "VIEW_MOBILE_META_TABLE";

export const VIEW_SETTINGS = "VIEW_SETTINGS";

export const IMPORT_CDR_FILE = "IMPORT_CDR_FILE";

export const DOWNLOAD_FINAL_REPORT_PDF = "DOWNLOAD_FINAL_REPORT_PDF";

export const DOWNLOAD_FINAL_REPORT_EXCEL = "DOWNLOAD_FINAL_REPORT_EXCEL";

export const VIEW_UNIQUE_PHONE_NUMBER = "VIEW_UNIQUE_PHONE_NUMBER";

export const VIEW_UNIQUE_TOWER_LOCATION = "VIEW_UNIQUE_TOWER_LOCATION";

export const DELETE_PROJECT = "DELETE_PROJECT";

export const VIEW_USER = "VIEW_USER";

export const ADD_USER = "ADD_USER";

export const UPDATE_USER = "UPDATE_USER";

export const DELETE_USER = "DELETE_USER";

export const VIEW_ROLE = "VIEW_ROLE";

export const ADD_ROLE = "ADD_ROLE";

export const UPDATE_ROLE = "UPDATE_ROLE";

export const DELETE_ROLE = "DELETE_ROLE";

export const VIEW_PERMISSION = "VIEW_PERMISSION";

export const ADD_PERMISSION = "ADD_PERMISSION";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

export const DELETE_PERMISSION = "DELETE_PERMISSION";

export const RECEIPT_TYPE = [
  {
    name: "Payment Receipt",
  },
  { name: "FBR Challan" },
  { name: "Tax Receipt" },
  { name: "Item Receipt" },
];
export const COMPLETED = "COMPLETED";
export const ACTIVE = "ACTIVE";
export const UPCOMING = "UPCOMING";
