import { Form, Input } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
// import { ProductService } from '../service/ProductService';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Controller, useForm } from "react-hook-form";
import { addLocation, deleteLocation, getAllLocation , areaLocation} from "../../../apis/LocationApi";
import { Sidebar } from "primereact/sidebar";
// import "./Area.scss";
// import { useForm } from "react-hook-form";
const Locations = () => {
  const moment = require("moment");
  const [locationData, setLocationData] = useState([]);
  const [locationTable, setLocationTable] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [date, setDate] = useState(null);
  const [deleteLocations, setDeleteLocations] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [sidebarVisible, setsidebarVisible] = useState(false);
  // const [selectedArea, setSelectedArea] = useState (null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    areaName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    creationDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const loadAreaData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data)
        setLocationData(res.data.reverse());
        // setLocationTable(res.data)
        setLoading(false);
      }
    });
  };
 
  useEffect(() => {
    loadAreaData();
    
  }, []);
  const confirmDeleteSelected = (id) => {
    setDeleteProductsDialog(true);
    setDeleteLocations(id);
  };
  // const saveProduct = () => {
  //   alert("saved");
  // };
  const hideDialog = () => {
    form.resetFields();
    setsidebarVisible(false);
    // setProductDialog(false);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
    setDeleteLocations("");
  };
  const onSubmit = async (data) => {

      let dataForApi = {
          locationName: data.locationName
      };
      
      addLocation(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();

          // setAreaValuesForEdit(true);
          setsidebarVisible(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Location Added Successfully",
            life: 3000,
          });
          loadAreaData();
        }
      });
      // addLocation(dataForApi).then((res) => {
      //   console.log("location", res)
      //   if (res.status === 200) {
      //     reset();
      //     hideDialog();
      //   }
      // });
    
  };

  
  // const onFinish = () => {
  //   form.validateFields().then((values) => {
  //     let newValues = {
  //       id: form.getFieldValue("id") ? form.getFieldValue("id") : null,
  //       ...values,
  //     };
  //     addLocation(newValues).then((res) => {
  //       if (res.status === 200) {
  //         hideDialog();
  //         loadAreaData();
  //       }
  //     });
  //     toast.current.show({
  //       severity: "success",
  //       summary: "Successful",
  //       detail: "Location Added Successfully",
  //       life: 3000,
  //     });
  //   });
  //   return false;
  // };
  const openNew = () => {
    // setId(null);
    // setProductDialog(true);
    reset();
    setsidebarVisible(true);
  };
  const deleteSelectedArea = () => {
    deleteLocation(deleteLocations).then((response) => {
      if (response.status === 200) {
        loadAreaData();
        setDeleteProductsDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Location Deleted",
          life: 3000,
        });
      } else {
        toast.current.show({
          className: "error-shower",
          severity: "Error",
          summary: "Error",
          detail: "Delete the Location from the distributor first",
          life: 6000,
        });
        setDeleteProductsDialog(false);
      }
    });
  };
  // const areaBody = (option) => {
  //   return ( 
  //             <div className="p-multiselect-areaName-option">
  //           <span className="image-text"> {option.areaName}</span>
  //       </div>
  //   );
  // };

  // useEffect(() => {
  //   const ids = selectedArea?.map(obj => obj.id);
  //   if(ids?.length > 0)
  //   {
  //     areaLocation(ids).then((res) => {
  //       if (res.status === 200) {
  //         setAreaTable(res.data)
  //       }
  //       else // api call failed 
  //         setAreaTable([]);
  //     });
  //   }
  //   else 
  //   loadAreaData();
  // }, [selectedArea])
  // const areaFilter = (options) => {
  //   return (
  //     <div className="card flex justify-content-center">
  //     <MultiSelect 
  //     value={selectedArea} 
  //     onChange={(e) => setSelectedArea(e.value)} 
  //     options={areaData} 
  //     optionLabel="areaName" 
  //     // display="chip" 
  //     placeholder="Select Area" 
  //     maxSelectedLabels={1} 
  //     className="w-full md:w-12rem"/>

  // </div>
  //   );
  // };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedArea}
      />
    </React.Fragment>
  );
  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Locations </h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <Button
          label="New"
          icon="pi pi-plus"
          style={{ marginLeft: "1rem" }}
          onClick={openNew}
        />
      </span>
    </React.Fragment>
  );
  const PaginatorDemo = () => {
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);
    const onBasicPageChange = (event) => {
      setBasicFirst(event.first);
      setBasicRows(event.rows);
    };
  };
  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-crud-demo">
      <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            ref={dt}
            value={locationTable}
            dataKey="id"
            paginator
            rows={10}
            scrollable
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Locations"
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={["locationName", "creationDate"]}
            loading={loading}
            emptyMessage="No Locations found."
            responsiveLayout="scroll"
            className="table-sel mytab"
          >
            <Column
              field="locationName"
              header="Location Name"
              style={{ minWidth: "12rem" }}
              sortable
              filterPlaceholder="Search by locationName"
              // showFilterMenu={true}
              // filterMenuStyle={{ width: '10rem' }}
              // filter
              // filterElement={areaFilter}
              // showFilterMenuOptions={false}
              // filterApply
              // filterClear
            ></Column>
            <Column
              field="creationDate"
              header="Creation Date"
              body={(record) => {
                return moment(record.creationDate).format("yyyy-MM-DD");
              }}
              style={{ minWidth: "16rem" }}
              sortable
              filterPlaceholder="Search by creationDate"
            ></Column>
            {/* <Column
            header="Action"
            field={(field) => (
              <Button
                onClick={(e) => {
                  form.setFieldsValue({
                    id: field.id,
                    areaName: field.areaName,
                    creationDate: moment(
                      new Date(field.creationDate),
                      "MM/DD/YYYY"
                    ),
                    //   creationDate : moment(field.creationDate).format("MM/DD/YYYY"),
                  });
                  setProductDialog(true);
                }}
              >
                Edit
              </Button>
            )}
          ></Column> */}
            <Column
              header="Action"
              fixed="Right"
              field={(field) => (
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded red-color-btn p-button-spacing"
                  onClick={(e) => confirmDeleteSelected(field.id)}
                />
              )}
            ></Column>
          </DataTable>
        </div>
        <Sidebar
          visible={sidebarVisible}
          position="right"
          style={{ width: "60em" }}
          onHide={() => {
            setsidebarVisible(false);
            
          }}
        >
          <div className="flex justify-content-center">
            <div className="card width-card width-adjuster-for-add-deservant-form">
              <h5 className="text-center font-for-register">Location Name</h5>
              <form form={form} className="p-fluid" onSubmit={handleSubmit(onSubmit)}>

                <div className="field grid" style={{ marginTop: "5px" }}>
                  <label
                    htmlFor="locationName"
                    className="col-12 mb-2 md:col-3 md:mb-0"
                  >
                    <b> Location Name  </b><span className="color-for-str">*</span>
                  </label>
                  <div className="col-10 md:col-8">
                    <Controller
                      name="locationName"
                      control={control}
                      rules={{ required: "Location Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          id={field.name}
                          {...field}
                          autoFocus
                        />
                      )}
                    />
                    {getFormErrorMessage("locationName")}
                  </div>
                </div>

                <Button type="submit" label="Submit" className="submit-btn"/>
                <Button
                  type="cancel"
                  label="Cancel"
                  className="p-button-danger cancel-btn"
                  onClick={hideDialog}
                />
              </form>
            </div>
          </div>

        </Sidebar>

        {/* <Dialog
          visible={productDialog}
          style={{ width: "450px" }}
          baseZIndex={0}
          header="New Location"
          modal
          className="p-fluid index-changer"
          onHide={hideDialog}
          // footer={productDialogFooter}
          //
        > */}
          {/* <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Location Name"
              name="locationName"
              autoFocus
              rules={[{ required: true, message: "Please input Location Name!" }]}
            >
              <Input />
            </Form.Item>
         
            <div className="btn-adjuster-footer-area-model">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDialog}
              />
              <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                htmlType="submit"
                // onClick={onFinish}
              />
            </div>
          </Form>
        </Dialog> */}
        {/* <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal
             onHide={hideDeleteProductDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                </div>
            </Dialog> */}

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {<span>Are you sure you want to delete the selected Loction?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default Locations;
